<template>
  <Dialog
      header="New Monthly Content"
      v-model:visible="showModal"
      modal
      :style="{'width':'40%'}"
  >
    <div class="row mb-3 mt-3">
      <div class="col-md-6 form-group">
        <label class="d-block">Month:</label>
        <Calendar
            class="w-100"
            v-model="newContent.date"
            dateFormat="mm.yy"
            view="month"
        />
      </div>
      <div class="col-md-12 form-group">
        <label class="d-block">Auto Translate:</label>
        <InputSwitch v-model="newContent.autoTranslate" @change="updateAutoAudio"/>
      </div>
      <div class="col-md-12 form-group">
        <label class="d-block">Auto Audio Languages:</label>
        <MultiSelect
            class="w-100"
            v-model="newContent.autoAudioLanguages"
            :options="supportedLanguages"
            :disabled="!newContent.autoTranslate"
            optionLabel="name"
            optionValue="id"
            placeholder="Select Languages"
        />
      </div>
      <div class="col-md-12 form-group">
        <label class="d-block">Title:</label>
        <InputText
            class="w-100"
            type="text"
            v-model="newContent.title"
        />
      </div>
      <div class="col-md-12 form-group">
        <label class="d-block">Text:</label>
        <Textarea
            class="w-100"
            type="text"
            rows="8"
            v-model="newContent.text"
        />
      </div>
      <div class="col-md-12 form-group">
        <label class="d-block">Story Prefix:</label>
        <InputText class="w-100" type="text" v-model="newContent.storyPrefix"/>
      </div>
      <div class="col-12">
        <label class="d-block">Is Premium:</label>
        <InputSwitch
            v-model="newContent.isPremium"
        />
      </div>

      <div class="form-group" :class="{'col-md-6':!newContent.isGeneral, 'col-md-12':newContent.isGeneral}">
        <label class="d-block">General:</label>
        <InputSwitch
            v-model="newContent.isGeneral"
        />
      </div>

      <div v-if="!newContent.isGeneral" class="col-md-6 form-group">
        <label class="d-block">Ascendant Sign:</label>
        <Dropdown
            class="w-100"
            v-model="newContent.ascendantSignId"
            :options="signs"
            optionLabel="name"
            optionValue="id"
            placeholder="Select Sign"
        />
      </div>
      <div class="col-md-6 form-group">
        <label class="d-block">Min Age:</label>
        <InputNumber
            class="w-100"
            mode="decimal"
            v-model="newContent.minAge"
        />
      </div>
      <div class="col-md-6 form-group">
        <label class="d-block">Max Age:</label>
        <InputNumber
            class="w-100"
            mode="decimal"
            v-model="newContent.maxAge"
        />
      </div>
      <div class="col-md-6 form-group">
        <label class="d-block">Gender:</label>
        <Dropdown
            class="w-100"
            :options="[{'id': 0, 'value':'All'},{'id': 1 , 'value':'Man'}, {'id': 2 , 'value':'Woman'}]"
            v-model="newContent.gender"
            optionLabel="value"
            optionValue="id"
        />
      </div>
      <div class="col-md-6 form-group">
        <label class="d-block">Relationship:</label>
        <MultiSelect
            class="w-100"
            :options="relationships"
            v-model="newContent.relationshipStatusIds"
            optionLabel="name"
            optionValue="id"
        />
      </div>
      <div class="col-md-6 form-group">
        <label class="d-block">Occupations:</label>
        <MultiSelect
            class="w-100"
            v-model="newContent.occupationIds"
            :options="occupations"
            optionLabel="name"
            optionValue="id"
            placeholder="Select Occupations"
        />
      </div>
      <div class="col-md-6 form-group">
        <label class="d-block">Tags:</label>
        <MultiSelect
            class="w-100"
            v-model="newContent.categoryIds"
            :options="categories"
            optionLabel="name"
            optionValue="id"
            placeholder="Select Tags"
        />
      </div>
    </div>
    <Message v-for="msg of messages" :severity="msg.severity" :key="msg.id" :life="5000" :sticky="false">
      {{ msg.content }}
    </Message>
    <div class="modal-footer">
      <button
          type="button"
          class="btn btn-default"
          @click="hideModal"
      >
        Close
      </button>
      <Button
          label="Save"
          icon="pi pi-save"
          iconPos="left"
          @click="addNewContent"
      />
    </div>
  </Dialog>
</template>
<script>
import dayjs from 'dayjs';
import {mapGetters} from 'vuex';

export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    contentType: {
      type: String,
      required: true
    }
  },
  mounted() {
  },
  data() {
    return {
      newContent: {},
      messages: [],
      messageCount: 0,
      autoAudioLanguages: []
    }
  },
  computed: {
    ...mapGetters(['occupations', 'signs', 'houses', 'relationships', 'occupationIds', 'relationshipIds', 'categories', 'supportedLanguages']),
    showModal: {
      get() {
        return this.show;
      },
      set() {
        this.hideModal();
      }
    },
  },
  methods: {
    hideModal() {
      this.$emit('hide');
    },
    addNewContent() {
      if (!(this.newContent.title && this.newContent.date && this.newContent.text && (this.newContent.ascendantSignId != null || this.newContent.isGeneral))) {
        this.messages = [{severity: 'warn', content: 'Required fields must be entered.', id: this.messageCount++}];
        return;
      }
      var date = this.newContent.date;
      var startDate = dayjs(new Date(Date.UTC(date.getFullYear(), date.getMonth(), 1, 0, 0, 0, 0)));
      var endDate = startDate.add(1, 'month');

      this.$appAxios.post("admin/content/v2/monthly", {
        type: "monthly",
        houseId: this.newContent.houseId,
        ascendantSignId: this.newContent.ascendantSignId,
        score: this.newContent.score,
        minAge: this.newContent.minAge,
        maxAge: this.newContent.maxAge,
        gender: this.newContent.gender,
        relationshipStatusIds: this.newContent.relationshipStatusIds,
        occupationIds: this.newContent.occupationIds,
        startDate: startDate,
        endDate: endDate,
        title: this.newContent.title,
        text: this.newContent.text,
        isGeneral: this.newContent.isGeneral,
        isPremium: this.newContent.isPremium,
        categoryIds: this.newContent.categoryIds,
        storyPrefix: this.newContent.storyPrefix,
        autoTranslate: this.newContent.autoTranslate,
        autoAudioLanguages: this.newContent.autoAudioLanguages
      }).then(() => {
        this.hideModal();
        this.$toast.add({severity: 'success', summary: 'Success', detail: 'Content added successfully', life: 3000});
        this.$emit('refresh');
      }).catch(() => {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'An error occured while adding content',
          life: 3000
        });
      });
    },
    updateAutoAudio() {
      if (!this.newContent.autoTranslate) {
        this.newContent.autoAudioLanguages = [];
      }
    },
  }
}
</script>
<style>
</style>
<template>
  <Dialog
      header="Delete Language Settings"
      v-model:visible="showModal"
      modal
      :style="{'width':'40%'}"
      @hide="hideModal"
  >
    <div class="row mb-3 mt-3">
      <div class="row d-flex justify-content-between">
        <div class="col-md-12 form-group">
          <label class="d-block">Are you sure you want to delete it?</label>
        </div>
      </div>
    </div>
    <Message v-for="msg of messages" :severity="msg.severity" :key="msg.id" :life="5000" :sticky="false">
      {{ msg.content }}
    </Message>
    <div class="modal-footer">
      <button
          type="button"
          class="btn btn-default"
          @click="hideModal"
      >
        Close
      </button>
      <Button
          label="Yes"
          icon=""
          iconPos="left"
          @click="deleteLanguageSettings"
      />
    </div>
  </Dialog>
</template>
<script>
import {mapGetters} from 'vuex';

export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    selectedLanguageSetting: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      newContent: {},
      messages: [],
      messageCount: 0,
      selectedContentType: [],
      contentType: [],
      contentTypeObj: {},
      languageObj: {},
      languageKey: null,
      deepLKey: null,
      isActive: false,
      isActiveType: false,
      contentTranslation: null,
    }
  },
  watch: {
    showModal(newVal) {
      if (newVal && this.selectedLanguageSetting) {
        this.fetchLanguageSettings();
      }
    }
  },
  computed: {
    ...mapGetters(['occupations', 'signs', 'houses', 'relationships', 'occupationIds', 'relationshipIds', 'categories']),
    showModal: {
      get() {
        return this.show;
      },
      set() {
        this.hideModal();
      }
    },
  },
  methods: {
    hideModal() {
      this.$emit('hide');
    },
    addContentType() {
      this.contentTypeObj = {
        key: this.contentTranslation,
        isActive: this.isActiveType
      }

      this.contentType.push(this.contentTypeObj)
      this.languageObj.contentTypes = this.contentType
    },
    deleteContentType() {
      this.languageObj.contentTypes = null
      this.contentType = []
    },
    addWorkspaces() {
      this.selectedWorkspaces = true
      this.selectedWorkspace = this.value
      const id = this.value.id
      this.values.push(id)
      this.value = null
    },
    fetchLanguageSettings() {
      this.$appAxios.get(`/app-settings/language/${this.selectedLanguageSetting}`)
          .then(res => {
            this.languageObj = res.data.language.result;
          })
          .catch(err => {
            console.error("An error occurred:", err);
          });
    },
    deleteLanguageSettings() {
      this.$appAxios.delete(`/app-settings/language/delete/${this.languageObj.id}`).then(() => {
        this.hideModal();
        this.$toast.add({severity: 'success', summary: 'Success', detail: 'Content added successfully', life: 3000});
        this.$emit('refresh');
      }).catch(() => {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'An error occured while adding content',
          life: 3000
        });
        this.$emit('refresh');
      });
    },
  }
}
</script>
<style>
</style>
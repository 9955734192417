<template>
  <Header/>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-12 m-0 no-max-w home-div">
          <Toast/>
          <ConfirmDialog/>
          <DataTable :value="contents" :lazy="true" :paginator="true"
                     paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                     :rowsPerPageOptions="[5, 10, 20, 50, 100]" dataKey="id" :rows="10" ref="dt"
                     :totalRecords="contentsTotalRecords" :loading="loading" @page="onContentsPage($event)"
                     @sort="onContentsSort($event)" @filter="onContentsFilter($event)" responsiveLayout="scroll"
                     class="p-datatable-striped" v-model:filters="filters" filterDisplay="menu" removableSort
                     :globalFilterFields="['global', 'name', 'isActive', 'relationshipStatusIds']"
                     currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
            <template #header>
              <div class="d-flex justify-content-between">
                <div>
                  <Button type="button" icon="pi pi-plus" label="Add Content" class="p-button-info d-inline me-2"
                          @click="showNewContentModal = true"/>
                  <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)"/>
                </div>
                <div>
                  <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined d-inline me-2"
                          @click="clearFilter()"/>
                  <span class="p-input-icon-left">
                    <i class="pi pi-search"/>
                    <InputText v-model="filters['global'].value" placeholder="Keyword Search" @change="getContents"/>
                  </span>
                </div>
              </div>
            </template>

            <template #empty> No yearly contents found.</template>
            <template #loading> Loading yearly contents data. Please wait.</template>
            <Column field="id" header="Id" ref="id">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.id }}</span>
              </template>
            </Column>
            <Column field="title" header="Title" ref="title">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.title }}</span>
              </template>
            </Column>
            <Column field="text" header="Text" ref="text">
              <template #body="slotProps">
                <ScrollPanel style="width: 100%; height: 100px" class="custombar">
                  <span class="m-0">{{ slotProps.data.text }}</span>
                </ScrollPanel>
              </template>
            </Column>
            <Column field="isGeneral" header="General" ref="isGeneral">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.isGeneral ? 'Yes' : 'No' }}</span>
              </template>
            </Column>
            <Column field="ascendant" header="Ascendant" ref="ascendant" :show-filter-match-modes="false">
              <template #body="slotProps">
                <span class="m-0">{{ this.signs.find(x => x.id == slotProps.data.ascendantSignId)?.name }} </span>
              </template>
              <template #filter="">
                <MultiSelect v-model="filters['ascendant'].value" :options="signs" optionLabel="name" optionValue="id"
                             class="p-column-filter"/>
              </template>
            </Column>
            <Column field="photoUrl" header="Photo Url" ref="photoUrl">
              <template #body="slotProps">
                <img :src="slotProps.data.photoUrl" alt="Photo" width="100" height="100"/>
              </template>
            </Column>
            <Column field="startDate" header="Start Date">
              <template #body="slotProps">
                <span class="m-0">{{ formatDate(slotProps.data.startDate) }}</span>
              </template>
            </Column>
            <Column field="endDate" header="End Date">
              <template #body="slotProps">
                <span class="m-0">{{ formatDate(slotProps.data.endDate) }}</span>
              </template>
            </Column>
            <Column field="type" header="Type"/>
            <Column field="actions" header="Actions" ref="actions">
              <template #body="slotProps">
                <Button
                    icon="pi pi-book"
                    class="p-button p-button-sm me-2 my-2"
                    @click="showEditLanguage(slotProps.data.id)"
                />
                <Button icon="pi pi-pencil" class="p-button-warning p-button-sm me-2 my-2"
                        @click="openEditContent(slotProps.data.id)"/>
                <Button icon="pi pi-trash" class="p-button-danger p-button-sm me-2 my-2"
                        @click="deleteContent(slotProps.data.id)"/>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>

      <Dialog header="New Content" v-model:visible="showNewContentModal" modal :style="{ 'width': '40%' }">
        <div class="row mb-4">
          <div class="col-6">
            <label class="d-block">General:</label>
            <InputSwitch v-model="newContent.isGeneral"/>
          </div>
          <div class="col-6">
            <label class="d-block">Premium</label>
            <InputSwitch v-model="newContent.isPremium"/>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-6" v-if="!newContent.isGeneral">
            <label class="d-block">Ascendant Sign:</label>
            <Dropdown v-model="newContent.ascendantSignId" :options="signs" optionLabel="name" optionValue="id"
                      placeholder="Select Ascendant Sign"/>
          </div>
        </div>
        <div class="col-md-12 form-group d-flex align-items-center">
          <InputSwitch v-model="newContent.autoTranslate" @change="updateAutoAudio"/>
          <label class="d-block ms-2">Auto Translate</label>
        </div>
        <div class="col-md-12 form-group">
          <label class="d-block">Auto Audio Languages:</label>
          <MultiSelect 
            v-model="newContent.autoAudioLanguages" 
            :options="supportedLanguages" 
            :disabled="!newContent.autoTranslate"
            optionLabel="name" 
            optionValue="id" 
            placeholder="Select Languages" 
            class="w-100"
          />
        </div>
        <div class="row">
          <div class="col-12 mb-4">
            <label class="d-block">Title:</label>
            <InputText class="w-100" type="text" v-model="newContent.title"/>
          </div>

          <div class="col-12 mb-4">
            <label class="d-block">Text:</label>
            <InputText class="w-100" type="text" v-model="newContent.text"/>
          </div>
        </div>

        <div class="row">
          <div class="col-12 mb-4">
            <label class="d-block">Photo Url:</label>
            <InputText class="w-100" type="text" v-model="newContent.photoUrl"/>
          </div>
        </div>

        <div class="row">
          <div class="col-6 mb-4">
            <label class="d-block">Start Date:</label>
            <Datepicker v-model="newContent.startDate" format="dd/MM/yyyy"></Datepicker>
          </div>
          <div class="col-6 mb-4">
            <label class="d-block">End Date:</label>
            <Datepicker v-model="newContent.endDate" format="dd/MM/yyyy"></Datepicker>
          </div>
        </div>
        <div class="form-group" :class="{ 'col-md-6': !newContent.isGeneral, 'col-md-12': newContent.isGeneral }">
          <label class="d-block">General:</label>
          <InputSwitch v-model="newContent.isGeneral"/>
        </div>
        <div v-if="!newContent.isGeneral" class="col-md-6 form-group">
          <label class="d-block">Ascendant Sign:</label>
          <Dropdown class="w-100" v-model="newContent.ascendantSignId" :options="signs" optionLabel="name"
                    optionValue="id" placeholder="Select Sign"/>
        </div>
        <div class="col-md-6 form-group">
          <label class="d-block">Min Age:</label>
          <InputNumber class="w-100" mode="decimal" v-model="newContent.minAge"/>
        </div>
        <div class="col-md-6 form-group">
          <label class="d-block">Max Age:</label>
          <InputNumber class="w-100" mode="decimal" v-model="newContent.maxAge"/>
        </div>
        <div class="col-md-6 form-group">
          <label class="d-block">Gender:</label>
          <Dropdown class="w-100"
                    :options="[{ 'id': 0, 'value': 'All' }, { 'id': 1, 'value': 'Man' }, { 'id': 2, 'value': 'Woman' }]"
                    v-model="newContent.gender" optionLabel="value" optionValue="id"/>
        </div>
        <div class="col-md-6 form-group">
          <label class="d-block">Relationship:</label>
          <MultiSelect class="w-100" :options="relationships" v-model="newContent.relationshipStatusIds"
                       optionLabel="name" optionValue="id"/>
        </div>
        <div class="col-md-6 form-group">
          <label class="d-block">Occupations:</label>
          <MultiSelect class="w-100" v-model="newContent.occupationIds" :options="occupations" optionLabel="name"
                       optionValue="id" placeholder="Select Occupations"/>
        </div>
        <div class="col-md-6 form-group">
          <label class="d-block">Tags:</label>
          <MultiSelect class="w-100" v-model="newContent.categoryIds" :options="categories" optionLabel="name"
                       optionValue="id" placeholder="Select Tags"/>
        </div>
        <Message v-for="msg of messages" :severity="msg.severity" :key="msg.id" :life="5000" :sticky="false">
          {{ msg.content }}
        </Message>

        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click="showNewContentModal = false">
            Close
          </button>
          <Button label="Save" icon="pi pi-save" iconPos="left" @click="addNewContent()"/>
        </div>
      </Dialog>

      <Dialog header="Edit Language" v-model:visible="showEditLanguageModal" modal :style="{ 'width': '40%' }">
        <div class="col-12">
          <label class="d-block">Verified</label>
          <InputSwitch v-model="editLanguageModel.isVerified"/>
        </div>
        <div class="col-md-12 form-group">
          <label class="d-block">Title:</label>
          <InputText class="w-100" type="text" v-model="editLanguageModel.title"/>
        </div>
        <div class="row mb-3 mt-3">
          <div class="col-md-12 form-group">
            <label class="d-block">Text:</label>
            <Textarea class="w-100" type="text" rows="5" v-model="editLanguageModel.text"/>
          </div>
        </div>
        <div class="col-12 mt-3 mb-3">
          <label class="d-block">Audio Url:</label>
          <InputText class="w-50 me-3" type="text" disabled v-model="editLanguageModel.audioUrl"/>
          <Button v-tooltip="'Generate audio with the content text'" icon="pi pi-plus" iconPos="left"
                  class="p-button-info me-2" @click="generateAudio(editLanguageModel.id)"
                  :loading="generateAudioLoading"/>
          <Button v-tooltip="'Listen'" icon="pi pi-play" iconPos="left" class="p-button-warning"
                  @click="openUrl(editLanguageModel.audioUrl)"/>
          <Button v-tooltip="'Remove Audio'" icon="pi pi-trash" class="p-button-danger ms-2"
                  @click="removeAudio(editLanguageModel.id)"/>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click="showEditLanguageModal = false">
            Close
          </button>
          <Button label="Save" icon="pi pi-save" iconPos="left" @click="editLanguage()"/>
        </div>
      </Dialog>

      <Dialog header="Edit Content" v-model:visible="showEditContent" modal :style="{ 'width': '40%' }">
        <div class="row mb-3 mt-3">
          <div class="col-md-6 form-group">
            <label class="d-block">General:</label>
            <InputSwitch v-model="editContentModel.isGeneral"/>
          </div>
          <div class="col-md-6 form-group">
            <label class="d-block">Premium:</label>
            <InputSwitch v-model="editContentModel.isPremium"/>
          </div>
          <div v-if="!editContentModel.isGeneral" class="col-md-12 form-group">
            <label class="d-block">Sign:</label>
            <Dropdown v-model="editContentModel.ascendantSignId" :options="signs" optionLabel="name" optionValue="id"
                      placeholder="Select Sign"/>
          </div>
          <div class="col-md-6 form-group">
            <label class="d-block">Start Date:</label>
            <Datepicker v-model="editContentModel.startDate" format="dd/MM/yyyy"></Datepicker>
          </div>
          <div class="col-md-6 form-group">
            <label class="d-block">End Date:</label>
            <Datepicker v-model="editContentModel.endDate" format="dd/MM/yyyy"></Datepicker>
          </div>
          <div class="col-12">
            <label class="d-block">Photo Url</label>
            <InputText class="w-100" type="text" v-model="editContentModel.photoUrl"/>
          </div>
          <div class="col-md-6 form-group">
            <label class="d-block">Min Age:</label>
            <InputNumber class="w-100" mode="decimal" v-model="editContentModel.minAge"/>
          </div>
          <div class="col-md-6 form-group">
            <label class="d-block">Max Age:</label>
            <InputNumber class="w-100" mode="decimal" v-model="editContentModel.maxAge"/>
          </div>
          <div class="col-md-6 form-group">
            <label class="d-block">Tags:</label>
            <MultiSelect class="w-100" v-model="editContentModel.categoryIds" :options="categories" optionLabel="name"
                         optionValue="id" placeholder="Select Tags"/>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click="showEditContent = false">
            Close
          </button>
          <Button label="Save" icon="pi pi-save" iconPos="left" @click="editContent()"/>
        </div>
      </Dialog>

      <TranslationsModal
          :show="showEditLanguageModal"
          @hide="showEditLanguageModal = false"
          :contentId="editTranslationContentId"
      />
    </div>
  </main>
</template>

<script>
import Header from "@/components/Header.vue";
import dayjs from "dayjs";
import "@vuepic/vue-datepicker/dist/main.css";
import InputText from "primevue/inputtext";
import {mapGetters} from "vuex";
import TranslationsModal from "../Modals/TranslationsModal.vue";

export default {
  name: "Yearly Contents",
  components: {
    Header,
    InputText,
    TranslationsModal,
  },
  data() {
    return {
      selectedContentType: 'yearly',
      loading: false,
      contentsTotalRecords: 0,
      contents: [],
      filters: {
        global: {value: null},
        name: {value: null},
        house: {value: null},
        gender: {value: null},
        relationshipStatusIds: {value: null},
        score: {value: []},
        occupation: {value: null},
        minAge: {value: null},
        maxAge: {value: null},
      },
      contentsLazyParams: {},
      showNewContentModal: false,
      showEditLanguageModal: false,
      showEditContent: false,
      newContent: {
        title: "",
        text: "",
        score: 1,
        houseId: null,
        ascendantSignId: null,
        isGeneral: false,
        gender: 0,
        minAge: 0,
        maxAge: 100,
        relationshipStatusIds: 0,
        occupationIds: 0,
        autoTranslate: false,
        autoAudioLanguages: [],
      },
      editContentModel: {},
      messages: [],
      messageCount: 0,
      editLanguageModel: {},
      genders: [{id: 0, name: "All"}, {id: 1, name: "Man"}, {id: 2, name: "Woman"}],
      generateAudioLoading: false,
      editTranslationContentId: 0,
    };
  },
  mounted() {
    this.contentsLazyParams = {
      first: 0,
      rows: this.$refs.dt.rows,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };
    this.getContents();
  },
  computed: {
    ...mapGetters(['occupations', 'signs', 'houses', 'relationships', 'occupationIds', 'relationshipIds', 'categories', 'supportedLanguages']),
  },
  watch: {},
  methods: {
    getContents() {
      this.loading = true;

      var filters = {};

      for (const [key] of Object.entries(this.contentsLazyParams.filters)) {
        filters[key] = this.contentsLazyParams.filters[key].value;
      }
      this.$appAxios
          .post("admin/content/list/" + this.selectedContentType, {
            first: this.contentsLazyParams.first,
            rows: this.contentsLazyParams.rows,
            sortField: this.contentsLazyParams.sortField,
            sortOrder: this.contentsLazyParams.sortOrder,
            page: this.contentsLazyParams.page,
            filters: JSON.stringify(filters),
          })
          .then((resp) => {
            this.contents = resp.data.data;
            this.contentsTotalRecords = resp.data.totalRecords;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
    },
    onContentsPage(event) {
      this.contentsLazyParams = event;
      this.contentsLazyParams.filters = this.filters;
      this.getContents();
    },
    onContentsSort(event) {
      this.contentsLazyParams = event;
      this.contentsLazyParams.filters = this.filters;
      this.getContents();
    },
    onContentsFilter() {
      this.contentsLazyParams.filters = this.filters;
      this.getContents();
    },
    clearFilter() {
      for (const [key] of Object.entries(this.filters)) {
        this.filters[key].value = null;
      }
      this.getContents();
    },
    formatDate(dateString) {
      if (dateString == null) {
        return "";
      }

      const date = dayjs(dateString);
      return date.format("DD/MM/YYYY HH:mm");
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    showEditLanguage(id) {
      this.editTranslationContentId = id;
      this.showEditLanguageModal = true;
    },
    openEditContent(id) {
      this.$appAxios.get("admin/content/" + id)
          .then(resp => {
            this.editContentModel = resp.data;
            this.showEditContent = true;
          })
          .catch(err => {
            console.log(err)
          });
    },
    editContent() {
      this.$appAxios.put("admin/content",
          {
            id: this.editContentModel.id,
            ascendantSignId: this.editContentModel.ascendantSignId,
            score: this.editContentModel.score,
            relationshipStatusIds: this.editContentModel.relationshipStatusIds,
            occupationIds: this.editContentModel.occupationIds,
            type: this.selectedContentType,
            isGeneral: this.editContentModel.isGeneral,
            isPremium: this.editContentModel.isPremium,
            startDate: this.editContentModel.startDate,
            endDate: this.editContentModel.endDate,
            photoUrl: this.editContentModel.photoUrl,
            minAge: this.editContentModel.minAge,
            maxAge: this.editContentModel.maxAge,
            categoryIds: this.editContentModel.categoryIds,
          }
      ).then(() => {
        this.showEditContent = false;
        this.$toast.add({severity: 'success', summary: 'Success', detail: 'Content updated', life: 3000});
        this.getContents();
      })
          .catch(() => {
            this.showEditContent = false;
            this.$toast.add({severity: 'error', summary: 'Error', detail: 'Content not updated', life: 3000});
          })
    },
    deleteContent(id) {
      this.$confirm.require({
        message: 'Are you sure you want to delete the content?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$appAxios.delete("admin/content/" + id)
              .then(() => {
                this.$toast.add({severity: 'success', summary: 'Success', detail: 'Content deleted', life: 3000});
                this.getContents();
              })
              .catch(() => {
                this.$toast.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'Content could not be deleted',
                  life: 3000
                });
              })
        },
        reject: () => {
        }
      });
    },
    updateAutoAudio() {
      if (!this.newContent.autoTranslate) {
        this.newContent.autoAudioLanguages = [];
      }
    },
    addNewContent() {
      if (!this.newContent.isGeneral && this.newContent.ascendantSignId == null) {
        this.messages = [{severity: 'warn', content: 'Ascendant sign cannot be empty', id: this.messageCount++}];
        return;
      }

      if (this.newContent.title == null || this.newContent.text == null) {
        this.messages = [{severity: 'warn', content: 'Titles and image cannot be empty', id: this.messageCount++}];
        return;
      }

      if (this.newContent.startDate == null || this.newContent.endDate == null) {
        this.messages = [{severity: 'warn', content: 'Start and end dates cannot be empty', id: this.messageCount++}];
        return;
      }

      if (this.newContent.endDate < this.newContent.startDate) {
        this.messages = [{severity: 'warn', content: 'End date cannot be before start date', id: this.messageCount++}];
        return;
      }

      this.$appAxios.post("admin/content/v2/" + this.selectedContentType, {
        type: this.selectedContentType,
        ascendantSignId: this.newContent.ascendantSignId,
        startDate: this.newContent.startDate,
        endDate: this.newContent.endDate,
        title: this.newContent.title,
        text: this.newContent.text,
        minAge: this.newContent.minAge,
        maxAge: this.newContent.maxAge,
        gender: this.newContent.gender,
        relationshipStatusIds: this.newContent.relationshipStatusIds,
        occupationIds: this.newContent.occupationIds,
        isGeneral: this.newContent.isGeneral,
        isPremium: this.newContent.isPremium,
        language: this.selectedLanguage,
        photoUrl: this.newContent.photoUrl,
        categoryIds: this.newContent.categoryIds,
        autoTranslate: this.newContent.autoTranslate,
        autoAudioLanguages: this.newContent.autoAudioLanguages
      }).then(() => {
        this.showNewContentModal = false;
        this.$toast.add({severity: 'success', summary: 'Success', detail: 'Content added successfully', life: 3000});
        this.getContents();
      }).catch(() => {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'An error occured while adding content',
          life: 3000
        });
        this.getContents();
      });
    },
    generateAudio(id) {
      this.generateAudioLoading = true;

      this.$appAxios.put("admin/content/translation",
          {
            id: this.editLanguageModel.id,
            title: this.editLanguageModel.title,
            text: this.editLanguageModel.text,
            language: this.editLanguageModel.language,
            isVerified: this.editLanguageModel.isVerified,
          }
      ).then(() => {
        this.$appAxios.get("admin/content/generate-audio/" + id)
            .then(resp => {
              this.editLanguageModel.audioUrl = resp.data;
              this.generateAudioLoading = false;
              this.showEditLanguageModal = false;
              this.$toast.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Audio is being generated, you will receive an email when it is ready.',
                life: 3000
              });
            })
            .catch(err => {
              console.log(err)
              this.generateAudioLoading = false;
            });
      })
          .catch(() => {
          })
    },
    removeAudio(id) {
      this.$appAxios.delete("admin/content/remove-audio/" + id)
          .then(() => {
            this.editLanguageModel.audioUrl = "";
          })
          .catch(err => {
            console.log(err)
          });
    },
  },
};
</script>
<style scoped>
.p-button-sm {
  height: 35px;
  width: 35px;
  font-size: 0.875rem;
  padding: 0.7rem;
}

.p-disabled,
.p-component:disabled {
  opacity: 0.8;
}

.p-dropdown {
  min-width: 200px;
}
</style>

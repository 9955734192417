<template>
  <Dialog
      header="Edit Content"
      v-model:visible="showModal"
      modal
      :style="{'width':'40%'}"
  >
    <div class="row">
      <div class="col-6">
        <label class="d-block">Type</label>
        <InputText
            v-model="editContentModel.type"
            disabled
        />
      </div>
      <div class="col-6 form-group">
        <label class="d-block">General:</label>
        <InputSwitch
            v-model="editContentModel.isGeneral"
        />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-6">
        <label class="d-block">Category</label>
        <InputText
            v-model="editContentModel.category"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 form-group">
        <label class="d-block">Start Date:</label>
        <Calendar
            v-model="editContentModel.startDate"
            showTime
            format="dd/MM/yyyy"
            hourFormat="24"
            class="w-100"
        />
      </div>
      <div class="col-md-6 form-group">
        <label class="d-block">End Date:</label>
        <Calendar
            class="w-100"
            v-model="editContentModel.endDate"
            showTime
            format="dd/MM/yyyy"
            hourFormat="24"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 form-group">
        <label class="d-block">House:</label>
        <Dropdown
            v-model="editContentModel.houseId"
            :options="houses"
            optionLabel="name"
            optionValue="id"
            placeholder="Select House"
            class="w-100"
        />
      </div>
      <div class="col-md-6 form-group">
        <label class="d-block">Ascendant Sign:</label>
        <Dropdown
            v-model="editContentModel.AscendantSignId"
            :options="signs"
            optionLabel="name"
            optionValue="id"
            placeholder="Select Sign"
            class="w-100"
        />
      </div>
      <div class="col-md-6 form-group">
        <label class="d-block">Gender:</label>
        <Dropdown
            :options="[{'id': 0, 'value':'All'},{'id': 1 , 'value':'Man'}, {'id': 2 , 'value':'Woman'}]"
            v-model="editContentModel.gender"
            optionLabel="value"
            optionValue="id"
            class="w-100"
        />
      </div>
      <div class="col-6">
        <label class="d-block">Photo Url</label>
        <InputText
            v-model="editContentModel.photoUrl"
            class="w-100"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 form-group">
        <label class="d-block">Min Age:</label>
        <InputNumber
            mode="decimal"
            v-model="editContentModel.minAge"
            class="w-100"
        />
      </div>
      <div class="col-md-6 form-group">
        <label class="d-block">Max Age:</label>
        <InputNumber
            mode="decimal"
            v-model="editContentModel.maxAge"
            class="w-100"
        />
      </div>
    </div>
    <div class="col-md-6 form-group">
      <label class="d-block">Score:</label>
      <InputNumber
          mode="decimal"
          v-model="editContentModel.score"
          class="w-100"
      />
    </div>
    <div class="row">
      <div class="col-md-6 form-group">
        <label class="d-block">Relationship:</label>
        <MultiSelect
            :options="relationships"
            v-model="editContentModel.relationshipStatusIds"
            optionLabel="name"
            optionValue="id"
            class="w-100"
            placeholder="Select Relationship Statuses"
        />
      </div>
      <div class="col-md-6 form-group">
        <label class="d-block">Occupations:</label>
        <MultiSelect
            v-model="editContentModel.occupationIds"
            :options="occupations"
            optionLabel="name"
            optionValue="id"
            placeholder="Select Occupations"
            class="w-100"
        />
      </div>
    </div>
    <Divider>
      <h4>Translations</h4>
    </Divider>
    <div class="row">
      <div class="col-6">
        <label class="d-block">Language</label>
        <Dropdown
            v-model="editContentModel.language"
            :options="[{id: 'en', value: 'English'}, {id: 'tr', value: 'Turkish'}]"
            optionLabel="value"
            optionValue="id"
            placeholder="Select Language"
            class="w-100"
            @change="onLanguageChange"
        />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-6 form-group">
        <label class="d-block">Title:</label>
        <InputText
            v-model="editContentModel.title"
            class="w-100"
        />
      </div>
      <div class="col-md-6 form-group">
        <label class="d-block">Text:</label>
        <Textarea
            v-model="editContentModel.text"
            class="w-100"
        />
      </div>
    </div>

    <div class="modal-footer">
      <button
          type="button"
          class="btn btn-default"
          @click="hideModal"
      >
        Close
      </button>
      <Button
          label="Save and Verify"
          icon="pi pi-save"
          iconPos="left"
          @click="editContent()"
      />
    </div>
  </Dialog>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    contentId: {
      type: Number,
      required: true
    },
    contentTranslationId: {
      type: Number,
      required: true
    },
  },
  mounted() {
    //fetch data
    this.$appAxios
        .get("admin/content/translations/" + this.contentTranslationId)
        .then((resp) => {
          this.editContentModel = resp.data;
        })
        .catch(() => {
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'An error occured while fetching content',
            life: 3000
          });
        })

  },
  data() {
    return {
      newContent: {},
      messages: [],
      messageCount: 0,
      editContentModel: {},
    }
  },
  computed: {
    ...mapGetters(['occupations', 'signs', 'houses', 'relationships', 'occupationIds', 'relationshipIds']),
    showModal: {
      get() {
        return this.show;
      },
      set() {
        this.hideModal();
      }
    },
  },
  methods: {
    hideModal() {
      this.$emit('hide');
    },
    onLanguageChange(event) {
      //fetch translation
      this.$appAxios
          .get("admin/content/translation/" + this.editContentModel.contentId + "/" + event.value)
          .then((resp) => {
            this.editContentModel.title = resp.data.title;
            this.editContentModel.text = resp.data.text;
            this.editContentModel.language = resp.data.language;
            this.editContentModel.id = resp.data.id;
          })
          .catch(() => {
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occured while fetching content',
              life: 3000
            });
          })
    },
    editContent() {
      this.$appAxios
          .put("admin/content/translations/edit-verify/" + this.editContentModel.id, this.editContentModel)
          .then(() => {
            this.$toast.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Content updated successfully',
              life: 3000
            });
            this.hideModal();
          })
          .catch(() => {
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occured while updating content',
              life: 3000
            });
          })
    }
  }
}
</script>
<style>
</style>
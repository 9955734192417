<template>
  <Header/>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-12 m-0 no-max-w home-div">
          <Toast/>

          <AstopiaTable ref="astopiaTable" :columns="columns" :url="'/admin/public-figure/list'"
                        :default-post-data="'?test=test'"
                        :axiosDataName="'data'" :sort-field="''" :totalDataName="'totalRecords'"
                        @detail-see="detailSee" @delet-see="deleteSee" @add-button="addPublicFigure" :addButton="true" :dataPagination="false" :isOnlydata="true"/>

          <EditModal
              :show="showEditModal"
              modalTitle="Edit Public Figure"
              modalWidth="50%"
              :formFields="[
                { label: 'Name', type: 'text', model: 'user.name', placeholder: 'Enter name' },
                { label: 'Surname', type: 'text', model: 'user.surname', placeholder: 'Enter surname' },
                { label: 'Photo URL', type: 'text', model: 'user.photoUrl', placeholder: 'Enter photo URL' },
                { label: 'Date of Birth', type: 'date', model: 'user.birthDate', placeholder: 'Select date' },
                { label: 'Hour and Minute of Birth', type: 'hour-minute', hour: 'userInfo.birthHour',  minute: 'userInfo.birthMinute',placeholder: 'Enter hour' },
                { label: 'Gender', type: 'select', model: 'user.gender', options: [
                    { text: 'Male', value: 1 },
                    { text: 'Female', value: 2 },
                    { text: 'Other', value: 3 }]
                },
                { label: 'Relationship Status', type: 'number', model: 'userInfo.relationshipStatus', placeholder: 'Enter relationship status' },
                { label: 'Occupation ID', type: 'number', model: 'userInfo.occupationId', placeholder: 'Enter occupation ID' },
                { label: 'Place of Birth', type: 'text', model: 'userInfo.birthCityId', placeholder: 'Enter city' },
                { label: 'Order', type: 'number', model: 'publicFigureInfo.order', placeholder: 'Enter order' },
                { label: 'Is Alive', type: 'switch', model: 'publicFigureInfo.isAlive' },
                { label: 'Is Active', type: 'switch', model: 'publicFigureInfo.isActive' }
              ]"
              :detailEndpoint="`/admin/public-figure/${selectedObjId}`"
              @saveEndpoint="editObj"
              method="put"
              :totalDataName="'data'"
              :isOnlydata="true"
              @hide="showEditModal = false"
          />


          <CreateModal
              :show="showCreateModal"
              modalTitle="Create Public Figure"
              modalWidth="50%"
              :formFields="[
                { label: 'Name', type: 'text', model: 'name', placeholder: 'Enter name' },
                { label: 'Surname', type: 'text', model: 'surname', placeholder: 'Enter surname' },
                { label: 'Photo URL', type: 'text', model: 'photoUrl', placeholder: 'Enter photo URL' },
                { label: 'Date of Birth', type: 'date', model: 'dateOfBirth', placeholder: 'Select date' },
                { label: 'Hour and Minute of Birth', type: 'hour-minute', hour: 'hourOfBirth', minute: 'minuteOfBirth', placeholder: 'Enter hour' },
                { label: 'Gender', type: 'select', model: 'gender', options: [
                    { text: 'Male', value: 1 },
                    { text: 'Female', value: 2 },
                    { text: 'Other', value: 3 }]
                },
                { label: 'Relationship Status', type: 'number', model: 'relationshipStatus', placeholder: 'Enter relationship status' },
                { label: 'Occupation ID', type: 'number', model: 'occupationId', placeholder: 'Enter occupation ID' },
                { label: 'Place of Birth country', type: 'number', model: 'placeOfBirth.country', placeholder: 'Enter country' },
                { label: 'Place of Birth city', type: 'number', model: 'placeOfBirth.city', placeholder: 'Enter city' },
                { label: 'Place of Birth state', type: 'number', model: 'placeOfBirth.state', placeholder: 'Enter state' },
                { label: 'Order', type: 'number', model: 'order', placeholder: 'Enter order' },
                { label: 'Is Alive', type: 'switch', model: 'isAlive' },
                { label: 'Is Active', type: 'switch', model: 'isActive' }
              ]"
              @saveEndpoint="createObj"
              method="post"
              @hide="showCreateModal = false"
              @success="refreshTable"
          />



          <DeleteModal
              :show="showDeleteModal"
              :itemId="selectedDeleteId"
              modalTitle="Delete Public Figure"
              deleteMessage="Are you sure you want to delete this public figure?"
              confirmButtonText="Yes"
              :deleteEndpoint="`/app-settings/language/delete/${selectedDeleteId}`"
              @update:isVisible="showDeleteModal = false"
              @delete-success="refreshTable"
          />



          <Dialog header="Edit User" v-model:visible="showEditBirthdateModal" modal :style="{'width':'40%'}">
            <div class="row">
              <div class="col-6">
                <label class="d-block">Name</label>
                <InputText v-model="editUserModel.userInfo.name"/>
              </div>
              <div class="col-6">
                <label class="d-block">Surname</label>
                <InputText v-model="editUserModel.userInfo.surname"/>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <label class="d-block">Photo Url</label>
                <InputText v-model="editUserModel.userInfo.photoUrl"/>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-6">
                <label class="d-block">Active</label>
                <InputSwitch v-model="editUserModel.isActive"/>
              </div>
              <div class="col-6">
                <label class="d-block">Alive</label>
                <InputSwitch v-model="editUserModel.isAlive"/>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-6">
                <label class="d-block">Order</label>
                <InputNumber v-model="editUserModel.order"/>
              </div>
            </div>

            <div class="modal-footer mt-5">
              <button type="button" class="btn btn-default" @click="showEditBirthdateModal = false">
                Close
              </button>
              <Button label="Save" icon="pi pi-save" :loading="saveButtonLoading" iconPos="left" @click="editUser"/>
            </div>
          </Dialog>

          <Dialog header="New Public Figure" v-model:visible="showPublicFigureModal" modal :style="{'width':'40%'}">

            <div class="row">
              <div class="col-6">
                <label class="d-block">Name</label>
                <InputText v-model="newPublicFigureModel.name"/>
              </div>
              <div class="col-6">
                <label class="d-block">Surname</label>
                <InputText v-model="newPublicFigureModel.surname"/>
              </div>
            </div>

            <div class="col-12 mt-4">
              <label class="d-block">Birthdate</label>
              <Calendar
                  v-model="newPublicFigureModel.birthDate"
                  hourFormat="24"
                  dateFormat="dd/mm/yy"
                  class="w-100"
              />
            </div>
            <div class="row mt-4">

              <div class="col-6">
                <label class="d-block">Birth Hour</label>
                <InputNumber v-model="newPublicFigureModel.birthHour"/>
              </div>

              <div class="col-6">
                <label class="d-block">Birth Minute</label>
                <InputNumber v-model="newPublicFigureModel.birthMinute"/>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-6">
                <label class="d-block">Gender</label>
                <Dropdown :options="genders" v-model="newPublicFigureModel.gender" optionLabel="name" optionValue="id"/>
              </div>
              <div class="col-6">
                <label class="d-block">Order</label>
                <InputNumber v-model="newPublicFigureModel.order"/>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-6">
                <label class="d-block">Is Active</label>
                <InputSwitch v-model="newPublicFigureModel.isActive"/>
              </div>
              <div class="col-6">
                <label class="d-block">Is Alive</label>
                <InputSwitch v-model="newPublicFigureModel.isAlive"/>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-6">
                <label class="d-block">Photo Url</label>
                <InputText v-model="newPublicFigureModel.photoUrl"/>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-6">
                <label class="d-block">Occupations</label>
                <Dropdown
                    v-model="newPublicFigureModel.description"
                    :options="occupations"
                    optionLabel="name"
                    optionValue="id"
                />
              </div>

              <div class="col-6">
                <label class="d-block">Relationship</label>
                <Dropdown
                    v-model="newPublicFigureModel.relationship"
                    :options="relationships"
                    optionLabel="name"
                    optionValue="id"
                />
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-6">
                <label class="d-block">Country</label>
                <Dropdown
                    v-model="newPublicFigureModel.birthCountry"
                    :options="countries"
                    optionLabel="name"
                    optionValue="id"
                    filter="true"
                    @change="getCities"
                />
              </div>

              <div class="col-6">
                <label class="d-block">City</label>
                <Dropdown
                    v-model="newPublicFigureModel.birthCity"
                    :options="cities"
                    optionLabel="name"
                    optionValue="id"
                    filter="true"
                />
              </div>
            </div>

            <div class="modal-footer mt-5">
              <button type="button" class="btn btn-default" @click="showPublicFigureModal = false">
                Close
              </button>
              <Button label="Save" icon="pi pi-save" :loading="saveButtonLoading" iconPos="left"
                      @click="addPublicFigure"/>
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Header from "@/components/Header.vue";
import AstopiaTable from "@/components/Tables/AstopiaTable.vue";
import dayjs from "dayjs";
import "@vuepic/vue-datepicker/dist/main.css";
import Toast from 'primevue/toast';
import {mapGetters} from "vuex";

var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export default {
  name: "Users",
  components: {
    Header,
    Toast,
    AstopiaTable
  },
  data() {
    const schema = {
      price: "required|numeric|regex",
    };

    return {
      loading: false,
      totalRecords: 0,
      content: [],
      filters: {
        global: {value: null},
        name: {value: null},
        isPublicFigure: {value: true}
      },
      contentLazyParams: {},
      schema,
      houses: [],
      signs: [],
      genders: [{id: 1, name: "Man"}, {id: 2, name: "Woman"}, {id: 3, name: "Other"}],
      showEditBirthdateModal: false,
      selectedObjId: null,
      showEditModal: false,
      showCreateModal: false,
      showDeleteModal: false,
      selectedDeleteId: null,
      editUserModel: {},
      messages: [],
      messageCount: 0,
      saveButtonLoading: false,
      showPublicFigureModal: false,
      newPublicFigureModel: {},
      countries: [],
      cities: [],
      columns: [
        // {field: "Detail",header:"Detail", functionType: "detail", selected: true},
        {field: "user.name", header: "User Name", functionType: "res", selected: true},
        {field: "user.surname", header: "User surname", functionType: "res", selected: true},
        {field: "userInfo.userId", header: "User userId", functionType: "res", selected: true},
        {field: "Actions", header: "Actions", functionType: "crud", edit: true, delete: true, selected: true},
      ],
      data:{
        "name": "string",
        "surname": "string",
        "photoUrl": "string",
        "dateOfBirth": "string",
        "hourOfBirth": 0,
        "minuteOfBirth": 0,
        "gender": 0,
        "relationshipStatus": 0,
        "occupationId": 0,
        "placeOfBirth": {
          "city": 0,
          "country": 0,
          "state": 0
        },
        "order": 0,
        "isAlive": true,
        "isActive": true,
        "languageInfos": [
          {
            "key": "string",
            "isActive": true
          }
        ]
      },
      dataUpdate:{
        "name": "string",
        "surname": "string",
        "photoUrl": "string",
        "dateOfBirth": "string",
        "hourOfBirth": 0,
        "minuteOfBirth": 0,
        "gender": 0,
        "relationshipStatus": 0,
        "occupationId": 0,
        "placeOfBirth": {
          "city": 0,
          "country": 0,
          "state": 0
        },
        "order": 0,
        "isAlive": true,
        "isActive": true,
        "languageInfos": [
          {
            "key": "string",
            "isActive": true
          }
        ]
      }
    };
  },
  computed: {
    ...mapGetters(['occupations', 'signs', 'houses', 'relationships', 'occupationIds', 'relationshipIds']),
  },
  methods: {
    openEditModal(rowData) {
      console.log("rowData",rowData)
      //copy object
      this.editUserModel = rowData;
      this.showEditBirthdateModal = true;
    },
    deleteSee(report) {
      this.selectedDeleteId = report.id

      this.showDeleteModal = true
    },
    detailSee(obj) {
      this.selectedObjId = obj.publicFigureInfo.id
      this.showEditModal = true
    },
    editObj(obj) {
      console.log("obj",obj)
      this.dataUpdate.name = obj.name;
      this.dataUpdate.surname = obj.surname;
      this.dataUpdate.photoUrl = obj.photoUrl;
      this.dataUpdate.dateOfBirth = obj?.dateOfBirth;
      this.dataUpdate.hourOfBirth = obj?.hourOfBirth;
      this.dataUpdate.minuteOfBirth = obj?.minuteOfBirth;
      this.dataUpdate.gender = obj?.gender;
      this.dataUpdate.relationshipStatus = obj?.relationshipStatus;
      this.dataUpdate.occupationId = obj?.occupationId;
      this.data.placeOfBirth.country = obj?.placeOfBirth.country;
      this.data.placeOfBirth.city = obj?.placeOfBirth.city;
      this.data.placeOfBirth.state = obj?.placeOfBirth.state;
      this.dataUpdate.order = obj?.order;
      this.dataUpdate.isAlive = obj?.isAlive;
      this.dataUpdate.isActive = obj?.isActive;
      this.$appAxios.put(`/admin/public-figure/update/${this.selectedObjId}`, this.dataUpdate).then(response => {
        console.log(response)
        this.refresh()
        this.showEditModal = false
      }).catch(err => {
        console.log(err)
      })
    },
    createObj(obj) {
      console.log("obj",obj)
      this.data.name = obj.name;
      this.data.surname = obj.surname;
      this.data.photoUrl = obj.photoUrl;
      this.data.dateOfBirth = obj?.dateOfBirth;
      this.data.hourOfBirth = obj?.hourOfBirth;
      this.data.minuteOfBirth = obj?.minuteOfBirth;
      this.data.gender = obj?.gender;
      this.data.relationshipStatus = obj?.relationshipStatus;
      this.data.occupationId = obj?.occupationId;
      this.data.placeOfBirth.country = obj?.placeOfBirth?.country;
      this.data.placeOfBirth.city = obj?.placeOfBirth?.city;
      this.data.placeOfBirth.state = obj?.placeOfBirth?.state;
      this.data.order = obj?.order;
      this.data.isAlive = obj?.isAlive;
      this.data.isActive = obj?.isActive;
      this.$appAxios.post("/admin/public-figure/create", this.data).then(response => {
        console.log("response",response)
        this.refresh()
        this.showNewContentModal = false
      }).catch(err => {
        console.log(err)
      })
    },
    addPublicFigure() {
      console.log("kjsndkcjnskdjcnskjdcnksjdn", this.showCreateModal)
      this.showCreateModal = true
      console.log("kjsndkcjnskdjcnskjdcnksjdn", this.showCreateModal)

    },
    refreshTable() {
      this.$refs.astopiaTable.fetchData();  // AstopiaTable bileşeninden veriyi yeniden yükle
    }
  },
};
</script>
<style scoped>
.p-button-sm {
  height: 35px;
  width: 35px;
  font-size: 0.875rem;
  padding: 0.7rem;
}

.p-disabled,
.p-component:disabled {
  opacity: 0.8;
}

.p-dropdown {
  min-width: 200px;
}
</style>
  
<template>
  <Header/>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-12 m-0 no-max-w home-div">
          <Toast/>
          <ConfirmDialog/>
          <div class="d-flex justify-content-around mb-3 mt-3">
            <Card style="width: 35rem;">
              <template #title>
                Test API
              </template>
              <template #content>
                <div class="row mb-3 mt-3">
                  <div class="col-md-6 form-group">
                    <label for="endpoint_dropdown" class="d-block">Endpoint</label>
                    <Dropdown id="endpoint_dropdown" class="mb-3" v-model="endpointModel.endpoint" :options="endpoints"
                              optionLabel="name" optionValue="name" placeholder="Select an Endpoint"/>
                  </div>
                  <div class="col-md-6 form-group">
                    <label for="date_picker" class="d-block">Date</label>
                    <Datepicker id="date_picker" class="mb-3" style="width: 200px;"
                                v-model="endpointModel.date"></Datepicker>
                  </div>
                </div>
                <div class="row mb-3 mt-3">
                  <div class="col-md-6 form-group">
                    <label for="country_picker" class="d-block">Country</label>
                    <Dropdown id="country_picker" class="mb-3" @change="getCities" v-model="endpointModel.countryId"
                              :options="countries" optionLabel="name" optionValue="id" placeholder="Select a Country"
                              :filter="true" filterPlaceholder="Find Country"/>
                  </div>
                  <div class="col-md-6 form-group">
                    <label for="city_picker" class="d-block">City</label>
                    <Dropdown id="city_picker" class="mb-3" @change="getDistricts" v-model="endpointModel.cityId"
                              :options="cities" optionLabel="name" optionValue="id" placeholder="Select a City"
                              :filter="true" filterPlaceholder="Find City"/>
                  </div>
                </div>
                <label for="discrict_picker" class="d-block">District</label>
                <Dropdown id="discrict_picker" class="mb-3" v-model="endpointModel.districtId" :options="districts"
                          optionLabel="name" optionValue="id" placeholder="Select a Discrict" :filter="true"
                          filterPlaceholder="Find District"/>


              </template>
              <template #footer>
                <Button icon="pi pi-cloud-download" @click="fetchAPI()" label="Fetch API"/>
              </template>
            </Card>
            <Card style="width: 45rem;">
              <template #title>
                Result
              </template>
              <template #content>
                <div v-if="APIResult == null">
                  <div v-for="i in 10" :key="i">
                    <Skeleton class="mb-3"/>
                  </div>
                </div>
                <VueJsonPretty v-else :data="APIResult"/>
              </template>

            </Card>
          </div>

        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Header from "@/components/Header.vue";
//   import DataTable from "primevue/datatable";
//   import Column from "primevue/column";
import Button from "primevue/button";
import dayjs from "dayjs";
import "@vuepic/vue-datepicker/dist/main.css";
import Card from 'primevue/card';
//   import InputText from "primevue/inputtext";
//   import Textarea from "primevue/textarea";
// import TriStateCheckbox from "primevue/tristatecheckbox";
//   import MultiSelect from "primevue/multiselect";
import Dropdown from "primevue/dropdown";
//   import Dialog from "primevue/dialog";
// import InputSwitch from "primevue/inputswitch";
import Datepicker from "@vuepic/vue-datepicker";
//   import InputNumber from "primevue/inputnumber";
//   import Toast from 'primevue/toast';
//   import Message from 'primevue/message';
//   import ConfirmDialog from 'primevue/confirmdialog';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import Skeleton from 'primevue/skeleton';

export default {
  name: "TestAstrologyAPI",
  components: {
    // Form,
    // Field,
    // ErrorMessage,
    Header,
    Card,
    Button,
    //   DataTable,
    //   Column,
    //   InputText,
    //   Textarea,
    //   Button,
    // TriStateCheckbox,
    //   MultiSelect,
    Dropdown,
    //   Dialog,
    // InputSwitch,
    Datepicker,
    VueJsonPretty,
    Skeleton,
    //   InputNumber,
    //   Toast,
    //   Message,
    //   ConfirmDialog
  },
  data() {

    return {
      countries: [],
      cities: [],
      districts: [],
      endpointModel: {},
      APIResult: "",
      endpoints: [
        {name: 'planets/tropical', code: 'planets/tropical'},
        {name: 'house_cusps/tropical', code: 'house_cusps/tropical'},
        {name: 'lunar_metrics', code: 'lunar_metrics'},
        // {name: 'general_sign_report/tropical', code: 'general_sign_report/tropical'},
        // {name: 'general_house_report/tropical', code: 'general_house_report/tropical'},
        // {name: 'personality_report/tropical', code: 'personality_report/tropical'},
        {name: 'general_ascendant_report', code: 'general_ascendant_report'},
        {name: 'natal_wheel_chart', code: 'natal_wheel_chart'},
        {name: 'western_horoscope', code: 'western_horoscope'},
        {name: 'astro_details', code: 'astro_details'},
        {name: 'general_ascendant_report', code: 'general_ascendant_report'},
        {name: 'life_forecast_report/tropical', code: 'life_forecast_report/tropical'},
      ]
    };
  },
  mounted() {
    this.$appAxios.get("global/countries")
        .then(resp => {
          this.countries = resp.data.response
        })
        .catch(err => {
          console.log(err)
        });
  },
  computed: {},
  methods: {
    getCities(event) {
      var countryId = event.value;
      this.$appAxios.get("global/cities/" + countryId)
          .then(resp => {
            this.cities = resp.data.response;
          })
          .catch(err => {
            console.log(err)
          });
    },
    getDistricts(event) {
      var cityId = event.value;
      this.$appAxios.get("global/districts/" + cityId)
          .then(resp => {
            this.districts = resp.data.response;
          })
          .catch(err => {
            console.log(err)
          });
    },
    fetchAPI() {
      if (!this.endpointModel.endpoint) return;

      this.APIResult = null;
      var date = dayjs(this.endpointModel.date);
      this.$appAxios.post("astrologyAPI/test", {
        path: this.endpointModel.endpoint,
        year: date.year(),
        month: date.month() + 1,
        day: date.date(),
        hour: date.hour(),
        min: date.minute(),
        countryId: this.endpointModel.countryId,
        cityId: this.endpointModel.cityId,
        districtId: this.endpointModel.districtId
      })
          .then(resp => {
            this.APIResult = resp.data
          })
          .catch(err => {
            this.APIResult = err.response
          });
    }
  },
};
</script>
<style scoped>
.p-button-sm {
  height: 35px;
  width: 35px;
  font-size: 0.875rem;
  padding: 0.7rem;
}

.p-disabled,
.p-component:disabled {
  opacity: 0.8;
}

.p-dropdown {
  min-width: 200px;
}
</style>
  
<template>
  <Header/>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-12 m-0 no-max-w home-div">
          <Toast/>
          <DataTable :value="astrologs" :lazy="true" :paginator="true"
                     paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                     :rowsPerPageOptions="[5, 10, 20, 50, 100]" dataKey="id" :rows="10" ref="dt"
                     :totalRecords="astrologsTotalRecords" :loading="loading" @page="onAstrologsPage($event)"
                     @sort="onAstrologsSort($event)" @filter="onAstrologsFilter($event)" responsiveLayout="scroll"
                     class="p-datatable-striped" v-model:filters="filters" filterDisplay="menu" removableSort
                     :globalFilterFields="['name', 'isActive']"
                     currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
            <template #header>
              <div class="flex justify-content-between">
                <div class="flex">
                  <Button type="button" icon="pi pi-plus" label="Add Astrolog" class="p-button-info d-inline me-2"
                          @click="showNewAstrolog()"/>

                  <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)"/>
                </div>
              </div>
            </template>

            <template #empty> No astrologs found.</template>
            <template #loading> Loading astrologs data. Please wait.</template>
            <Column field="photoUrl" header="Photo" ref="photoUrl">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.photoUrl }}</span>
              </template>
            </Column>
            <Column field="name" header="Name" ref="name">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.name }}</span>
              </template>
            </Column>
            <Column field="surname" header="Surname" ref="surname">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.surname }}</span>
              </template>
            </Column>
            <Column field="email" header="Email" ref="email">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.email }}</span>
              </template>
            </Column>
            <Column field="gender" header="Gender" ref="gender">
              <template #body="slotProps">
                <span class="m-0">{{ this.genders.find(x => x.id == slotProps.data.gender).name }}</span>
              </template>
            </Column>
            <Column field="title" header="Title" ref="title">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.title }}</span>
              </template>
            </Column>
            <Column field="text" header="Text" ref="text">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.text }}</span>
              </template>
            </Column>
            <Column field="birthDate" header="Birth Date" ref="birthDate">
              <template #body="slotProps">
                <span class="m-0">{{ formatDate(slotProps.data.birthDate) }}</span>
              </template>
            </Column>
            <Column field="isActive" header="IsActive" ref="isActive">
              <template #body="slotProps">
                <span class="ml-2 custom-label custom-label-success" v-if="slotProps.data.isActive">
                  <span class="m-0">{{ slotProps.data.isActive }}</span>
                </span>
                <span class="ml-2 custom-label custom-label-danger" v-else>
                  <span class="m-0">{{ slotProps.data.isActive }}</span>
                </span>
              </template>
            </Column>
            <Column field="order" header="Order" ref="order">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.order }}</span>
              </template>
            </Column>
            <Column field="actions" header="Actions" ref="actions">
              <template #body="slotProps">
                <Button v-text="'TR'" class="p-button-primary p-button-sm me-2 my-2"
                        @click="showEditLanguage('programGroup', slotProps.data.id, 'tr')"/>
                <Button v-text="'EN'" class="p-button-primary p-button-sm me-2 my-2"
                        @click="showEditLanguage('programGroup', slotProps.data.id, 'en')"/>
                <Button icon="pi pi-pencil" class="p-button-warning p-button-sm me-2 my-2"
                        @click="openEditAstrolog(slotProps.data.id)"/>
                <Button icon="pi pi-box" class="p-button-warning p-button-sm me-2 my-2"
                        @click="openAstrologPackages(slotProps.data.id)"/>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>

      <Dialog header="New Astrolog" v-model:visible="showNewAstrologModal" modal :style="{'width':'40%'}">
        <Form>
          <div class="row mb-3 mt-3">
            <div class="col-md-12 form-group">
              <label class="d-block">Photo:</label>
              <InputText class="w-100" type="text" v-model="newAstrolog.photoUrl"/>
            </div>
            <div class="col-md-6 form-group">
              <label class="d-block">Name:</label>
              <InputText class="w-100" type="text" v-model="newAstrolog.name"/>
            </div>
            <div class="col-md-6 form-group">
              <label class="d-block">Surname:</label>
              <InputText class="w-100" type="text" v-model="newAstrolog.surname"/>
            </div>
            <div class="col-md-6 form-group">
              <label class="d-block">Email:</label>
              <InputText class="w-100" type="text" v-model="newAstrolog.email"/>
            </div>
            <div class="col-md-6 form-group">
              <label class="d-block">Birth Date:</label>
              <InputText class="w-100" type="date" v-model="newAstrolog.birthDate"/>
            </div>
            <div class="col-md-12 form-group">
              <label class="d-block">Title:</label>
              <InputText class="w-100" type="text" v-model="newAstrolog.title"/>
            </div>
            <div class="col-md-12 form-group">
              <label class="d-block">Text:</label>
              <Textarea class="w-100" type="text" rows="8" v-model="newAstrolog.text"/>
            </div>
            <div class="col-md-6 form-group">
              <label class="d-block">Gender:</label>
              <Dropdown :options="genders" v-model="newAstrolog.gender" optionLabel="name" optionValue="id"
                        class="w-100"/>
            </div>
            <div class="col-md-6 form-group">
              <label class="d-block">Order:</label>
              <InputNumber buttonLayout="horizontal" decrementButtonClass="p-button-secondary"
                           incrementButtonClass="p-button-secondary" incrementButtonIcon="pi pi-plus"
                           decrementButtonIcon="pi pi-minus" mode="decimal" showButtons class="w-100"
                           v-model="newAstrolog.order"/>
            </div>
          </div>
          <Message v-for="msg of messages" :severity="msg.severity" :key="msg.id" :life="5000" :sticky="false">
            {{ msg.content }}
          </Message>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="showNewAstrologModal = false">
              Close
            </button>
            <Button label="Save" icon="pi pi-save" iconPos="left" @click="addNewAstrolog()"/>
          </div>
        </Form>
      </Dialog>

      <Dialog header="Edit Astrolog" v-model:visible="showEditAstrolog" modal :style="{'width':'40%'}">
        <Form>

          <div class="row mb-3 mt-3">
            <div class="col-md-12 form-group">
              <label class="d-block">Photo:</label>
              <InputText class="w-100" type="text" v-model="editAstrologModel.photoUrl"/>
            </div>
            <div class="col-md-6 form-group">
              <label class="d-block">Name:</label>
              <InputText class="w-100" type="text" v-model="editAstrologModel.name"/>
            </div>
            <div class="col-md-6 form-group">
              <label class="d-block">Surname:</label>
              <InputText class="w-100" type="text" v-model="editAstrologModel.surname"/>
            </div>
            <div class="col-md-6 form-group">
              <label class="d-block">Email:</label>
              <InputText class="w-100" type="text" disabled v-model="editAstrologModel.email"/>
            </div>
            <div class="col-md-6 form-group">
              <label class="d-block">Birth Date:</label>
              <Datepicker class="w-100" type="date" v-model="editAstrologModel.birthDate"/>
            </div>
            <div class="col-md-6 form-group">
              <label class="d-block">Gender:</label>
              <Dropdown :options="genders" v-model="editAstrologModel.gender" optionLabel="name" optionValue="id"
                        class="w-100"/>
            </div>
            <div class="col-md-6 form-group">
              <label class="d-block">Order:</label>
              <InputNumber buttonLayout="horizontal" decrementButtonClass="p-button-secondary"
                           incrementButtonClass="p-button-secondary" incrementButtonIcon="pi pi-plus"
                           decrementButtonIcon="pi pi-minus" mode="decimal" showButtons class="w-100"
                           v-model="editAstrologModel.order"/>
            </div>
            <div class="col-md-12 form-group">
              <label class="d-block">Is Active:</label>
              <InputSwitch class="mt-2" v-model="editAstrologModel.isActive"/>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="showEditLanguageModal = false">
              Close
            </button>
            <Button label="Save" icon="pi pi-save" iconPos="left" @click="editAstrolog()"/>
          </div>
        </Form>
      </Dialog>

      <Dialog header="Edit Language" v-model:visible="showEditLanguageModal" modal :style="{'width':'40%'}">
        <Form>

          <div class="col-md-12 form-group">
            <label class="d-block">Title:</label>
            <InputText class="w-100" type="text" v-model="editLanguageModel.title"/>
          </div>
          <div class="row mb-3 mt-3">
            <div class="col-md-12 form-group">
              <label class="d-block">Text:</label>
              <Textarea class="w-100" type="text" rows="5" v-model="editLanguageModel.text"/>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-3">
              <InputText class="w-100" type="text" v-model="newAstrologTag.tag"/>
            </div>
            <div class="col-6">
              <Button class="d-inline" label="Add Tag" icon="pi pi-plus" @click="addNewAstrologTag"/>
            </div>
          </div>
          <Listbox :options="astrologTags" optionLabel="tag" optionValue="id" class="w-full md:w-14rem">
            <template #option="slotProps">
              <div class="d-flex justify-content-between">
                <span>{{ slotProps.option.tag }}</span>
                <Button icon="pi pi-trash" class="p-button-sm p-button-danger"
                        @click="deleteAstrologTag(slotProps.option.id)"/>
              </div>
            </template>
            <template #empty>
              <div class="p-col-12 p-text-center">
                No tags found.
              </div>
            </template>
          </Listbox>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="showEditLanguageModal = false">
              Close
            </button>
            <Button label="Save" icon="pi pi-save" iconPos="left" @click="editLanguage()"/>
          </div>


        </Form>
      </Dialog>

      <Dialog header="Astrolog Packages" v-model:visible="showAstrologPackagesModal" modal :style="{'width':'40%'}">
        <div class="row">
          <div class="col-6">
            <label>Question Type</label>
            <Dropdown :options="questionTypes" v-model="newAstrologPackage.questionTypeId" optionLabel="name"
                      optionValue="id" class="w-100"/>
          </div>
          <div class="col-6">
            <label>Package</label>
            <Dropdown :options="questionPackages.filter(x => x.questionType == newAstrologPackage.questionTypeId)"
                      v-model="newAstrologPackage.paymentPackageId" optionLabel="name" optionValue="id" class="w-100"/>
          </div>
        </div>
        <Button label="Add New Package" icon="pi pi-plus" class="mt-3 mb-5" @click="addNewAstrologPackage"/>
        <div class="row">
          <div class="col-12">
            <label>Packages</label>
            <Listbox :options="astrologPackages" optionLabel="name">
              <template #option="slotProps">
                <div class="d-flex justify-content-between">
                  <span>{{ slotProps.option.name }}</span>
                  <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                          @click="deleteAstrologPackage(slotProps.option.id)"/>
                </div>
              </template>
            </Listbox>
          </div>
        </div>
      </Dialog>
    </div>
  </main>
</template>

<script>
import Header from "@/components/Header.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import dayjs from "dayjs";
import "@vuepic/vue-datepicker/dist/main.css";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
// import TriStateCheckbox from "primevue/tristatecheckbox";
// import MultiSelect from "primevue/multiselect";
import Dropdown from "primevue/dropdown";
import Dialog from "primevue/dialog";
import InputSwitch from "primevue/inputswitch";
import Datepicker from "@vuepic/vue-datepicker";
import InputNumber from "primevue/inputnumber";
import Toast from 'primevue/toast';
import Message from 'primevue/message';

export default {
  name: "Workshops",
  components: {
    Header,
    DataTable,
    Column,
    InputText,
    Textarea,
    Button,
    Dropdown,
    Dialog,
    InputSwitch,
    Datepicker,
    InputNumber,
    Toast,
    Message
  },
  data() {
    const schema = {
      price: "required|numeric|regex",
    };

    return {
      loading: false,
      astrologsTotalRecords: 0,
      astrologs: [],
      filters: {
        global: {value: null},
        name: {value: null},
      },
      astrologsLazyParams: {},
      schema,
      houses: [],
      signs: [],
      genders: [{id: 1, name: "Man"}, {id: 2, name: "Woman"}],
      showNewAstrologModal: false,
      showEditLanguageModal: false,
      showEditAstrolog: false,
      newAstrolog: {
        photoUrl: "",
        name: "",
        surname: "",
        email: "",
        birthDate: "",
        title: "",
        text: "",
        gender: 0,
        order: 0
      },
      editAstrologModel: {},
      messages: [],
      messageCount: 0,
      showAstrologPackagesModal: false,
      questionTypes: [
        {id: 1, name: "Yıllık Öngörü"},
        {id: 2, name: "İlişki Uyumu"},
        {id: 3, name: "Danışmana Sor"},
        {id: 4, name: "Rektifikasyon"},
        {id: 5, name: "Natal Analiz"},
        {id: 6, name: "Eleksiyon"},
        {id: 7, name: "Coğrafi Astroloji / Astrokartografi"},
        {id: 8, name: "Horary"},
      ],
      newAstrologPackage: {},
      questionPackages: [],
      astrologTags: [],
      newAstrologTag: {}
    };
  },
  mounted() {
    this.astrologsLazyParams = {
      first: 0,
      rows: this.$refs.dt.rows,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };
    this.$appAxios
        .get("admin/astrolog/payment-packages")
        .then((resp) => {
          this.questionPackages = resp.data;
        });

    this.getAstrologs();
  },
  computed: {},
  methods: {
    getAstrologs() {
      this.loading = true;

      var filters = {};

      for (const [key] of Object.entries(this.astrologsLazyParams.filters)) {
        filters[key] = this.astrologsLazyParams.filters[key].value;
      }

      this.$appAxios
          .post("admin/astrolog/list", {
            first: this.astrologsLazyParams.first,
            rows: this.astrologsLazyParams.rows,
            sortField: this.astrologsLazyParams.sortField,
            sortOrder: this.astrologsLazyParams.sortOrder,
            page: this.astrologsLazyParams.page,
            filters: JSON.stringify(filters),
          })
          .then((resp) => {
            this.astrologs = resp.data.data;
            this.astrologsTotalRecords = resp.data.totalRecords;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
    },
    onAstrologsPage(event) {
      this.astrologsLazyParams = event;
      this.astrologsLazyParams.filters = this.filters;
      this.getAstrologs();
    },
    onAstrologsSort(event) {
      this.astrologsLazyParams = event;
      this.astrologsLazyParams.filters = this.filters;
      this.getAstrologs();
    },
    onAstrologsFilter() {
      this.astrologsLazyParams.filters = this.filters;
      this.getAstrologs();
    },
    clearFilter() {
      for (const [key] of Object.entries(this.filters)) {
        this.filters[key].value = null;
      }
      this.getContents();
    },
    formatDate(dateString) {
      if (dateString == null) {
        return "";
      }

      const date = dayjs(dateString);
      return date.format("DD/MM/YYYY");
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    showNewAstrolog() {
      this.showNewAstrologModal = true;
    },
    showEditLanguage(type, id, language) {

      this.$appAxios.get("admin/astrolog/translation/" + id + "/" + language)
          .then(resp => {
            this.editLanguageModel = resp.data;
            this.showEditLanguageModal = true;
          })
          .catch(err => {
            console.log(err)
          });

      this.$appAxios.get("admin/astrolog/tag/" + id + "/" + language)
          .then(resp => {
            this.astrologTags = resp.data;
            this.newAstrologTag.userInfoId = id;
          })
    },
    editLanguage() {
      if (!(this.editLanguageModel.text && this.editLanguageModel.title && this.editLanguageModel.language)) {
        this.messages = [{severity: 'warn', content: 'Required fields must be entered.', id: this.messageCount++}];
        return;
      }
      this.$appAxios.put("admin/astrolog/translation",
          {
            id: this.editLanguageModel.id,
            title: this.editLanguageModel.title,
            text: this.editLanguageModel.text,
            language: this.editLanguageModel.language,
          }
      ).then(() => {
        this.showEditLanguageModal = false;
        this.$toast.add({severity: 'success', summary: 'Success', detail: 'Language info updated', life: 3000});
        this.getAstrologs();
      })
          .catch(() => {
            this.showEditLanguageModal = false;
            this.$toast.add({severity: 'error', summary: 'Error', detail: 'Language info not updated', life: 3000});
          })
    },
    addNewAstrolog() {
      if (!(this.newAstrolog.title && this.newAstrolog.text)) {
        this.messages = [{severity: 'warn', content: 'Required fields must be entered.', id: this.messageCount++}];
        return;
      }
      this.$appAxios.post("astrolog", {
        photoUrl: this.newAstrolog.photoUrl,
        name: this.newAstrolog.name,
        surname: this.newAstrolog.surname,
        email: this.newAstrolog.email,
        birthDate: this.newAstrolog.birthDate,
        gender: this.newAstrolog.gender,
        order: this.newAstrolog.order,
        isActive: this.newAstrolog.isActive,
        text: this.newAstrolog.text,
        title: this.newAstrolog.title
      }).then(() => {
        this.showNewAstrologModal = false;
        this.$toast.add({severity: 'success', summary: 'Success', detail: 'Astrolog added successfully', life: 3000});
        this.getAstrologs();
      }).catch(() => {
        this.showNewAstrologModal = false;

        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'An error occured while adding astrolog',
          life: 3000
        });
      });
    },
    openEditAstrolog(id) {
      this.$appAxios.get("admin/astrolog/" + id)
          .then(resp => {
            this.editAstrologModel = resp.data;
            this.showEditAstrolog = true;
          })
          .catch(err => {
            console.log(err)
          });
    },
    editAstrolog() {
      this.$appAxios.put("admin/astrolog", {
        order: this.editAstrologModel.order,
        isActive: this.editAstrologModel.isActive,
        photoUrl: this.editAstrologModel.photoUrl,
        name: this.editAstrologModel.name,
        surname: this.editAstrologModel.surname,
        birthDate: this.editAstrologModel.birthDate,
        gender: this.editAstrologModel.gender,
        id: this.editAstrologModel.id,
        email: this.editAstrologModel.email
      }).then(() => {
        this.showEditAstrolog = false;
        this.$toast.add({severity: 'success', summary: 'Success', detail: 'Astrolog added successfully', life: 3000});
        this.getAstrologs();
      }).catch(() => {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'An error occured while adding astrolog',
          life: 3000
        });
      });
    },
    openAstrologPackages(id) {
      this.$appAxios.get("admin/astrolog/payment-packages/" + id)
          .then(resp => {
            this.astrologPackages = resp.data;
            this.newAstrologPackage = {
              astrologInfoId: id
            };
            this.showAstrologPackagesModal = true;
          })
          .catch(err => {
            console.log(err)
          });
    },
    addNewAstrologPackage() {
      this.$appAxios.post("admin/astrolog/payment-package/",
          {
            astrologInfoId: this.newAstrologPackage.astrologInfoId,
            paymentPackageId: this.newAstrologPackage.paymentPackageId,
          })
          .then(() => {
            this.openAstrologPackages(this.newAstrologPackage.astrologInfoId);
          })
          .catch(err => {
            console.log(err)
            this.$toast.add({severity: 'error', summary: 'Error', detail: err.error, life: 3000});
          });
    },
    deleteAstrologPackage(packageId) {
      this.$appAxios.post("admin/astrolog/payment-package/delete",
          {
            astrologInfoId: this.newAstrologPackage.astrologInfoId,
            paymentPackageId: packageId,
          })
          .then(() => {
            this.openAstrologPackages(this.newAstrologPackage.astrologInfoId);
          })
          .catch(err => {
            console.log(err)
            this.$toast.add({severity: 'error', summary: 'Error', detail: err.error, life: 3000});
          });
    },
    addNewAstrologTag() {
      this.$appAxios.post("admin/astrolog/tag",
          {
            userInfoId: this.newAstrologTag.userInfoId,
            tag: this.newAstrologTag.tag,
            language: this.editLanguageModel.language
          })
          .then(() => {
            this.newAstrologTag.tag = "";
            this.showEditLanguage("", this.newAstrologTag.userInfoId, this.editLanguageModel.language);
          })
          .catch(err => {
            console.log(err)
            this.$toast.add({severity: 'error', summary: 'Error', detail: err.error, life: 3000});
          });
    },
    deleteAstrologTag(tagId) {
      this.$appAxios.delete("admin/astrolog/tag/" + tagId)
          .then(() => {
            this.showEditLanguage("", this.newAstrologTag.userInfoId, this.editLanguageModel.language);
          })
          .catch(err => {
            console.log(err)
            this.$toast.add({severity: 'error', summary: 'Error', detail: err.error, life: 3000});
          });
    }
  },
};
</script>
<style scoped>
.p-button-sm {
  height: 35px;
  width: 35px;
  font-size: 0.875rem;
  padding: 0.7rem;
}

.p-disabled,
.p-component:disabled {
  opacity: 0.8;
}

.p-dropdown {
  min-width: 200px;
}
</style>

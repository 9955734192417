<template>
  <Header/>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-12 m-0 no-max-w home-div">
          <Toast/>
          <ConfirmDialog/>
          <DataTable :value="contents" :lazy="true" :paginator="true"
                     paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                     :rowsPerPageOptions="[5, 10, 20, 50, 100]" dataKey="id" :rows="10" ref="dt"
                     :totalRecords="contentsTotalRecords" :loading="loading" @page="onContentsPage($event)"
                     @sort="onContentsSort($event)" @filter="onContentsFilter($event)" responsiveLayout="scroll"
                     class="p-datatable-striped" v-model:filters="filters" filterDisplay="menu" removableSort
                     :globalFilterFields="['name', 'isActive']"
                     currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
            <template #header>
              <div class="d-flex justify-content-between">
                <div>
                  <Button type="button" icon="pi pi-plus" label="Add Content" class="p-button-info d-inline me-2"
                          @click="showNewContent()"/>
                  <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)"/>
                </div>
                <span class="p-input-icon-left">
                  <i class="pi pi-search"/>
                  <InputText v-model="filters['global'].value" placeholder="Keyword Search" @change="getContents"/>
                </span>
              </div>
            </template>
            <template #empty> No natal contents found.</template>
            <template #loading> Loading natal contents data. Please wait.</template>
            <Column field="id" header="Id" ref="id">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.id }}</span>
              </template>
            </Column>
            <Column field="title" header="Title" ref="title">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.title }}</span>
              </template>
            </Column>
            <Column field="text" header="Text" ref="text">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.text }}</span>
              </template>
            </Column>
            <Column field="gender" header="Gender" ref="gender">
              <template #body="slotProps">
                <span class="m-0">{{ this.genders.find(x => x.id == slotProps.data.gender)?.name }}</span>
              </template>
            </Column>
            <Column field="sign" header="Sign" ref="sign">
              <template #body="slotProps">
                <span class="m-0">{{
                    slotProps.data.signId != null ? this.signs.find(x => x.id ==
                        slotProps.data.signId).name : ''
                  }}</span>
              </template>
            </Column>
            <Column field="house" header="House" ref="house">
              <template #body="slotProps">
                <span class="m-0">{{
                    slotProps.data.houseId != null ? this.houses.find(x => x.id ==
                        slotProps.data.houseId).name : ''
                  }} </span>
              </template>
            </Column>
            <Column field="planet" header="Planet" ref="planet">
              <template #body="slotProps">
                <span class="m-0">{{
                    slotProps.data.planetId != null ? this.planets.find(x => x.id ==
                        slotProps.data.planetId).name : ''
                  }} </span>
              </template>
            </Column>
            <Column field="actions" header="Actions" ref="actions">
              <template #body="slotProps">
                <Button v-text="'TR'" class="p-button-sm me-2 my-2"
                        :class="{ 'p-button-danger': !slotProps.data.isVerified['tr'], 'p-button-success': slotProps.data.isVerified['tr'] }"
                        @click="showEditLanguage(slotProps.data.id, 'tr')"/>
                <Button v-text="'EN'" class="p-button-sm me-2 my-2"
                        :class="{ 'p-button-danger': !slotProps.data.isVerified['en'], 'p-button-success': slotProps.data.isVerified['en'] }"
                        @click="showEditLanguage(slotProps.data.id, 'en')"/>
                <Button icon="pi pi-pencil" class="p-button-warning p-button-sm me-2 my-2"
                        @click="openEditContent(slotProps.data.id)"/>
                <Button icon="pi pi-trash" class="p-button-danger p-button-sm me-2 my-2"
                        @click="deleteContent(slotProps.data.id)"/>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>

      <Dialog header="New Natal Content" v-model:visible="showNewContentModal" modal :style="{ 'width': '40%' }">
        <Form>
          <div class="row mb-3 mt-3">
            <div class="col-md-12 form-group">
              <label class="d-block">Title:</label>
              <InputText class="w-100" type="text" v-model="newContent.title"/>
            </div>
            <div class="col-md-12 form-group">
              <label class="d-block">Text:</label>
              <Textarea class="w-100" type="text" rows="8" v-model="newContent.text"/>
            </div>
            <div class="col-md-6 form-group">
              <label class="d-block">Sign:</label>
              <Dropdown v-model="newContent.signId" :options="signs" optionLabel="name" optionValue="id"
                        placeholder="Select Sign"/>
            </div>
            <div class="col-md-6 form-group">
              <label class="d-block">Gender:</label>
              <Dropdown v-model="newContent.gender" :options="genders" optionLabel="name" optionValue="id"
                        placeholder="Select Gender"/>
            </div>
            <div class="col-md-6 form-group">
              <label class="d-block">House:</label>
              <Dropdown v-model="newContent.houseId" :options="houses" optionLabel="name" optionValue="id"
                        placeholder="Select House"/>
            </div>
            <div class="col-md-6 form-group">
              <label class="d-block">Planet:</label>
              <Dropdown v-model="newContent.planetId" :options="planets" optionLabel="name" optionValue="id"
                        placeholder="Select Planet"/>
            </div>
          </div>
          <Message v-for="msg of messages" :severity="msg.severity" :key="msg.id" :life="5000" :sticky="false">
            {{ msg.content }}
          </Message>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="showNewDailyContentModal = false">
              Close
            </button>
            <Button label="Save" icon="pi pi-save" iconPos="left" @click="addNewContent()"/>
          </div>
        </Form>
      </Dialog>

      <Dialog header="Edit Language" v-model:visible="showEditLanguageModal" modal :style="{ 'width': '40%' }">
        <Form>
          <div class="col-12">
            <label class="d-block">Verified</label>
            <InputSwitch v-model="editLanguageModel.isVerified"/>
          </div>
          <div class="col-md-12 form-group">
            <label class="d-block">Title:</label>
            <InputText class="w-100" type="text" v-model="editLanguageModel.title"/>
          </div>
          <div class="row mb-3 mt-3">
            <div class="col-md-12 form-group">
              <label class="d-block">Text:</label>
              <Textarea class="w-100" type="text" rows="5" v-model="editLanguageModel.text"/>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="showEditLanguageModal = false">
              Close
            </button>
            <Button label="Save" icon="pi pi-save" iconPos="left" @click="editLanguage()"/>
          </div>
        </Form>
      </Dialog>

      <Dialog header="Edit Content" v-model:visible="showEditContent" modal :style="{ 'width': '40%' }">
        <Form>
          <div class="row mb-3 mt-3">
            <div class="col-md-6 form-group">
              <label class="d-block">Sign:</label>
              <Dropdown v-model="editContentModel.signId" :options="signs" optionLabel="name" optionValue="id"
                        placeholder="Select Sign"/>
            </div>
            <div class="col-md-6 form-group">
              <label class="d-block">House:</label>
              <Dropdown v-model="editContentModel.houseId" :options="houses" optionLabel="name" optionValue="id"
                        placeholder="Select House"/>
            </div>
            <div class="col-md-6 form-group">
              <label class="d-block">Planet:</label>
              <Dropdown v-model="editContentModel.planetId" :options="planets" optionLabel="name" optionValue="id"
                        placeholder="Select Planet"/>
            </div>
            <div class="col-md-6 form-group">
              <label class="d-block">Gender:</label>
              <Dropdown v-model="editContentModel.gender" :options="genders" optionLabel="name" optionValue="id"
                        placeholder="Select Gender"/>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="showEditContent = false">
              Close
            </button>
            <Button label="Save" icon="pi pi-save" iconPos="left" @click="editContent()"/>
          </div>
        </Form>
      </Dialog>

    </div>
  </main>
  <vue-scroll-up custom-class="my-scroll-up" :scroll-duration="20">
    <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
          d="M16.778 9.24268C17.1685 8.85215 17.1685 8.21899 16.778 7.82846L9.70693 0.757398C9.31641 0.366874 8.68324 0.366875 8.29272 0.757399L1.22165 7.82846C0.831127 8.21898 0.831127 8.85215 1.22165 9.24268C1.61218 9.6332 2.24534 9.6332 2.63586 9.24268L8.29272 3.58582C8.68324 3.1953 9.31641 3.1953 9.70693 3.58582L15.3638 9.24268C15.7543 9.6332 16.3875 9.6332 16.778 9.24268Z"
          fill="#FFFFFF"/>
    </svg>
  </vue-scroll-up>
</template>

<script>
import Header from "@/components/Header.vue";
import VueScrollUp from "vue-scroll-up";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import dayjs from "dayjs";
import "@vuepic/vue-datepicker/dist/main.css";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import Dropdown from "primevue/dropdown";
import Dialog from "primevue/dialog";
import Toast from 'primevue/toast';
import Message from 'primevue/message';

export default {
  name: "Workshops",
  components: {
    VueScrollUp,
    Header,
    DataTable,
    Column,
    InputText,
    Textarea,
    Button,
    Dropdown,
    Dialog,
    Toast,
    Message
  },
  data() {
    const schema = {
      price: "required|numeric|regex",
    };

    return {
      loading: false,
      contentsTotalRecords: 0,
      contents: [],
      filters: {
        global: {value: null},
        name: {value: null},
      },
      contentsLazyParams: {},
      schema,
      houses: [],
      signs: [],
      planets: [],
      showNewContentModal: false,
      showEditLanguageModal: false,
      showEditContent: false,
      newContent: {
        title: "",
        text: "",
        houseId: null,
        signId: null,
      },
      editContentModel: {},
      editLanguageModel: {},
      genders: [{id: 0, name: "All"}, {id: 1, name: "Man"}, {id: 2, name: "Woman"}],
    };
  },
  mounted() {
    this.contentsLazyParams = {
      first: 0,
      rows: this.$refs.dt.rows,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };

    this.$appAxios.get("admin/global/planets")
        .then(resp => {

          this.planets = resp.data;

          this.$appAxios.get("admin/global/houses")
              .then(resp => {

                this.houses = resp.data;

                this.$appAxios.get("admin/global/signs")
                    .then(resp => {

                      this.signs = resp.data;
                      this.getContents();

                    }).catch(err => {
                  console.log(err);
                })
              }).catch(err => {
            console.log(err);
          })
        }).catch(err => {
      console.log(err);
    })

  },
  computed: {},
  methods: {
    getContents() {
      this.loading = true;

      var filters = {};

      for (const [key] of Object.entries(this.contentsLazyParams.filters)) {
        filters[key] = this.contentsLazyParams.filters[key].value;
      }

      this.$appAxios
          .post("admin/natalContent/list", {
            first: this.contentsLazyParams.first,
            rows: this.contentsLazyParams.rows,
            sortField: this.contentsLazyParams.sortField,
            sortOrder: this.contentsLazyParams.sortOrder,
            page: this.contentsLazyParams.page,
            filters: JSON.stringify(filters),
          })
          .then((resp) => {
            this.contents = resp.data.data;
            this.contentsTotalRecords = resp.data.totalRecords;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
    },
    onContentsPage(event) {
      this.contentsLazyParams = event;
      this.contentsLazyParams.filters = this.filters;
      this.getContents();
    },
    onContentsSort(event) {
      this.contentsLazyParams = event;
      this.contentsLazyParams.filters = this.filters;
      this.getContents();
    },
    onContentsFilter() {
      this.contentsLazyParams.filters = this.filters;
      this.getContents();
    },
    clearFilter() {
      for (const [key] of Object.entries(this.filters)) {
        this.filters[key].value = null;
      }
      this.getContents();
    },
    formatDate(dateString) {
      if (dateString == null) {
        return "";
      }

      const date = dayjs(dateString);
      return date.format("DD/MM/YYYY HH:mm");
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    showNewContent() {
      this.showNewContentModal = true;
    },
    showEditLanguage(id, language) {

      this.$appAxios.get("admin/natalContent/translation/" + id + "/" + language)
          .then(resp => {
            this.editLanguageModel = resp.data;
            this.showEditLanguageModal = true;
          })
          .catch(err => {
            console.log(err)
          });
    },
    editLanguage() {
      if (!(this.editLanguageModel.text && this.editLanguageModel.title && this.editLanguageModel.language)) {
        this.messages = [{severity: 'warn', content: 'Required fields must be entered.', id: this.messageCount++}];
        return;
      }
      this.$appAxios.put("admin/natalContent/translation",
          {
            id: this.editLanguageModel.id,
            title: this.editLanguageModel.title,
            text: this.editLanguageModel.text,
            language: this.editLanguageModel.language,
            isVerified: this.editLanguageModel.isVerified
          }
      ).then(() => {
        this.getContents();
        this.showEditLanguageModal = false;
        this.$toast.add({severity: 'success', summary: 'Success', detail: 'Language info updated', life: 3000});
      })
          .catch(() => {
            this.showEditLanguageModal = false;
            this.$toast.add({severity: 'error', summary: 'Error', detail: 'Language info not updated', life: 3000});
          })
    },
    addNewContent() {
      if (!(this.newContent.title && this.newContent.text && this.newContent.signId != null && this.newContent.houseId != null)) {
        this.messages = [{severity: 'warn', content: 'Required fields must be entered.', id: this.messageCount++}];
        return;
      }
      this.$appAxios.post("admin/natalContent", {
        houseId: this.newContent.houseId,
        signId: this.newContent.signId,
        title: this.newContent.title,
        text: this.newContent.text,
        gender: this.newContent.gender
      }).then(() => {
        this.showNewContentModal = false;
        this.$toast.add({severity: 'success', summary: 'Success', detail: 'Content added successfully', life: 3000});
        this.getContents();
      }).catch(() => {
        this.showNewContentModal = false;
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'An error occured while adding content',
          life: 3000
        });
      });
    },
    openEditContent(id) {
      this.$appAxios.get("admin/natalContent/" + id)
          .then(resp => {
            this.editContentModel = resp.data;
            this.showEditContent = true;
          })
          .catch(err => {
            console.log(err)
          });
    },
    editContent() {
      this.$appAxios.put("admin/natalContent", {
        id: this.editContentModel.id,
        houseId: this.editContentModel.houseId,
        signId: this.editContentModel.signId,
        gender: this.editContentModel.gender
      })
          .then(() => {
            this.showEditContent = false;
            this.$toast.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Content added successfully',
              life: 3000
            });
            this.getContents();
          })
          .catch(() => {
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occured while adding content',
              life: 3000
            });
          });
    },
    deleteContent(id) {
      this.$confirm.require({
        message: 'Are you sure that you want to delete this content?',
        header: 'Delete Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$appAxios.delete("admin/natalContent/" + id)
              .then(() => {
                this.$toast.add({
                  severity: 'success',
                  summary: 'Success',
                  detail: 'Content deleted successfully',
                  life: 3000
                });
                this.getContents();
              })
              .catch(() => {
                this.$toast.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'An error occured while deleting content',
                  life: 3000
                });
              });
        },
        reject: () => {
          //do nothing
        }
      });
    },
  },
};
</script>
<style scoped>
.p-button-sm {
  height: 35px;
  width: 35px;
  font-size: 0.875rem;
  padding: 0.7rem;
}

.p-disabled,
.p-component:disabled {
  opacity: 0.8;
}

.p-dropdown {
  min-width: 200px;
}
</style>

<template>
  <Header/>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-12 m-0 no-max-w home-div">
          <Toast/>
          <ConfirmDialog/>
          <DataTable
              :value="contents"
              :lazy="true"
              :paginator="true"
              v-model:expandedRows="expandedRows"
              @row-expand="onContentsExpand($event)"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              :rowsPerPageOptions="[5, 10, 20, 50, 100]"
              dataKey="id"
              :rows="10"
              ref="dt"
              :totalRecords="contentsTotalRecords"
              :loading="loading"
              @page="onContentsPage($event)"
              @sort="onContentsSort($event)"
              @filter="onContentsFilter($event)"
              responsiveLayout="scroll"
              class="p-datatable-striped"
              v-model:filters="filters"
              filterDisplay="menu" removableSort
              :globalFilterFields="['global', 'name', 'isActive', 'relationshipStatusIds']"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          >
            <template #header>
              <div class="d-flex justify-content-between">
                <div>
                  <Button type="button" icon="pi pi-plus" label="Add Content" class="p-button-info d-inline me-2"
                          @click="showNewContentModal = true"/>
                  <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)"/>
                  <Dropdown v-model="selectedLanguage" :options="supportedLanguages" optionLabel="name" optionValue="id"
                            @change="getContents" class="ms-2" placeholder="Select Language"/>
                </div>
                <div>
                  <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined d-inline me-2"
                          @click="clearFilter()"/>
                  <span class="p-input-icon-left">
                    <i class="pi pi-search"/>
                    <InputText v-model="filters['global'].value" placeholder="Keyword Search" @change="getContents"/>
                  </span>
                </div>
              </div>
            </template>

            <template #empty> No stories found.</template>
            <template #loading> Loading stories contents data. Please wait.</template>
            <Column :expander="true" headerStyle="width: 3rem"/>
            <Column field="id" header="Id" ref="id">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.id }}</span>
              </template>
            </Column>
            <Column field="title" header="Title" ref="title">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.title }}</span>
              </template>
            </Column>
<!--            <Column field="storyPrefix" header="Story Prefix" ref="storyPrefix">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.storyPrefix }}</span>
              </template>
            </Column>-->
            <Column field="text" header="Text" ref="text">
              <template #body="slotProps">
                <ScrollPanel style="width: 100%; height: 100px" class="custombar">
                  <span class="m-0">{{ slotProps.data.text }}</span>
                </ScrollPanel>
              </template>
            </Column>
            <Column field="isGeneral" header="General" ref="isGeneral">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.isGeneral ? 'Yes' : 'No' }}</span>
              </template>
            </Column>
            <Column field="photoUrl" header="Photo Url" ref="photoUrl">
              <template #body="slotProps">
                <img :src="slotProps.data.photoUrl" alt="Photo" width="100" height="100"/>
              </template>
            </Column>
            <Column field="startDate" header="Start Date">
              <template #body="slotProps">
                <span class="m-0">{{ formatDate(slotProps.data.startDate) }}</span>
              </template>
            </Column>
            <Column field="endDate" header="End Date">
              <template #body="slotProps">
                <span class="m-0">{{ formatDate(slotProps.data.endDate) }}</span>
              </template>
            </Column>
            <Column field="type" header="Type"/>
            <Column field="actions" header="Actions" ref="actions">
              <template #body="slotProps">
                <Button v-text="'TR'" v-if="selectedLanguage === 'tr'" class="p-button-sm me-2 my-2"
                        :class="{ 'p-button-danger': !slotProps.data.isVerified['tr'], 'p-button-success': slotProps.data.isVerified['tr'] }"
                        @click="showEditLanguage('programGroup', slotProps.data.id, 'tr')"/>
                <Button v-text="'EN'" v-if="selectedLanguage === 'en'" class="p-button-sm me-2 my-2"
                        :class="{ 'p-button-danger': !slotProps.data.isVerified['en'], 'p-button-success': slotProps.data.isVerified['en'] }"
                        @click="showEditLanguage('programGroup', slotProps.data.id, 'en')"/>
                <Button v-text="'ES'" v-if="selectedLanguage === 'es'" class="p-button-sm me-2 my-2"
                        :class="{ 'p-button-danger': !slotProps.data.isVerified['es'], 'p-button-success': slotProps.data.isVerified['es'] }"
                        @click="showEditLanguage('programGroup', slotProps.data.id, 'es')"/>
                <Button icon="pi pi-pencil" class="p-button-warning p-button-sm me-2 my-2"
                        @click="openEditContent(slotProps.data.id)"/>
                <Button icon="pi pi-trash" class="p-button-danger p-button-sm me-2 my-2"
                        @click="deleteContent(slotProps.data.id)"/>
              </template>
            </Column>
            <template #expansion="slotProps">
              <DataTable :value="slotProps.data.expandedTable" :lazy="true" dataKey="id"
                         :first="subContentsLazyParams.first"
                         :rows="subContentsLazyParams.rows" ref="dt" responsiveLayout="scroll"
                         class="p-datatable-striped"
                         :paginator="true"
                         paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                         :rowsPerPageOptions="[5, 10, 20, 50, 100]" :totalRecords="subContentsTotalRecords"
                         :loading="loading"
                         @page="onSubContentPage($event)">
                <template #header>
                  <div class="table-header">
                    <Button label="Add Subcontent" icon="pi pi-plus" @click="showNewSubContentModal = true"></Button>
                  </div>
                </template>
                <Column field="photoUrl" header="Photo" ref="photoUrl">
                  <template #body="slotProps">
                    <img :src="slotProps.data.photoUrl" style="width:40px"/>
                  </template>
                </Column>
                <Column field="title" header="Title" ref="title"></Column>
                <Column field="text" header="Text" ref="text"></Column>
                <Column field="type" header="Type" ref="type"></Column>
                <Column field="order" header="Order" ref="order"></Column>
                <Column field="actions" header="Actions" ref="actions">
                  <template #body="slotProps">
                    <Button v-text="'TR'" class="p-button-sm me-2 my-2" v-if="selectedLanguage === 'tr'"
                            :class="{ 'p-button-danger': !slotProps.data.isVerified['tr'], 'p-button-success': slotProps.data.isVerified['tr'] }"
                            @click="openSubContentEditLanguage('', slotProps.data.id, 'tr')"/>
                    <Button v-text="'EN'" class="p-button-sm me-2 my-2" v-if="selectedLanguage === 'en'"
                            :class="{ 'p-button-danger': !slotProps.data.isVerified['en'], 'p-button-success': slotProps.data.isVerified['en'] }"
                            @click="openSubContentEditLanguage('', slotProps.data.id, 'en')"/>
                    <Button v-text="'ES'" class="p-button-sm me-2 my-2" v-if="selectedLanguage === 'es'"
                            :class="{ 'p-button-danger': !slotProps.data.isVerified['es'], 'p-button-success': slotProps.data.isVerified['es'] }"
                            @click="openSubContentEditLanguage('', slotProps.data.id, 'es')"/>
                    <Button icon="pi pi-pencil" class="p-button-warning p-button-sm me-2 my-2"
                            @click="openEditSubContent(slotProps.data.id)"/>
                    <Button icon="pi pi-trash" class="p-button-danger p-button-sm me-2 my-2"
                            @click="deleteSubContent(slotProps.data.id)"/>
                  </template>
                </Column>

              </DataTable>
            </template>
          </DataTable>
        </div>
      </div>

      <Dialog header="New Story" v-model:visible="showNewContentModal" modal :style="{ 'width': '40%' }">
        <div class="row mb-4">
          <div class="col-6">
            <label class="d-block">General:</label>
            <InputSwitch v-model="newContent.isGeneral"/>
          </div>
          <div class="col-6">
            <label class="d-block">Premium</label>
            <InputSwitch v-model="newContent.isPremium"/>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-6" v-if="!newContent.isGeneral">
            <label class="d-block">Ascendant Sign:</label>
            <Dropdown v-model="newContent.ascendantSignId" :options="signs" optionLabel="name" optionValue="id"
                      placeholder="Select Ascendant Sign"/>
          </div>
        </div>

        <div class="row">
          <div class="col-12 mb-4">
            <label class="d-block">Title:</label>
            <InputText class="w-100" type="text" v-model="newContent.title"/>
          </div>
          <div class="col-12 mb-4">
            <label class="d-block">Story Prefix:</label>
            <InputText class="w-100" type="text" v-model="newContent.storyPrefix"/>
          </div>

          <div class="col-12 mb-4">
            <label class="d-block">Text:</label>
            <InputText class="w-100" type="text" v-model="newContent.text"/>
          </div>
        </div>

        <div class="row">
          <div class="col-12 mb-4">
            <label class="d-block">Photo Url:</label>
            <InputText class="w-100" type="text" v-model="newContent.photoUrl"/>
          </div>
        </div>

        <div class="row">
          <div class="col-6 mb-4">
            <label class="d-block">Start Date:</label>
            <Datepicker v-model="newContent.startDate" format="dd/MM/yyyy"></Datepicker>
          </div>
          <div class="col-6 mb-4">
            <label class="d-block">End Date:</label>
            <Datepicker v-model="newContent.endDate" format="dd/MM/yyyy"></Datepicker>
          </div>
        </div>

        <div class="col-md-6 form-group">
          <label class="d-block">Tags:</label>
          <MultiSelect class="w-100" v-model="newContent.categoryIds" :options="categories" optionLabel="name"
                       optionValue="id" placeholder="Select Tags"/>
        </div>

        <Message v-for="msg of messages" :severity="msg.severity" :key="msg.id" :life="5000" :sticky="false">
          {{ msg.content }}
        </Message>

        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click="showNewContentModal = false">
            Close
          </button>
          <Button label="Save" icon="pi pi-save" iconPos="left" @click="addNewContent()"/>
        </div>
      </Dialog>

      <Dialog header="Edit Language" v-model:visible="showEditLanguageModal" modal :style="{ 'width': '40%' }">
        <div class="col-12">
          <label class="d-block">Verified</label>
          <InputSwitch v-model="editLanguageModel.isVerified"/>
        </div>
        <div class="col-md-12 form-group">
          <label class="d-block">Title:</label>
          <InputText class="w-100" type="text" v-model="editLanguageModel.title"/>
        </div>
        <div class="row mb-3 mt-3">
          <div class="col-md-12 form-group">
            <label class="d-block">Text:</label>
            <Textarea class="w-100" type="text" rows="5" v-model="editLanguageModel.text"/>
          </div>
        </div>
        <div class="row mb-3 mt-3">
          <div class="col-md-12 form-group">
            <label class="d-block">Button Text:</label>
            <InputText type="text" class="w-100" v-model="editLanguageModel.secondaryTitle"/>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-12 form-group">
            <label class="d-block">Button Url:</label>
            <InputText type="text" class="w-100" v-model="editLanguageModel.secondaryText"/>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click="showEditLanguageModal = false">
            Close
          </button>
          <Button label="Save" icon="pi pi-save" iconPos="left" @click="editLanguage()"/>
        </div>
      </Dialog>

      <Dialog header="Edit Content" v-model:visible="showEditContent" modal :style="{ 'width': '40%' }">
        <div class="row mb-3 mt-3">
          <div class="col-md-6 form-group">
            <label class="d-block">General:</label>
            <InputSwitch v-model="editContentModel.isGeneral"/>
          </div>
          <div class="col-md-6 form-group">
            <label class="d-block">Premium:</label>
            <InputSwitch v-model="editContentModel.isPremium"/>
          </div>
          <div v-if="!editContentModel.isGeneral" class="col-md-12 form-group">
            <label class="d-block">Sign:</label>
            <Dropdown v-model="editContentModel.ascendantSignId" :options="signs" optionLabel="name" optionValue="id"
                      placeholder="Select Sign"/>
          </div>
          <div class="col-md-6 form-group">
            <label class="d-block">Start Date:</label>
            <Datepicker v-model="editContentModel.startDate" format="dd/MM/yyyy"></Datepicker>
          </div>
          <div class="col-md-6 form-group">
            <label class="d-block">End Date:</label>
            <Datepicker v-model="editContentModel.endDate" format="dd/MM/yyyy"></Datepicker>
          </div>
          <div class="col-12">
            <label class="d-block">Photo Url</label>
            <InputText class="w-100" type="text" v-model="editContentModel.photoUrl"/>
          </div>
          <div class="col-md-6 form-group">
            <label class="d-block">Tags:</label>
            <MultiSelect class="w-100" v-model="editContentModel.categoryIds" :options="categories" optionLabel="name"
                         optionValue="id" placeholder="Select Tags"/>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click="showEditContent = false">
            Close
          </button>
          <Button label="Save" icon="pi pi-save" iconPos="left" @click="editContent()"/>
        </div>
      </Dialog>

      <Dialog header="New Subcontent" v-model:visible="showNewSubContentModal" modal :style="{ 'width': '40%' }">
        <div class="row">
          <div class="col-12 mb-4">
            <label class="d-block">Photo Url:</label>
            <InputText class="w-100" type="text" v-model="newSubContent.photoUrl"/>
          </div>
        </div>

        <div class="row">
          <div class="col-12 mb-4">
            <label class="d-block">Title</label>
            <InputText class="w-100" type="text" v-model="newSubContent.title"/>
          </div>
        </div>

        <div class="row">
          <div class="col-12 mb-4">
            <label class="d-block">Text</label>
            <Textarea class="w-100" type="text" v-model="newSubContent.text"/>
          </div>
        </div>

        <div class="row">
          <div class="col-12 mb-4">
            <label class="d-block">Order</label>
            <InputNumber class="w-100" type="text" v-model="newSubContent.order"/>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-6">
            <label class="d-block">Show Button</label>
            <InputSwitch v-model="newSubContent.showButton"/>
          </div>
        </div>

        <div class="row mb-4" v-if="newSubContent.showButton">
          <div class="col-12">
            <label class="d-block">Button Text</label>
            <InputText class="w-100" type="text" v-model="newSubContent.buttonText"/>
          </div>
        </div>

        <div class="row mb-4" v-if="newSubContent.showButton">
          <div class="col-12">
            <label class="d-block">Button Color:</label>
            <ColorPicker v-model="newSubContent.buttonColor"/>
          </div>
        </div>

        <div class="row mb-4" v-if="newSubContent.showButton">
          <div class="col-12">
            <label class="d-block">Link:</label>
            <InputText class="w-100" type="text" v-model="newSubContent.link"/>
          </div>
        </div>

        <Message v-for="msg of messages" :severity="msg.severity" :key="msg.id" :life="5000" :sticky="false">
          {{ msg.content }}
        </Message>

        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click="showNewSubContentModal = false">
            Close
          </button>
          <Button label="Save" icon="pi pi-save" iconPos="left" @click="addNewSubContent"/>
        </div>
      </Dialog>

      <Dialog header="Sub Content Edit" v-model:visible="showEditSubContent" modal :style="{ 'width': '40%' }">
        <div class="row mb-3 mt-3">
          <div class="col-md-12 form-group">
            <label class="d-block">Photo Url:</label>
            <InputText class="w-100" type="text" v-model="editSubContentModel.photoUrl"/>
          </div>
          <div class="col-md-12 form-group">
            <label class="d-block">Order:</label>
            <InputNumber buttonLayout="horizontal" decrementButtonClass="p-button-secondary"
                         incrementButtonClass="p-button-secondary" incrementButtonIcon="pi pi-plus"
                         decrementButtonIcon="pi pi-minus" mode="decimal" showButtons
                         v-model="editSubContentModel.order"/>
          </div>
          <div class="col-md-6 form-group">
            <label class="d-block">Type:</label>
            <Dropdown v-model="editSubContentModel.type"
                      :options="[{ id: 1, name: '1 (No Button)' }, { id: 2, name: '2 (Button)' }]" optionLabel="name"
                      optionValue="id" placeholder="Select Type"/>
          </div>
          <div class="col-md-6 form-group" v-if="editSubContentModel.type == 2">
            <label class="d-block">Button Color:</label>
            <ColorPicker v-model="editSubContentModel.color"/>
          </div>
        </div>
        <Message v-for="msg of messages" :severity="msg.severity" :key="msg.id" :life="5000" :sticky="false">
          {{ msg.content }}
        </Message>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click="showNewSubContentModal = false">
            Close
          </button>
          <Button label="Save" icon="pi pi-save" iconPos="left" @click="editSubContent"/>
        </div>
      </Dialog>

    </div>
  </main>
</template>

<script>
import Header from "@/components/Header.vue";
import dayjs from "dayjs";
import "@vuepic/vue-datepicker/dist/main.css";
import InputText from "primevue/inputtext";
import {mapGetters} from "vuex";

export default {
  name: "Stories",
  components: {
    Header,
    InputText,
  },
  data() {
    return {
      selectedContentType: 'story',
      loading: false,
      contentsTotalRecords: 0,
      contents: [],
      subContentsTotalRecords: 0,
      subContents: [],
      filters: {
        global: {value: null},
        name: {value: null},
        house: {value: null},
        gender: {value: null},
        relationshipStatusIds: {value: null},
        score: {value: []},
        occupation: {value: null},
        minAge: {value: null},
        maxAge: {value: null},
      },
      contentsLazyParams: {},
      subContentsLazyParams: {
        first: 0,
        rows: 5,
      },
      showNewContentModal: false,
      showEditLanguageModal: false,
      showEditContent: false,
      newContent: {},
      editContentModel: {},
      messages: [],
      messageCount: 0,
      editLanguageModel: {},
      genders: [{id: 0, name: "All"}, {id: 1, name: "Man"}, {id: 2, name: "Woman"}],
      selectedLanguage: "tr",
      expandedRows: {},
      showNewSubContentModal: false,
      newSubContent: {},
      showEditSubContent: false,
      editSubContentModel: {},
      eventId: null,
    };
  },
  mounted() {
    this.contentsLazyParams = {
      first: 0,
      rows: this.$refs.dt.rows,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };
    this.getContents();
  },
  computed: {
    ...mapGetters(['occupations', 'signs', 'houses', 'relationships', 'occupationIds', 'relationshipIds', 'categories', 'supportedLanguages']),
  },
  methods: {
    getContents() {
      this.expandedRows = {};

      this.loading = true;

      var filters = {};

      for (const [key] of Object.entries(this.contentsLazyParams.filters)) {
        filters[key] = this.contentsLazyParams.filters[key].value;
      }
      this.$appAxios
          .post("admin/content/list/" + this.selectedContentType, {
            first: this.contentsLazyParams.first,
            rows: this.contentsLazyParams.rows,
            sortField: this.contentsLazyParams.sortField,
            sortOrder: this.contentsLazyParams.sortOrder,
            page: this.contentsLazyParams.page,
            filters: JSON.stringify(filters),
            language: this.selectedLanguage,
          })
          .then((resp) => {
            this.contents = resp.data.data;
            this.contentsTotalRecords = resp.data.totalRecords;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
    },
    onContentsExpand(event) {
      this.eventId = event.data.id;
      // this.subContentLazyParams.id = event.data.id;
      this.getSubContents(event.data.id);
    },
    onSubContentPage(event) {
      this.subContentsLazyParams.first = event.first;
      this.subContentsLazyParams.rows = event.rows;
      this.getSubContents();
    },
    getSubContents(id) {
      if (!id) return;

      this.$appAxios
          .post("admin/content/sub-contents/" + id, {
            first: this.subContentsLazyParams.first,
            rows: this.subContentsLazyParams.rows,
          })
          .then((resp) => {
            this.subContents = resp.data.data;
            this.eventId = id;
            this.contents.find(d => d?.id === id).expandedTable = resp.data.data
            this.subContentsTotalRecords = resp.data.totalRecords;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
    },
    onContentsPage(event) {
      this.contentsLazyParams = event;
      this.contentsLazyParams.filters = this.filters;
      this.getContents();
    },
    onContentsSort(event) {
      this.contentsLazyParams = event;
      this.contentsLazyParams.filters = this.filters;
      this.getContents();
    },
    onContentsFilter() {
      this.contentsLazyParams.filters = this.filters;
      this.getContents();
    },
    clearFilter() {
      for (const [key] of Object.entries(this.filters)) {
        this.filters[key].value = null;
      }
      this.getContents();
    },
    formatDate(dateString) {
      if (dateString == null) {
        return "";
      }

      const date = dayjs(dateString);
      return date.format("DD/MM/YYYY HH:mm");
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    addNewContent() {
      if (!this.newContent.isGeneral && this.newContent.ascendantSignId == null) {
        this.messages = [{severity: 'warn', content: 'Ascendant sign cannot be empty', id: this.messageCount++}];
        return;
      }

      if (this.newContent.title == null || this.newContent.text == null) {
        this.messages = [{severity: 'warn', content: 'Titles and image cannot be empty', id: this.messageCount++}];
        return;
      }

      if (this.newContent.startDate == null || this.newContent.endDate == null) {
        this.messages = [{severity: 'warn', content: 'Start and end dates cannot be empty', id: this.messageCount++}];
        return;
      }

      if (this.newContent.endDate < this.newContent.startDate) {
        this.messages = [{severity: 'warn', content: 'End date cannot be before start date', id: this.messageCount++}];
        return;
      }

      this.$appAxios.post("admin/content/" + this.selectedContentType, {
        type: this.selectedContentType,
        ascendantSignId: this.newContent.ascendantSignId,
        startDate: this.newContent.startDate,
        endDate: this.newContent.endDate,
        title: this.newContent.title,
        text: this.newContent.text,
        isGeneral: this.newContent.isGeneral,
        isPremium: this.newContent.isPremium,
        language: this.selectedLanguage,
        photoUrl: this.newContent.photoUrl,
        storyPrefix: this.newContent.storyPrefix,
        categoryIds: this.newContent.categoryIds,
      }).then(() => {
        this.showNewContentModal = false;
        this.$toast.add({severity: 'success', summary: 'Success', detail: 'Content added successfully', life: 3000});
        this.getContents();
      }).catch(() => {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'An error occured while adding content',
          life: 3000
        });
        this.getContents();
      });

      // if(this.newContent.showButton && this.newContent.buttonText == null){
      //   this.messages = [{severity: 'warn', content: 'Button text cannot be empty', id: this.messageCount++}];
      //   return;
      // }
    },
    addNewSubContent() {
      this.newSubContent.contentId = Object.entries(this.expandedRows)[0][0]

      delete this.expandedRows[this.newSubContent.contentId];

      this.$appAxios
          .post("admin/content/sub-content",
              {
                contentId: this.newSubContent.contentId,
                order: this.newSubContent.order,
                title: this.newSubContent.title,
                text: this.newSubContent.text,
                photoUrl: this.newSubContent.photoUrl,
                type: this.newSubContent.showButton ? 2 : 1,
                secondaryText: this.newSubContent.link,
                secondaryTitle: this.newSubContent.buttonText,
                color: this.newSubContent.buttonColor,
              })
          .then(() => {
            this.showNewSubContentModal = false;
            this.$toast.add({severity: 'success', summary: 'Success', detail: 'Sub content added', life: 3000});
            this.getSubContents(this.newSubContent.contentId);
          })
          .catch(() => {
            this.loading = false;
          });
    },
    showEditLanguage(type, id, language) {

      this.$appAxios.get("admin/content/translation/" + id + "/" + language)
          .then(resp => {
            this.editLanguageModel = resp.data;
            this.editLanguageModel.type = type;
            this.showEditLanguageModal = true;
          })
          .catch(err => {
            console.log(err)
          });
    },
    editLanguage() {
      if (!(this.editLanguageModel.text && this.editLanguageModel.language)) {
        this.messages = [{severity: 'warn', content: 'Required fields must be entered.', id: this.messageCount++}];
        return;
      }
      this.$appAxios.put("admin/content/translation",
          {
            id: this.editLanguageModel.id,
            title: this.editLanguageModel.title,
            text: this.editLanguageModel.text,
            language: this.editLanguageModel.language,
            isVerified: this.editLanguageModel.isVerified,
            secondaryTitle: this.editLanguageModel.secondaryTitle,
            secondaryText: this.editLanguageModel.secondaryText,
          }
      ).then(() => {
        this.showEditLanguageModal = false;
        this.$toast.add({severity: 'success', summary: 'Success', detail: 'Language info updated', life: 3000});
        this.getContents();
      })
          .catch(() => {
            this.showEditLanguageModal = false;
            this.$toast.add({severity: 'error', summary: 'Error', detail: 'Language info not updated', life: 3000});
          })
    },
    openEditContent(id) {
      this.$appAxios.get("admin/content/" + id)
          .then(resp => {
            this.editContentModel = resp.data;
            this.showEditContent = true;
          })
          .catch(err => {
            console.log(err)
          });
    },
    editContent() {
      this.$appAxios.put("admin/content",
          {
            id: this.editContentModel.id,
            ascendantSignId: this.editContentModel.ascendantSignId,
            score: this.editContentModel.score,
            relationshipStatusIds: this.editContentModel.relationshipStatusIds,
            occupationIds: this.editContentModel.occupationIds,
            type: this.selectedContentType,
            isGeneral: this.editContentModel.isGeneral,
            isPremium: this.editContentModel.isPremium,
            startDate: this.editContentModel.startDate,
            endDate: this.editContentModel.endDate,
            photoUrl: this.editContentModel.photoUrl,
            categoryIds: this.editContentModel.categoryIds,
          }
      ).then(() => {
        this.showEditContent = false;
        this.$toast.add({severity: 'success', summary: 'Success', detail: 'Content updated', life: 3000});
        this.getContents();
      })
          .catch(() => {
            this.showEditContent = false;
            this.$toast.add({severity: 'error', summary: 'Error', detail: 'Content not updated', life: 3000});
          })
    },
    deleteContent(id) {
      this.$confirm.require({
        message: 'Are you sure you want to delete the content?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$appAxios.delete("admin/content/" + id)
              .then(() => {
                this.$toast.add({severity: 'success', summary: 'Success', detail: 'Content deleted', life: 3000});
                this.getContents();
              })
              .catch(() => {
                this.$toast.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'Content could not be deleted',
                  life: 3000
                });
              })
        },
        reject: () => {
        }
      });
    },
    openSubContentEditLanguage(type, id, language) {
      this.$appAxios.get("admin/content/sub-content/translation/" + id + "/" + language)
          .then(resp => {
            this.editLanguageModel = resp.data;
            this.editLanguageModel.isSubContent = true;
            this.showEditLanguageModal = true;
          })
          .catch(err => {
            console.log(err)
          });
    },
    openEditSubContent(id) {
      this.$appAxios.get("admin/content/sub-content/" + id)
          .then((resp) => {
            this.editSubContentModel = resp.data;
            this.showEditSubContent = true;
          })
          .catch(() => {
          });
    },
    deleteSubContent(id) {
      this.$confirm.require({
        message: 'Are you sure you want to delete the content?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$appAxios.delete("admin/content/sub-content/" + id)
              .then(() => {
                this.$toast.add({severity: 'success', summary: 'Success', detail: 'Content deleted', life: 3000});
                this.getSubContents();
              })
              .catch(() => {
                this.$toast.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'Content could not be deleted',
                  life: 3000
                });
              })
        },
        reject: () => {
        }
      });
    },
    editSubContent() {
      this.$appAxios.put("admin/content/sub-content/" + this.editSubContentModel.id, this.editSubContentModel)
          .then(() => {
            this.showEditSubContent = false;
            this.$toast.add({severity: 'success', summary: 'Success', detail: 'Sub content updated', life: 3000});
            this.getSubContents();
          })
          .catch(() => {
            this.showEditSubContent = false;
            this.$toast.add({severity: 'error', summary: 'Error', detail: 'Sub content not updated', life: 3000});
          })
    },
  },
};
</script>
<style scoped>
.p-button-sm {
  height: 35px;
  width: 35px;
  font-size: 0.875rem;
  padding: 0.7rem;
}

.p-disabled,
.p-component:disabled {
  opacity: 0.8;
}

.p-dropdown {
  min-width: 200px;
}
</style>
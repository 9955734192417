<template>
  <Header/>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-12 m-0 no-max-w home-div">
          <Toast/>

          <DataTable :value="content" :lazy="true" :paginator="true"
                     paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                     :rowsPerPageOptions="[5, 10, 20, 50, 100]" dataKey="id" :rows="10" ref="dt"
                     :totalRecords="totalRecords" :loading="loading" @page="onPage($event)"
                     @sort="onSort($event)" @filter="onFilter($event)" responsiveLayout="scroll"
                     class="p-datatable-striped" v-model:filters="filters" filterDisplay="menu" removableSort
                     :globalFilterFields="['name', 'isActive']"
                     currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
            <template #header>
              <div class="d-flex justify-content-between">
                <div>
                  <Button
                      type="button"
                      icon="pi pi-plus"
                      label="Add Public Figure"
                      class="p-button-info d-inline me-2"
                      @click="showPublicFigureModal = true"
                  />
                </div>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"/>
                    <InputText
                        v-model="filters['global'].value"
                        placeholder="Keyword Search"
                        @change="getContent"
                    />
                    
                  </span>
              </div>
            </template>

            <template #empty> No user found.</template>
            <template #loading> Loading user data. Please wait.</template>
            <Column field="name" header="Name" ref="name">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.name }}</span>
              </template>
            </Column>
            <Column field="surname" header="Surname" ref="surname"/>
            <Column field="email" header="Email" ref="email"/>
            <Column field="gender" header="Gender" ref="gender">
              <template #body="slotProps">
                <span class="m-0">{{ this.genders.find(x => x.id == slotProps.data.gender).name }}</span>
              </template>
            </Column>
            <Column field="birthDate" header="Birth Date" ref="birthDate">
              <template #body="slotProps">
                <span class="m-0">{{ formatDate(slotProps.data.birthDate) }} {{
                    slotProps.data.birthHour
                  }}:{{ slotProps.data.birthMinute }}</span>
              </template>
            </Column>
            <Column field="actions" header="Actions" ref="actions">
              <template #body="slotProps">
                <Button
                    class="p-button-primary p-button-sm me-2 my-2"
                    v-tooltip.bottom="'Edit User'"
                    icon="pi pi-pencil"
                    @click="openEditModal(slotProps.data)"
                />
              </template>
            </Column>
          </DataTable>

          <Dialog header="Edit User" v-model:visible="showEditBirthdateModal" modal :style="{'width':'40%'}">
            <div class="row">
              <div class="col-6">
                <label class="d-block">Name</label>
                <InputText v-model="editUserModel.name"/>
              </div>
              <div class="col-6">
                <label class="d-block">Surname</label>
                <InputText v-model="editUserModel.surname"/>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <label class="d-block">Photo Url</label>
                <InputText v-model="editUserModel.photoUrl"/>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-6">
                <label class="d-block">Active</label>
                <InputSwitch v-model="editUserModel.isActive"/>
              </div>
              <div class="col-6">
                <label class="d-block">Alive</label>
                <InputSwitch v-model="editUserModel.isAlive"/>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-6">
                <label class="d-block">Order</label>
                <InputNumber v-model="editUserModel.order"/>
              </div>
            </div>

            <div class="modal-footer mt-5">
              <button type="button" class="btn btn-default" @click="showEditBirthdateModal = false">
                Close
              </button>
              <Button label="Save" icon="pi pi-save" :loading="saveButtonLoading" iconPos="left" @click="editUser"/>
            </div>
          </Dialog>

          <Dialog header="New Public Figure" v-model:visible="showPublicFigureModal" modal :style="{'width':'40%'}">

            <div class="row">
              <div class="col-6">
                <label class="d-block">Name</label>
                <InputText v-model="newPublicFigureModel.name"/>
              </div>
              <div class="col-6">
                <label class="d-block">Surname</label>
                <InputText v-model="newPublicFigureModel.surname"/>
              </div>
            </div>

            <div class="col-12 mt-4">
              <label class="d-block">Birthdate</label>
              <Calendar
                  v-model="newPublicFigureModel.birthDate"
                  hourFormat="24"
                  dateFormat="dd/mm/yy"
                  class="w-100"
              />
            </div>
            <div class="row mt-4">

              <div class="col-6">
                <label class="d-block">Birth Hour</label>
                <InputNumber v-model="newPublicFigureModel.birthHour"/>
              </div>

              <div class="col-6">
                <label class="d-block">Birth Minute</label>
                <InputNumber v-model="newPublicFigureModel.birthMinute"/>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-6">
                <label class="d-block">Gender</label>
                <Dropdown :options="genders" v-model="newPublicFigureModel.gender" optionLabel="name" optionValue="id"/>
              </div>
              <div class="col-6">
                <label class="d-block">Order</label>
                <InputNumber v-model="newPublicFigureModel.order"/>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-6">
                <label class="d-block">Is Active</label>
                <InputSwitch v-model="newPublicFigureModel.isActive"/>
              </div>
              <div class="col-6">
                <label class="d-block">Is Alive</label>
                <InputSwitch v-model="newPublicFigureModel.isAlive"/>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-6">
                <label class="d-block">Photo Url</label>
                <InputText v-model="newPublicFigureModel.photoUrl"/>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-6">
                <label class="d-block">Occupations</label>
                <Dropdown
                    v-model="newPublicFigureModel.description"
                    :options="occupations"
                    optionLabel="name"
                    optionValue="id"
                />
              </div>

              <div class="col-6">
                <label class="d-block">Relationship</label>
                <Dropdown
                    v-model="newPublicFigureModel.relationship"
                    :options="relationships"
                    optionLabel="name"
                    optionValue="id"
                />
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-6">
                <label class="d-block">Country</label>
                <Dropdown
                    v-model="newPublicFigureModel.birthCountry"
                    :options="countries"
                    optionLabel="name"
                    optionValue="id"
                    filter="true"
                    @change="getCities"
                />
              </div>

              <div class="col-6">
                <label class="d-block">City</label>
                <Dropdown
                    v-model="newPublicFigureModel.birthCity"
                    :options="cities"
                    optionLabel="name"
                    optionValue="id"
                    filter="true"
                />
              </div>
            </div>

            <div class="modal-footer mt-5">
              <button type="button" class="btn btn-default" @click="showPublicFigureModal = false">
                Close
              </button>
              <Button label="Save" icon="pi pi-save" :loading="saveButtonLoading" iconPos="left"
                      @click="addPublicFigure"/>
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Header from "@/components/Header.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import dayjs from "dayjs";
import "@vuepic/vue-datepicker/dist/main.css";
import Toast from 'primevue/toast';
import {mapGetters} from "vuex";

var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export default {
  name: "Users",
  components: {
    Header,
    DataTable,
    Column,
    Toast,
  },
  data() {
    const schema = {
      price: "required|numeric|regex",
    };

    return {
      loading: false,
      totalRecords: 0,
      content: [],
      filters: {
        global: {value: null},
        name: {value: null},
        isPublicFigure: {value: true}
      },
      contentLazyParams: {},
      schema,
      houses: [],
      signs: [],
      genders: [{id: 1, name: "Man"}, {id: 2, name: "Woman"}, {id: 3, name: "Other"}],
      showEditBirthdateModal: false,
      editUserModel: {},
      messages: [],
      messageCount: 0,
      saveButtonLoading: false,
      showPublicFigureModal: false,
      newPublicFigureModel: {},
      countries: [],
      cities: [],
    };
  },
  mounted() {
    this.contentLazyParams = {
      first: 0,
      rows: this.$refs.dt.rows,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };
    this.getContent();

    this.$appAxios.get("global/countries")
        .then(resp => {
          this.countries = resp.data.response
        })
        .catch(err => {
          console.log(err)
        });
  },
  computed: {
    ...mapGetters(['occupations', 'signs', 'houses', 'relationships', 'occupationIds', 'relationshipIds']),
  },
  methods: {
    getContent() {
      this.loading = true;

      var filters = {};

      for (const [key] of Object.entries(this.contentLazyParams.filters)) {
        filters[key] = this.contentLazyParams.filters[key].value;
      }

      this.$appAxios
          .post("admin/user/public-figure/list", {
            first: this.contentLazyParams.first,
            rows: this.contentLazyParams.rows,
            sortField: this.contentLazyParams.sortField,
            sortOrder: this.contentLazyParams.sortOrder,
            page: this.contentLazyParams.page,
            filters: JSON.stringify(filters),
          })
          .then((resp) => {
            this.content = resp.data.data;
            this.totalRecords = resp.data.totalRecords;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
    },
    onPage(event) {
      this.contentLazyParams = event;
      this.contentLazyParams.filters = this.filters;
      this.getContent();
    },
    onSort(event) {
      this.contentLazyParams = event;
      this.contentLazyParams.filters = this.filters;
      this.getContent();
    },
    onFilter() {
      this.contentLazyParams.filters = this.filters;
      this.getContent();
    },
    clearFilter() {
      for (const [key] of Object.entries(this.filters)) {
        this.filters[key].value = null;
      }
      this.getContent();
    },
    formatDate(dateString, format = "DD/MM/YYYY") {
      if (dateString == null) {
        return "";
      }

      const date = dayjs(dateString);
      return date.format(format);
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    getCities(event) {
      var countryId = event.value;
      this.$appAxios.get("global/cities/" + countryId)
          .then(resp => {
            this.cities = resp.data.response;
          })
          .catch(err => {
            console.log(err)
          });
    },
    addPublicFigure() {
      this.saveButtonLoading = true;
      this.$appAxios.post("admin/user/public-figure/add", this.newPublicFigureModel)
          .then(() => {
            this.saveButtonLoading = false;
            this.showPublicFigureModal = false;
            this.$toast.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Public figure added successfully',
              life: 3000
            });
            this.getContent();
          })
          .catch((err) => {
            this.saveButtonLoading = false;
            this.$toast.add({severity: 'error', summary: 'Error', detail: err.message, life: 3000});
          });
    },
    openEditModal(rowData) {
      //copy object
      this.editUserModel = JSON.parse(JSON.stringify(rowData));
      this.showEditBirthdateModal = true;
    },
    editUser() {
      this.saveButtonLoading = true;
      this.$appAxios.post("admin/user/public-figure/edit", this.editUserModel)
          .then(() => {
            this.saveButtonLoading = false;
            this.showEditBirthdateModal = false;
            this.$toast.add({severity: 'success', summary: 'Success', detail: 'User updated successfully', life: 3000});
            this.getContent();
          })
          .catch((err) => {
            this.saveButtonLoading = false;
            this.$toast.add({severity: 'error', summary: 'Error', detail: err.message, life: 3000});
          });
    }
  },
};
</script>
<style scoped>
.p-button-sm {
  height: 35px;
  width: 35px;
  font-size: 0.875rem;
  padding: 0.7rem;
}

.p-disabled,
.p-component:disabled {
  opacity: 0.8;
}

.p-dropdown {
  min-width: 200px;
}
</style>
  
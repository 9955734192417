<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-12 m-0 no-max-w home-div">

          <AstopiaTable ref="astopiaTable" :columns="columns" :url="'/admin/app-settings/language/list'"
                        :default-post-data="'?test=test'"
                        :axiosDataName="'languages'" :sort-field="'id'" :totalDataName="'total_records'"
                        @detail-see="detailSee" @delet-see="deletSee" @add-button="addBlog" :addButton="true"/>
        </div>
      </div>
    </div>
  </main>
  <AddLanguageSettingsModal :show="showNewContentModal"
                            @hide="showNewContentModal = false" @refresh="refresh"/>

  <EditLanguageSettingsModal :show="showEditModal" :selectedLanguageSetting="selectedLanguageSetting"
                             @hide="showEditModal = false" @refresh="refresh"/>
  <DeleteLanguageSettingsModal :show="showDeletModal" :selectedLanguageSetting="selectedLanguageSetting"
                               @hide="showDeletModal = false" @refresh="refresh"/>
</template>

<script>
import AstopiaTable from "../../components/Tables/AstopiaTable.vue";
import AddLanguageSettingsModal from "../Modals/AddLanguageSettingsModal.vue";
import EditLanguageSettingsModal from "../Modals/EditLanguageSettingsModal.vue";
import DeleteLanguageSettingsModal from "../Modals/DeleteLanguageSettingsModal.vue";

export default {
  components: {AddLanguageSettingsModal, EditLanguageSettingsModal, AstopiaTable, DeleteLanguageSettingsModal},
  data() {
    return {
      columns: [
        // {field: "Detail",header:"Detail", functionType: "detail", selected: true},
        {field: "key", header: "Key", functionType: "none", selected: true},
        {field: "deepLKey", header: "DeepL Key", functionType: "none", selected: true},
        {field: "isActive", header: "Is Active", functionType: "bool", selected: true},
        {field: "contentTypes", header: "Content Types", functionType: "scroll", selected: true},
        {field: "Actions", header: "Actions", functionType: "crud", edit: true, delete: true, selected: true},
      ],
      showNewContentModal: false,
      showEditModal: false,
      showDeletModal: false,
      selectedLanguageSetting: null,
      language: null,
    };
  },
  methods: {
    addBlog() {
      this.showNewContentModal = true
    },
    detailSee(report) {
      this.selectedLanguageSetting = report.id

      this.showEditModal = true
    },
    deletSee(report) {
      this.selectedLanguageSetting = report.id

      this.showDeletModal = true
    },
    refresh() {
      this.$router.go(0);
    }
  }
}
</script>
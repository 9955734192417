<template>
  <Header/>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-12 m-0 no-max-w home-div">
          <Toast/>
          <ConfirmDialog/>
          <DataTable :value="contents" :lazy="true" :paginator="true"
                     paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                     :rowsPerPageOptions="[5, 10, 20, 50, 100]" dataKey="id" :rows="10" ref="dt"
                     :totalRecords="contentsTotalRecords" :loading="loading" @page="onContentsPage($event)"
                     @sort="onContentsSort($event)" @filter="onContentsFilter($event)" responsiveLayout="scroll"
                     class="p-datatable-striped" v-model:filters="filters" filterDisplay="menu" removableSort
                     :globalFilterFields="['name', 'isActive']"
                     currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
            <template #header>
              <div class="flex justify-content-between">
                <div class="flex">
                  <ToggleButton
                      v-model="filters.isVerified.value"
                      @change="onContentsFilter"
                      :onLabel="'Verified'"
                      :offLabel="'Unverified'"
                      :onIcon="'pi pi-check'"
                      :offIcon="'pi pi-times'"
                  />
                </div>
              </div>
            </template>

            <template #empty> No unverified contents found.</template>
            <template #loading> Loading contents. Please wait.</template>
            <Column field="title" header="Title" ref="title"/>
            <Column field="text" header="Text" ref="text" style="width: 40vw;">
              <template #body="slotProps">
                <ScrollPanel v-if="slotProps.data.text.length > 100" style="width: 100%; height: 100px">
                  <div>
                    {{ slotProps.data.text }}
                  </div>
                </ScrollPanel>
                <div v-else>
                  {{ slotProps.data.text }}
                </div>

              </template>
            </Column>
            <Column field="type" header="Type"/>
            <Column field="language" header="Language" ref="language"/>
            <Column field="isVerified" header="Verified">
              <template #body="slotProps">
                <span v-if="slotProps.data.isVerified" class="pi pi-check-circle text-success"></span>
                <span v-else class="pi pi-times-circle text-danger"></span>
              </template>
            </Column>
            <Column field="actions" header="Actions" ref="actions">
              <template #body="slotProps">
                <Button
                    icon="pi pi-check"
                    class="p-button-success p-button-sm me-2 my-2"
                    @click="verifyContent(slotProps.data.id)"
                    v-tooltip="'Verify'"
                    v-if="!slotProps.data.isVerified"
                />

                <Button
                    icon="pi pi-pencil"
                    class="p-button-warning p-button-sm me-2 my-2"
                    @click="openEditModal(slotProps.data.id)"
                    v-tooltip="slotProps.data.isVerified ? 'Edit' : 'Edit and verify'"
                />
              </template>
            </Column>
          </DataTable>

          <EditContentModal
              :show="showEditModal"
              v-if="showEditModal"
              :contentTranslationId="editContentTranslationId"
              @hide="showEditModal = false"
          />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Header from "@/components/Header.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Toast from 'primevue/toast';
import ConfirmDialog from 'primevue/confirmdialog';
import EditContentModal from '@/views/Modals/EditContentModal.vue';

export default {
  name: "Workshops",
  components: {
    Header,
    DataTable,
    Column,
    Button,
    Toast,
    ConfirmDialog,
    EditContentModal,
  },
  data() {
    const schema = {
      price: "required|numeric|regex",
    };

    return {
      loading: false,
      contentsTotalRecords: 0,
      contents: [],
      filters: {
        global: {value: null},
        name: {value: null},
        isVerified: {value: false},
      },
      contentsLazyParams: {},
      schema,
      messages: [],
      messageCount: 0,
      showEditModal: false,
      editContentModel: {},
      reRender: false,
      editContentTranslationId: null,
    };
  },
  mounted() {
    this.contentsLazyParams = {
      first: 0,
      rows: this.$refs.dt.rows,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };

    this.getContents();
  },
  computed: {},
  methods: {
    getContents() {
      this.loading = true;

      var filters = {};

      for (const [key] of Object.entries(this.contentsLazyParams.filters)) {
        filters[key] = this.contentsLazyParams.filters[key].value;
      }

      this.$appAxios
          .post("admin/content/translations", {
            first: this.contentsLazyParams.first,
            rows: this.contentsLazyParams.rows,
            sortField: this.contentsLazyParams.sortField,
            sortOrder: this.contentsLazyParams.sortOrder,
            page: this.contentsLazyParams.page,
            filters: JSON.stringify(filters),
          })
          .then((resp) => {
            this.contents = resp.data.data;
            this.contentsTotalRecords = resp.data.totalRecords;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
    },
    onContentsPage(event) {
      this.contentsLazyParams = event;
      this.contentsLazyParams.filters = this.filters;
      this.getContents();
    },
    onContentsSort(event) {
      this.contentsLazyParams = event;
      this.contentsLazyParams.filters = this.filters;
      this.getContents();
    },
    onContentsFilter() {
      this.contentsLazyParams.filters = this.filters;
      this.getContents();
    },
    clearFilter() {
      for (const [key] of Object.entries(this.filters)) {
        this.filters[key].value = null;
      }
      this.getContents();
    },
    verifyContent(contentTranslationId) {
      this.$appAxios
          .post("admin/content/translations/verify/" + contentTranslationId)
          .then(() => {
            this.$toast.add({severity: 'success', summary: 'Success', detail: 'Content verified', life: 3000});
            this.getContents();
          })
          .catch(() => {
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occured while verifying',
              life: 3000
            });
          })
    },
    openEditModal(contentTranslationId) {
      this.editContentTranslationId = contentTranslationId
      this.showEditModal = true;
    },
  },
};
</script>
<style scoped>
.p-button-sm {
  height: 35px;
  width: 35px;
  font-size: 0.875rem;
  padding: 0.7rem;
}

.p-disabled,
.p-component:disabled {
  opacity: 0.8;
}

.p-dropdown {
  min-width: 200px;
}
</style>
  
<template>
  <Header/>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-12 m-0 no-max-w home-div">
          <Toast/>
          <ConfirmDialog/>
          <DataTable :value="contents" :lazy="true" :paginator="true"
                     paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                     :rowsPerPageOptions="[5, 10, 20, 50, 100]" dataKey="id" :rows="10" ref="dt"
                     :totalRecords="contentsTotalRecords" :loading="loading" @page="onContentsPage($event)"
                     @sort="onContentsSort($event)" @filter="onContentsFilter($event)" responsiveLayout="scroll"
                     class="p-datatable-striped" v-model:filters="filters" filterDisplay="menu" removableSort
                     :globalFilterFields="['global', 'name', 'isActive', 'relationshipStatusIds']"
                     currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
            <template #header>
              <div class="d-flex justify-content-between">
                <div>
                  <Button type="button" icon="pi pi-plus" label="Add Content" class="p-button-info d-inline me-2"
                          @click="showNewContentModal = true"/>
                  <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)"/>
                  <Dropdown v-model="selectedContentType" :options="contentTypes" optionLabel="name" optionValue="id"
                            @change="getContents" class="ms-2" placeholder="Select Content Type"/>
                  <Dropdown v-model="selectedLanguage" :options="languages" optionLabel="name" optionValue="id"
                            @change="getContents" class="ms-2" placeholder="Select Content Type"/>
                </div>
                <div>
                  <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined d-inline me-2"
                          @click="clearFilter()"/>
                  <span class="p-input-icon-left">
                    <i class="pi pi-search"/>
                    <InputText v-model="filters['global'].value" placeholder="Keyword Search" @change="getContents"/>
                  </span>
                </div>
              </div>
            </template>

            <template #empty> No daily contents found.</template>
            <template #loading> Loading daily contents data. Please wait.</template>
            <Column field="id" header="Id" ref="id">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.id }}</span>
              </template>
            </Column>
            <Column field="text" header="Text" ref="text">
              <template #body="slotProps">
                <ScrollPanel style="width: 100%; height: 100px" class="custombar">
                  <span class="m-0">{{ slotProps.data.text }}</span>
                </ScrollPanel>
              </template>
            </Column>
            <Column field="type" header="Type"/>
            <Column field="score" header="Score" ref="score">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.score }}</span>
              </template>
              <template #filter="">
                <Slider v-model="filters['score'].value" range max="10" min="0" class="mb-2"/>
                <span class="mt-2">{{ filters['score'].value[0] }} - {{ filters['score'].value[1] }}</span>
              </template>
            </Column>
            <Column field="actions" header="Actions" ref="actions">
              <template #body="slotProps">
                <Button v-text="'TR'" class="p-button-sm me-2 my-2"
                        :class="{ 'p-button-danger': !slotProps.data.isVerified['tr'], 'p-button-success': slotProps.data.isVerified['tr'] }"
                        @click="showEditLanguage('programGroup', slotProps.data.id, 'tr')"/>
                <Button v-text="'EN'" class="p-button-sm me-2 my-2"
                        :class="{ 'p-button-danger': !slotProps.data.isVerified['en'], 'p-button-success': slotProps.data.isVerified['en'] }"
                        @click="showEditLanguage('programGroup', slotProps.data.id, 'en')"/>
                <Button icon="pi pi-pencil" class="p-button-warning p-button-sm me-2 my-2"
                        @click="openEditContent(slotProps.data.id)"/>
                <Button icon="pi pi-trash" class="p-button-danger p-button-sm me-2 my-2"
                        @click="deleteContent(slotProps.data.id)"/>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>

      <Dialog header="New Content" v-model:visible="showNewContentModal" modal :style="{ 'width': '40%' }"
              @hide="hideModal">
        <div class="row mb-3 mt-3">
          <div class="col-md-12 form-group">
            <label class="d-block">Title:</label>
            <InputText class="w-100" type="text" v-model="newContent.title"/>
          </div>
          <div class="col-md-12 form-group">
            <label class="d-block">Text:</label>
            <Textarea class="w-100" type="text" rows="8" v-model="newContent.text"/>
          </div>

          <div class="col-md-6 form-group">
            <label class="d-block">Score:</label>
            <InputNumber class="w-100" buttonLayout="horizontal" mode="decimal" v-model="newContent.score"/>
          </div>
          <div class="row">
            <div class="col-md-6 form-group">
              <label class="d-block">Type:</label>
              <Dropdown v-model="newContent.type" :options="contentTypes" optionLabel="name" optionValue="id"
                        class="ms-2"
                        placeholder="Select Content Type"/>
            </div>

            <div class="col-md-6 form-group">
              <label class="d-block">Language:</label>
              <Dropdown v-model="newContent.language" :options="languages" optionLabel="name" optionValue="id"
                        class="ms-2" placeholder="Select Content Type"/>
            </div>
          </div>
        </div>
        <Message v-for="msg of messages" :severity="msg.severity" :key="msg.id" :life="5000" :sticky="false">
          {{ msg.content }}
        </Message>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click="hideModal">
            Close
          </button>
          <Button label="Save" icon="pi pi-save" iconPos="left" @click="addNewContent"/>
        </div>
      </Dialog>

      <Dialog header="Edit Language" v-model:visible="showEditLanguageModal" modal :style="{ 'width': '40%' }">
        <div class="col-12">
          <label class="d-block">Verified</label>
          <InputSwitch v-model="editLanguageModel.isVerified"/>
        </div>
        <div class="col-md-12 form-group">
          <label class="d-block">Title:</label>
          <InputText class="w-100" type="text" v-model="editLanguageModel.title"/>
        </div>
        <div class="row mb-3 mt-3">
          <div class="col-md-12 form-group">
            <label class="d-block">Text:</label>
            <Textarea class="w-100" type="text" rows="5" v-model="editLanguageModel.text"/>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click="showEditLanguageModal = false">
            Close
          </button>
          <Button label="Save" icon="pi pi-save" iconPos="left" @click="editLanguage()"/>
        </div>
      </Dialog>

      <Dialog header="Edit Content" v-model:visible="showEditContent" modal :style="{ 'width': '40%' }">
        <div class="row mb-3 mt-3">
          <div class="col-md-6 form-group">
            <label class="d-block">General:</label>
            <InputSwitch v-model="editContentModel.isGeneral"/>
          </div>
          <div class="col-md-6 form-group" v-if="editContentModel.isGeneral">
            <label class="d-block">Date:</label>
            <Datepicker v-model="editContentModel.startDate" format="dd/MM/yyyy"></Datepicker>
          </div>
          <div v-if="!editContentModel.isGeneral" class="col-md-6 form-group">
            <label class="d-block">House:</label>
            <Dropdown v-model="editContentModel.houseId" :options="houses" optionLabel="name" optionValue="id"
                      placeholder="Select House"/>
          </div>
          <div class="col-md-12 form-group">
            <label class="d-block">Score:</label>
            <InputNumber class="w-50" mode="decimal" v-model="editContentModel.score"/>
          </div>
          <div class="col-md-6 form-group">
            <label class="d-block">Min Age:</label>
            <InputNumber class="w-100" mode="decimal" v-model="editContentModel.minAge"/>
          </div>
          <div class="col-md-6 form-group">
            <label class="d-block">Max Age:</label>
            <InputNumber class="w-100" mode="decimal" v-model="editContentModel.maxAge"/>
          </div>
          <div class="col-md-6 form-group">
            <label class="d-block">Gender:</label>
            <Dropdown class="w-100" :options="genders" v-model="editContentModel.gender" optionLabel="name"
                      optionValue="id"/>
          </div>
          <div class="col-md-6 form-group">
            <label class="d-block">Relationship:</label>
            <MultiSelect class="w-100" :options="relationships" v-model="editContentModel.relationshipStatusIds"
                         optionLabel="name" optionValue="id"/>
          </div>
          <div class="col-md-12 form-group">
            <label class="d-block">Occupations:</label>
            <MultiSelect class="w-100" v-model="editContentModel.occupationIds" :options="occupations"
                         optionLabel="name"
                         optionValue="id" placeholder="Select Occupations"/>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click="showEditContent = false">
            Close
          </button>
          <Button label="Save" icon="pi pi-save" iconPos="left" @click="editContent()"/>
        </div>
      </Dialog>
    </div>
  </main>
</template>

<script>
import Header from "@/components/Header.vue";
import dayjs from "dayjs";
import "@vuepic/vue-datepicker/dist/main.css";
import InputText from "primevue/inputtext";
import {mapGetters} from "vuex";

export default {
  name: "Daily Contents",
  components: {Header, InputText},
  data() {
    return {
      selectedContentType: 'biorhythm-emotional',
      contentTypes: [
        {"id": "biorhythm-emotional", "name": "Biorhythm Emotional"},
        {"id": "biorhythm-emotional-suggestion", "name": "Biorhythm Emotional Suggestion"},
        {"id": "biorhythm-intellectual", "name": "Biorhythm Intellectual"},
        {"id": "biorhythm-intellectual-suggestion", "name": "Biorhythm Intellectual Suggestion"},
        {"id": "biorhythm-physical", "name": "Biorhythm Physical"},
        {"id": "biorhythm-physical-suggestion", "name": "Biorhythm Physical Suggestion"}
      ],
      selectedLanguage: "tr",
      languages: [
        {"id": "tr", "name": "Turkish"},
        {"id": "en", "name": "English"},
      ],
      loading: false,
      contentsTotalRecords: 0,
      contents: [],
      filters: {
        global: {value: null},
        name: {value: null},
        house: {value: null},
        gender: {value: null},
        relationshipStatusIds: {value: null},
        score: {value: []},
        occupation: {value: null},
        minAge: {value: null},
        maxAge: {value: null},
      },
      contentsLazyParams: {},
      showNewContentModal: false,
      showEditLanguageModal: false,
      showEditContent: false,
      newContent: {
        title: "",
        text: "",
        score: 1,
        houseId: null,
        ascendantSignId: null,
        gender: 0,
        minAge: 0,
        maxAge: 100,
        relationshipStatus: 0,
      },
      editContentModel: {},
      messages: [],
      messageCount: 0,
      editLanguageModel: {},
      genders: [{id: 0, name: "All"}, {id: 1, name: "Man"}, {id: 2, name: "Woman"}],
    };
  },
  mounted() {
    this.contentsLazyParams = {
      first: 0,
      rows: this.$refs.dt.rows,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };
    this.getContents();
  },
  computed: {
    scores: () => {
      let scores = [];
      for (let i = -100; i <= 90; i += 10) {
        scores.push(i);
      }
      return scores;
    },
    ...mapGetters(['occupations', 'signs', 'houses', 'relationships', 'occupationIds', 'relationshipIds']),
  },
  watch: {},
  methods: {
    getContents() {
      this.loading = true;

      var filters = {};

      for (const [key] of Object.entries(this.contentsLazyParams.filters)) {
        filters[key] = this.contentsLazyParams.filters[key].value;
      }
      this.$appAxios
          .post("admin/content/list/" + this.selectedContentType, {
            first: this.contentsLazyParams.first,
            rows: this.contentsLazyParams.rows,
            sortField: this.contentsLazyParams.sortField,
            sortOrder: this.contentsLazyParams.sortOrder,
            page: this.contentsLazyParams.page,
            filters: JSON.stringify(filters),
            language: this.selectedLanguage,
          })
          .then((resp) => {
            this.contents = resp.data.data;
            this.contentsTotalRecords = resp.data.totalRecords;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
    },
    onContentsPage(event) {
      this.contentsLazyParams = event;
      this.contentsLazyParams.filters = this.filters;
      this.getContents();
    },
    onContentsSort(event) {
      this.contentsLazyParams = event;
      this.contentsLazyParams.filters = this.filters;
      this.getContents();
    },
    onContentsFilter() {
      this.contentsLazyParams.filters = this.filters;
      this.getContents();
    },
    clearFilter() {
      for (const [key] of Object.entries(this.filters)) {
        this.filters[key].value = null;
      }
      this.getContents();
    },
    formatDate(dateString) {
      if (dateString == null) {
        return "";
      }

      const date = dayjs(dateString);
      return date.format("DD/MM/YYYY HH:mm");
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    showEditLanguage(type, id, language) {

      this.$appAxios.get("admin/content/translation/" + id + "/" + language)
          .then(resp => {
            this.editLanguageModel = resp.data;
            this.editLanguageModel.type = type;
            this.showEditLanguageModal = true;
          })
          .catch(err => {
            console.log(err)
          });
    },
    editLanguage() {
      if (!(this.editLanguageModel.text && this.editLanguageModel.language)) {
        this.messages = [{severity: 'warn', content: 'Required fields must be entered.', id: this.messageCount++}];
        return;
      }
      this.$appAxios.put("admin/content/translation",
          {
            id: this.editLanguageModel.id,
            title: this.editLanguageModel.title,
            text: this.editLanguageModel.text,
            language: this.editLanguageModel.language,
            isVerified: this.editLanguageModel.isVerified,
          }
      ).then(() => {
        this.showEditLanguageModal = false;
        this.$toast.add({severity: 'success', summary: 'Success', detail: 'Language info updated', life: 3000});
        this.getContents();
      })
          .catch(() => {
            this.showEditLanguageModal = false;
            this.$toast.add({severity: 'error', summary: 'Error', detail: 'Language info not updated', life: 3000});
          })
    },
    openEditContent(id) {
      this.$appAxios.get("admin/content/" + id)
          .then(resp => {
            this.editContentModel = resp.data;
            this.showEditContent = true;
          })
          .catch(err => {
            console.log(err)
          });
    },
    editContent() {
      this.$appAxios.put("admin/content",
          {
            id: this.editContentModel.id,
            ascendantSignId: this.editContentModel.ascendantSignId,
            houseId: this.editContentModel.houseId,
            gender: this.editContentModel.gender,
            minAge: this.editContentModel.minAge,
            maxAge: this.editContentModel.maxAge,
            score: this.editContentModel.score,
            relationshipStatusIds: this.editContentModel.relationshipStatusIds,
            occupationIds: this.editContentModel.occupationIds,
            type: this.selectedContentType,
            isGeneral: this.editContentModel.isGeneral,
            startDate: this.editContentModel.startDate,
            endDate: this.editContentModel.startDate,
          }
      ).then(() => {
        this.showEditContent = false;
        this.$toast.add({severity: 'success', summary: 'Success', detail: 'Content updated', life: 3000});
        this.getContents();
      })
          .catch(() => {
            this.showEditContent = false;
            this.$toast.add({severity: 'error', summary: 'Error', detail: 'Content not updated', life: 3000});
          })
    },
    deleteContent(id) {
      this.$confirm.require({
        message: 'Are you sure you want to delete the content?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$appAxios.delete("admin/content/" + id)
              .then(() => {
                this.$toast.add({severity: 'success', summary: 'Success', detail: 'Content deleted', life: 3000});
                this.getContents();
              })
              .catch(() => {
                this.$toast.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'Content could not be deleted',
                  life: 3000
                });
              })
        },
        reject: () => {
        }
      });
    },
    addNewContent() {
      if (!this.newContent.text) {
        this.messages = [{severity: 'warn', content: 'Required fields must be entered.', id: this.messageCount++}];
        return;
      }
      this.$appAxios.post("admin/content/" + this.newContent.type, {
        type: this.newContent.type,
        score: this.newContent.score,
        title: this.newContent.title,
        text: this.newContent.text,
        language: this.newContent.language,
      }).then(() => {
        this.hideModal();
        this.$toast.add({severity: 'success', summary: 'Success', detail: 'Content added successfully', life: 3000});
        this.$emit('refresh');
      }).catch(() => {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'An error occured while adding content',
          life: 3000
        });
        this.$emit('refresh');
      });
    },
  },
};
</script>
<style scoped>
.p-button-sm {
  height: 35px;
  width: 35px;
  font-size: 0.875rem;
  padding: 0.7rem;
}

.p-disabled,
.p-component:disabled {
  opacity: 0.8;
}

.p-dropdown {
  min-width: 200px;
}
</style>
  
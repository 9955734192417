<template>
  <Dialog
      header="New Content"
      v-model:visible="showModal"
      modal
      :style="{'width':'40%'}"
      @hide="hideModal"
  >
    <div class="row mb-3 mt-3">
      <div class="row d-flex justify-content-between">
        <div class="col-md-8 form-group">
          <label class="d-block">Content Translation:</label>
          <MultiSelect class="w-100" :options="contentTranslations" v-model="contentTranslation"
                       optionLabel="" optionValue="" placeholder="Select Content Translation" filter/>
        </div>
        <div class="col-md-3 form-group d-flex flex-column">
          <label class="d-block">isActive:</label>
          <InputSwitch
              v-model="isActiveType"
          />
        </div>
        <div class="col-md-1 form-group d-flex flex-column align-items-center">
          <label class="d-block">Add:</label>
          <span @click="addContentType"
                style="background: rgb(160, 160, 160);padding: 8px;border-radius: 50%;color: white;width: 30px;height: 30px;display: flex;align-items: center;justify-content: center;font-size: 25px;">+</span>
        </div>
      </div>
      <div class="col-md-12 pb-2 d-flex flex-column gap-4"
           style="max-height: 200px;overflow-y: auto;border-bottom: 1px solid rgb(206, 206, 206);border-top: 1px solid rgb(206, 206, 206);padding-top: 0.5rem;background: #d1d5db29;">
        <div class="d-flex flex-column">
          <div v-for="type in contentType" :key="type" class="d-flex gap-3 align-items-center">
            {{ type.Key }}
            <InputSwitch v-model="type.IsActive"/>
            <span @click="deleteContentType(type.Key)"
                  style="background: rgb(160, 160, 160);padding: 8px;border-radius: 50%;color: white;width: 30px;height: 30px;display: flex;align-items: center;justify-content: center;font-size: 25px;">-</span>

          </div>
        </div>
      </div>
      <div class="row pt-2" v-if="!newContent.isGeneral">
        <div class="col-md-6 form-group">
          <label class="d-block">Languages Key:</label>
          <InputText
              class="w-100"
              type="text"
              v-model="languageObj.key"
          />
        </div>
        <div class="col-md-6 form-group">
          <label class="d-block">DeepL Key:</label>
          <InputText
              class="w-100"
              type="text"
              v-model="languageObj.deepLKey"
          />
        </div>
      </div>
      <div class="col-md-6 form-group">
        <label class="d-block">isActive:</label>
        <InputSwitch
            v-model="languageObj.isActive"
        />
      </div>
    </div>
    <Message v-for="msg of messages" :severity="msg.severity" :key="msg.id" :life="5000" :sticky="false">
      {{ msg.content }}
    </Message>
    <div class="modal-footer">
      <button
          type="button"
          class="btn btn-default"
          @click="hideModal"
      >
        Close
      </button>
      <Button
          label="Save"
          icon="pi pi-save"
          iconPos="left"
          @click="updateLanguageSettings"
      />
    </div>
  </Dialog>
</template>
<script>
import {mapGetters} from 'vuex';

export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    selectedLanguageSetting: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      newContent: {},
      messages: [],
      messageCount: 0,
      selectedContentType: [],
      contentType: [],
      contentTypeObj: {},
      languageObj: {},
      languageKey: null,
      deepLKey: null,
      isActive: false,
      isActiveType: false,
      selectedAddContentTypes: false,
      contentTranslation: null,
      contentTranslations: [
        "do",
        "flow",
        "daily",
        "don't",
        "story",
        "weekly",
        "yearly",
        "cosmic-event",
        "weekly-discover",
        "monthly",
        "weekly-suggestion",
        "birth-chart",
        "calendar-event",
        "celestial-card",
        "biorhythm-physical",
        "daily-suggestion",
        "calendar-void-moon",
        "weekly-affirmation",
        "biorhythm-emotional",
        "family-compatibility",
        "friend-compatibility",
        "biorhythm-intellectual",
        "conflict-compatibility",
        "romantic-compatibility",
        "weekly-suggestion-card",
        "emotional-compatibility",
        "family-life-compatibility",
        "friend-life-compatibility",
        "family-trust-compatibility",
        "friend-trust-compatibility",
        "communication-compatibility",
        "family-values-compatibility",
        "friend-values-compatibility",
        "public-figure-compatibility",
        "romantic-life-compatibility",
        "romantic-love-compatibility",
        "romantic-trust-compatibility",
        "biorhythm-physical-suggestion",
        "family-conflict-compatibility",
        "friend-conflict-compatibility",
        "romantic-values-compatibility",
        "biorhythm-emotional-suggestion",
        "family-emotional-compatibility",
        "friend-emotional-compatibility",
        "romantic-conflict-compatibility",
        "romantic-emotional-compatibility",
        "biorhythm-intellectual-suggestion",
        "public-figure-trust-compatability",
        "family-communication-compatibility",
        "friend-communication-compatibility",
        "public-figure-values-compatability",
        "public-figure-general-compatability",
        "romantic-communication-compatibility",
        "public-figure-communication-compatability",
        "PlanetAspectContents",
        "QuestionSubjects",
        "AstrologTranslations",
        "BlogCategories",
        "CategoryTranslations",
        "CompositeAspects",
        "GeneralTranslations",
        "Occupations",
        "Onboardings",
        "QuestionSubjectCategories",
        "QuestionSubjectTypes",
      ]
    }
  },
  watch: {
    showModal(newVal) {
      if (newVal && this.selectedLanguageSetting) {
        this.fetchLanguageSettings();
      }
    }
  },
  computed: {
    ...mapGetters(['occupations', 'signs', 'houses', 'relationships', 'occupationIds', 'relationshipIds', 'categories']),
    showModal: {
      get() {
        return this.show;
      },
      set() {
        this.hideModal();
      }
    },
  },
  methods: {
    hideModal() {
      this.$emit('hide');
    },
    addContentType() {
      if (Array.isArray(this.contentTranslation) && this.contentTranslation.length > 0) {
        this.contentTranslation.forEach(translation => {
          const isKeyExists = this.contentType.some(type => type.Key === translation);

          if (!isKeyExists) {
            this.contentTypeObj = {
              Key: translation,
              IsActive: this.isActiveType
            };
            this.selectedAddContentTypes = true;
            this.contentType.push(this.contentTypeObj);
          } else {
            console.log(`Key "${translation}" zaten mevcut.`);
          }
        });
      }

      this.languageObj.contentTypes = this.contentType;
    },
    deleteContentType(val) {
      if (!this.selectedAddContentTypes) {
        const contentType = JSON.parse(this.languageObj.contentTypes)
        const contentType2 = contentType.filter(d => d.Key !== val)
        this.languageObj.contentTypes = contentType2
        this.contentType = this.languageObj.contentTypes
      } else {
        const contentType = this.languageObj.contentTypes
        const contentType2 = contentType.filter(d => d.Key !== val)
        this.languageObj.contentTypes = contentType2
        this.contentType = this.languageObj.contentTypes
      }
    },
    deleteContentTypes() {
      this.languageObj.contentTypes = null
      this.contentType = []
    },
    addWorkspaces() {
      this.selectedWorkspaces = true
      this.selectedWorkspace = this.value
      const id = this.value.id
      this.values.push(id)
      this.value = null
    },
    fetchLanguageSettings() {
      this.$appAxios.get(`/admin/app-settings/language/${this.selectedLanguageSetting}`)
          .then(res => {
            this.languageObj = res.data.language.result;
            this.contentType = JSON.parse(this.languageObj.contentTypes)
          })
          .catch(err => {
            console.error("An error occurred:", err);
          });
    },
    updateLanguageSettings() {
      if (this.contentTypeObj === {}) {
        this.selectedContentType = JSON.parse(this.languageObj.contentTypes)
      } else {
        this.selectedContentType = this.contentType
      }


      this.$appAxios.put(`/app-settings/language/update/${this.languageObj.id}`, {
        key: this.languageObj.key,
        deepLKey: this.languageObj.deepLKey,
        contentType: this.selectedContentType,
        isActive: this.languageObj.isActive,

      }).then(() => {
        this.hideModal();
        this.$toast.add({severity: 'success', summary: 'Success', detail: 'Content added successfully', life: 3000});
        this.$emit('refresh');
      }).catch(() => {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'An error occured while adding content',
          life: 3000
        });
        this.$emit('refresh');
      });
    },
  }
}
</script>
<style>
</style>
<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-12 m-0 no-max-w home-div">
          <Toast/>
          <ConfirmDialog/>
          <DataTable :value="contents" :lazy="true" :paginator="true"
                     paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                     :rowsPerPageOptions="[5, 10, 20, 50, 100]" dataKey="id" :rows="10" ref="dt"
                     :totalRecords="contentsTotalRecords" :loading="loading" @page="onContentsPage($event)"
                     @sort="onContentsSort($event)" @filter="onContentsFilter($event)" responsiveLayout="scroll"
                     class="p-datatable-striped" v-model:filters="filters" filterDisplay="menu" removableSort
                     :globalFilterFields="['name', 'isActive']"
                     currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
            <template #header>
              <div class="d-flex justify-content-between">
                <div>
                  <Dropdown v-model="selectedContentType" :options="contentTypes" optionLabel="name" optionValue="name"
                            placeholder="Select Content Type" @change="onContentsFilter" class="me-2"/>
                  <Dropdown v-model="selectedLanguage" :options="supportedLanguages" optionLabel="name" optionValue="id"
                            placeholder="Select Language" @change="onContentsFilter" class="me-2"/>
                  <Button type="button" icon="pi pi-plus" label="Add Content" class="p-button-info d-inline me-2"
                          @click="showNewContent()"/>
                  <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)"/>
                </div>
                <span class="p-input-icon-left">
                  <i class="pi pi-search"/>
                  <InputText v-model="filters['global'].value" placeholder="Keyword Search" @change="getContents"/>
                </span>
              </div>
            </template>

            <template #empty> No aspect contents found.</template>
            <template #loading> Loading aspect contents data. Please wait.</template>
            <Column field="id" header="Id" ref="id">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.id }}</span>
              </template>
            </Column>
            <Column field="title" header="Title" ref="title">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.title }}</span>
              </template>
            </Column>
            <Column field="text" header="Text" ref="text">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.text }}</span>
              </template>
            </Column>
            <Column field="gender" header="Gender" ref="gender">
              <template #body="slotProps">
                <span class="m-0">{{ this.genders.find(x => x.id == slotProps.data.gender)?.name }}</span>
              </template>
            </Column>
            <Column field="contentType" header="Content Type" ref="contentType">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.contentType }}</span>
              </template>
            </Column>
            <Column field="planet" header="Aspecting" ref="planet">
              <template #body="slotProps">
                <span class="m-0">{{
                    slotProps.data.aspectingPlanetId != null ? this.planets.find(x => x.id ==
                        slotProps.data.aspectingPlanetId).name : ''
                  }} </span>
              </template>
            </Column>
            <Column field="planet2" header="Aspected" ref="planet2">
              <template #body="slotProps">
                <span class="m-0">{{
                    slotProps.data.aspectedPlanetId != null ? this.planets.find(x => x.id ==
                        slotProps.data.aspectedPlanetId).name : ''
                  }} </span>
              </template>
            </Column>

            <Column field="aspect" header="Aspect" ref="aspect">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.type }} </span>
              </template>
            </Column>
            <Column field="actions" header="Actions" ref="actions">
              <template #body="slotProps">
                <Button v-text="'TR'" class="p-button-sm me-2 my-2"
                        @click="showEditLanguage(slotProps.data.id, 'tr')"/>
                <Button v-text="'EN'" class="p-button-sm me-2 my-2"
                        @click="showEditLanguage(slotProps.data.id, 'en')"/>
                <Button v-text="'ES'" class="p-button-sm me-2 my-2"
                        @click="showEditLanguage(slotProps.data.id, 'es')"/>
                <Button icon="pi pi-pencil" class="p-button-warning p-button-sm me-2 my-2"
                        @click="openEditContent(slotProps.data.id)"/>
                <Button icon="pi pi-trash" class="p-button-danger p-button-sm me-2 my-2"
                        @click="deleteContent(slotProps.data.id)"/>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>

      <Dialog header="New Aspect Content" v-model:visible="showNewContentModal" modal :style="{ 'width': '40%' }">
        <Form>
          <div class="row mb-3 mt-3">
            <div class="col-md-12 form-group">
              <label class="d-block">Title:</label>
              <InputText class="w-100" type="text" v-model="newContent.title"/>
            </div>
            <div class="col-md-12 form-group">
              <label class="d-block">Text:</label>
              <Textarea class="w-100" type="text" rows="8" v-model="newContent.text"/>
            </div>
            <div class="col-md-6 form-group">
              <label class="d-block">Gender:</label>
              <Dropdown v-model="newContent.gender" :options="genders" optionLabel="name" optionValue="id"
                        placeholder="Select Gender"/>
            </div>
            <div class="col-md-6 form-group">
              <label class="d-block">Aspecting:</label>
              <Dropdown v-model="newContent.planet" :options="planets" optionLabel="name" optionValue="id"
                        placeholder="Select Planet"/>
            </div>
            <div class="col-md-6 form-group">
              <label class="d-block">Aspected:</label>
              <Dropdown v-model="newContent.planet2" :options="planets" optionLabel="name" optionValue="id"
                        placeholder="Select Planet"/>
            </div>
            <div class="col-md-6 form-group">
              <label class="d-block">Aspect:</label>
              <Dropdown v-model="newContent.aspect" :options="aspects" optionLabel="name" optionValue="id"
                        placeholder="Select Planet"/>
            </div>
          </div>
          <Message v-for="msg of messages" :severity="msg.severity" :key="msg.id" :life="5000" :sticky="false">
            {{ msg.content }}
          </Message>
          <div class="modal-footer">
            <Button label="Save" icon="pi pi-save" iconPos="left" @click="addNewContent()"/>
          </div>
        </Form>
      </Dialog>

      <Dialog header="Edit Language" v-model:visible="showEditLanguageModal" modal :style="{ 'width': '40%' }">
        <Form>
          <div class="col-12">
            <label class="d-block">Verified</label>
            <InputSwitch v-model="editLanguageModel.isVerified"/>
          </div>
          <div class="col-md-12 form-group">
            <label class="d-block">Title:</label>
            <InputText class="w-100" type="text" v-model="editLanguageModel.title"/>
          </div>
          <div class="row mb-3 mt-3">
            <div class="col-md-12 form-group">
              <label class="d-block">Text:</label>
              <Textarea class="w-100" type="text" rows="5" v-model="editLanguageModel.text"/>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="showEditLanguageModal = false">
              Close
            </button>
            <Button label="Save" icon="pi pi-save" iconPos="left" @click="editLanguage()"/>
          </div>
        </Form>
      </Dialog>

      <Dialog header="Edit Content" v-model:visible="showEditContent" modal :style="{ 'width': '40%' }">
        <Form>
          <div class="row mb-3 mt-3">
            <div class="col-md-6 form-group">
              <label class="d-block">Aspecting:</label>
              <Dropdown v-model="editContentModel.aspendingPlanetId" :options="planets" optionLabel="name"
                        optionValue="id" placeholder="Select Planet"/>
            </div>
            <div class="col-md-6 form-group">
              <label class="d-block">Aspected:</label>
              <Dropdown v-model="editContentModel.aspectedPlanetId" :options="planets" optionLabel="name"
                        optionValue="id"
                        placeholder="Select Planet"/>
            </div>
            <div class="col-md-6 form-group">
              <label class="d-block">Aspect:</label>
              <Dropdown v-model="editContentModel.type" :options="aspects" optionLabel="name" optionValue="id"
                        placeholder="Select Planet"/>
            </div>
            <div class="col-md-6 form-group">
              <label class="d-block">Gender:</label>
              <Dropdown v-model="editContentModel.gender" :options="genders" optionLabel="name" optionValue="id"
                        placeholder="Select Gender"/>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="showEditContent = false">
              Close
            </button>
            <Button label="Save" icon="pi pi-save" iconPos="left" @click="editContent()"/>
          </div>
        </Form>
      </Dialog>

    </div>
  </main>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import dayjs from "dayjs";
import "@vuepic/vue-datepicker/dist/main.css";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import Dropdown from "primevue/dropdown";
import Dialog from "primevue/dialog";
import Toast from 'primevue/toast';
import Message from 'primevue/message';
import {mapGetters} from "vuex";

export default {
  name: "Workshops",
  components: {
    DataTable,
    Column,
    InputText,
    Textarea,
    Button,
    Dropdown,
    Dialog,
    Toast,
    Message
  },
  data() {
    const schema = {
      price: "required|numeric|regex",
    };

    return {
      loading: false,
      contentsTotalRecords: 0,
      contents: [],
      filters: {
        global: {value: null},
        name: {value: null},
      },
      contentsLazyParams: {},
      schema,
      aspects: [
        {id: "Opposition", name: "Opposition"},
        {id: "Sextile", name: "Sextile"},
        {id: "Trine", name: "Trine"},
        {id: "Square", name: "Square"},
        {id: "Conjunction", name: "Conjunction"},
      ],
      planets: [],
      showNewContentModal: false,
      showEditLanguageModal: false,
      showEditContent: false,
      newContent: {
        title: "",
        text: "",
        houseId: null,
        signId: null,
      },
      editContentModel: {},
      editLanguageModel: {},
      genders: [{id: 0, name: "All"}, {id: 1, name: "Man"}, {id: 2, name: "Woman"}],
      contentTypes: [],
      selectedContentType: null,
    };
  },
  mounted() {
    this.contentsLazyParams = {
      first: 0,
      rows: this.$refs.dt.rows,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };

    this.$appAxios.get("admin/aspectContent/content-types")
        .then(resp => {
          this.contentTypes = resp.data;
        }).catch(err => {
      console.log(err);
    });

    this.$appAxios.get("admin/global/planets")
        .then(resp => {

          this.planets = resp.data;

          this.getContents();

        }).catch(err => {
      console.log(err);
    })

  },
  computed: {
    ...mapGetters(['occupations', 'signs', 'houses', 'relationships', 'occupationIds', 'relationshipIds', 'categories', 'supportedLanguages']),
  },
  methods: {
    getContents() {
      this.loading = true;

      var filters = {};

      for (const [key] of Object.entries(this.contentsLazyParams.filters)) {
        filters[key] = this.contentsLazyParams.filters[key].value;
      }

      this.$appAxios
          .post("admin/aspectContent/list", {
            first: this.contentsLazyParams.first,
            rows: this.contentsLazyParams.rows,
            sortField: this.contentsLazyParams.sortField,
            sortOrder: this.contentsLazyParams.sortOrder,
            page: this.contentsLazyParams.page,
            filters: JSON.stringify(filters),
            contentType: this.selectedContentType,
            language: this.selectedLanguage,
          })
          .then((resp) => {
            this.contents = resp.data.data;
            this.contentsTotalRecords = resp.data.totalRecords;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
    },
    onContentsPage(event) {
      this.contentsLazyParams = event;
      this.contentsLazyParams.filters = this.filters;
      this.getContents();
    },
    onContentsSort(event) {
      this.contentsLazyParams = event;
      this.contentsLazyParams.filters = this.filters;
      this.getContents();
    },
    onContentsFilter() {
      this.contentsLazyParams.filters = this.filters;
      this.getContents();
    },
    clearFilter() {
      for (const [key] of Object.entries(this.filters)) {
        this.filters[key].value = null;
      }
      this.selectedContentType = "";
      this.getContents();
    },
    formatDate(dateString) {
      if (dateString == null) {
        return "";
      }

      const date = dayjs(dateString);
      return date.format("DD/MM/YYYY HH:mm");
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    showNewContent() {
      this.showNewContentModal = true;
    },
    showEditLanguage(id, language) {

      this.$appAxios.get("admin/aspectContent/planet-aspect-content/translation/" + id + "/" + language)
          .then(resp => {
            this.editLanguageModel = resp.data;
            this.showEditLanguageModal = true;
          })
          .catch(err => {
            console.log(err)
          });
    },
    editLanguage() {
      if (!(this.editLanguageModel.text && this.editLanguageModel.title && this.editLanguageModel.language)) {
        this.messages = [{severity: 'warn', content: 'Required fields must be entered.', id: this.messageCount++}];
        return;
      }
      this.$appAxios.put("admin/aspectContent/translation",
          {
            id: this.editLanguageModel.id,
            title: this.editLanguageModel.title,
            text: this.editLanguageModel.text,
            language: this.editLanguageModel.language,
            isVerified: this.editLanguageModel.isVerified
          }
      ).then(() => {
        this.showEditLanguageModal = false;
        this.$toast.add({severity: 'success', summary: 'Success', detail: 'Language info updated', life: 3000});
        this.getContents()
      })
          .catch(() => {
            this.showEditLanguageModal = false;
            this.$toast.add({severity: 'error', summary: 'Error', detail: 'Language info not updated', life: 3000});
          })
    },
    addNewContent() {
      if (!(this.newContent.title && this.newContent.text)) {
        this.messages = [{severity: 'warn', content: 'Required fields must be entered.', id: this.messageCount++}];
        return;
      }
      this.$appAxios.post("admin/aspectContent/planet-aspect-content", {
        aspendingPlanetId: this.newContent.planet,
        aspectedPlanetId: this.newContent.planet2,
        type: this.newContent.aspect,
        title: this.newContent.title,
        text: this.newContent.text,
        gender: this.newContent.gender
      }).then(() => {
        this.showNewContentModal = false;
        this.$toast.add({severity: 'success', summary: 'Success', detail: 'Content added successfully', life: 3000});
        this.getContents();
      }).catch(() => {
        this.showNewContentModal = false;
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'An error occured while adding content',
          life: 3000
        });
      });
    },
    openEditContent(id) {
      this.$appAxios.get("admin/aspectContent/" + id)
          .then(resp => {
            this.editContentModel = resp.data;
            this.showEditContent = true;
          })
          .catch(err => {
            console.log(err)
          });
    },
    editContent() {
      this.$appAxios.put("admin/aspectContent/planet-aspect-content", {
        id: this.editContentModel.id,
        aspendingPlanetId: this.editContentModel.aspendingPlanetId,
        aspectedPlanetId: this.editContentModel.aspectedPlanetId,
        type: this.editContentModel.type,
        gender: this.editContentModel.gender
      })
          .then(() => {
            this.showEditContent = false;
            this.$toast.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Content added successfully',
              life: 3000
            });
            this.getContents();
          })
          .catch(() => {
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occured while adding content',
              life: 3000
            });
          });
    },
    deleteContent(id) {

      this.$confirm.require({
        message: 'Are you sure you want to delete this content?',
        header: 'Delete Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$appAxios.delete("admin/aspectContent/" + id)
              .then(() => {
                this.$toast.add({
                  severity: 'success',
                  summary: 'Success',
                  detail: 'Content deleted successfully',
                  life: 3000
                });
                this.getContents();
              })
              .catch(() => {
                this.$toast.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'An error occured while deleting content',
                  life: 3000
                });
              });
        },
        reject: () => {
          this.$toast.add({severity: 'info', summary: 'Info', detail: 'Content not deleted', life: 3000});
        }
      });
    },
  },
};
</script>
<style scoped>
.p-button-sm {
  height: 35px;
  width: 35px;
  font-size: 0.875rem;
  padding: 0.7rem;
}

.p-disabled,
.p-component:disabled {
  opacity: 0.8;
}

.p-dropdown {
  min-width: 200px;
}
</style>
  
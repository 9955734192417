<template>
  <Header/>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-12 m-0 no-max-w home-div">
          <Toast/>
          <ConfirmDialog/>
          <DataTable :value="contents" :lazy="true" :paginator="true"
                     paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                     :rowsPerPageOptions="[5, 10, 20, 50, 100]" dataKey="id" :rows="10" ref="dt"
                     :totalRecords="contentsTotalRecords" :loading="loading" @page="onContentsPage($event)"
                     @sort="onContentsSort($event)" @filter="onContentsFilter($event)" responsiveLayout="scroll"
                     class="p-datatable-striped" v-model:filters="filters" filterDisplay="menu" removableSort
                     :globalFilterFields="['name', 'isActive']"
                     currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">

            <template #header>
              <div class="d-flex justify-content-between">
                <div>
                  <Button type="button" icon="pi pi-plus" label="Add Content" class="p-button-info d-inline me-2"
                          @click="showNewContent()"/>
                  <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)"/>
                </div>
                <div>
                  <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined d-inline me-2"
                          @click="clearFilter"/>
                  <span class="p-input-icon-left">
                    <i class="pi pi-search"/>
                    <InputText v-model="filters['global'].value" placeholder="Keyword Search" @change="getContents"/>
                  </span>
                </div>
              </div>
            </template>

            <template #empty> No weekly contents found.</template>
            <template #loading> Loading weekly contents data. Please wait.</template>
            <Column field="id" header="Id" ref="id">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.id }}</span>
              </template>
            </Column>
            <Column field="title" header="Title" ref="title">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.title }}</span>
              </template>
            </Column>
            <Column field="text" header="Text" ref="text">
              <template #body="slotProps">
                <ScrollPanel style="width: 400px; height: 100px" class="custombar">
                  <span class="m-0">{{ slotProps.data.text }}</span>
                </ScrollPanel>
              </template>
            </Column>
            <Column field="type" header="Type"/>
            <Column field="ascendant" header="Ascendant" ref="ascendant" :show-filter-match-modes="false">
              <template #body="slotProps">
                <span class="m-0">{{ this.signs.find(x => x.id == slotProps.data.ascendantSignId)?.name }} </span>
              </template>
              <template #filter="">
                <MultiSelect v-model="filters['ascendant'].value" :options="signs" optionLabel="name" optionValue="id"
                             class="p-column-filter"/>
              </template>
            </Column>
            <Column field="gender" header="Gender" ref="gender">
              <template #body="slotProps">
                <span class="m-0">{{ this.genders.find(x => x.id == slotProps.data.gender)?.name }}</span>
              </template>
              <template #filter="">
                <MultiSelect v-model="filters['gender'].value" :options=genders optionLabel="name" optionValue="id"
                             class="p-column-filter"/>
              </template>
            </Column>
            <Column field="relationshipStatusIds" ref="relationshipStatusIds" header="RelationshipStatus">
              <template #body="slotProps">
                <div
                    v-if="this.relationshipIds.every(x => slotProps.data.relationshipStatusIds.includes(x)) || slotProps.data.relationshipStatusIds.length === 0">
                  <span class="badge bg-primary rounded-pill">All</span>
                </div>
                <div v-else>
                  <ScrollPanel style="width: 100%; height: 100px" class="custombar">
                    <div v-for="relationshipId in slotProps.data.relationshipStatusIds" :key="relationshipId">
                      <span class="mt-1">{{ this.relationships?.find(x => x.id == relationshipId)?.name }}</span>
                    </div>
                  </ScrollPanel>
                </div>
              </template>
              <template #filter="">
                <MultiSelect v-model="filters['relationshipStatusIds'].value" :options=relationships optionLabel="name"
                             optionValue="id" class="p-column-filter" :maxSelectedLabels="3"/>
              </template>
            </Column>
            <Column field="occupation" header="Occupations" ref="slug">
              <template #body="slotProps">
                <div
                    v-if="this.occupationIds.every(x => slotProps.data.occupationIds.includes(x)) || slotProps.data.occupationIds.length === 0">
                  <span class="badge bg-primary rounded-pill">All</span>
                </div>
                <div v-else>
                  <ScrollPanel style="width: 100%; height: 100px" class="custombar">
                    <div v-for="occupationId in slotProps.data.occupationIds" :key="occupationId">
                      <span class="mt-1">{{ this.occupations?.find(x => x.id == occupationId)?.name }}</span>
                    </div>
                  </ScrollPanel>
                </div>
              </template>
              <template #filter="">
                <MultiSelect v-model="filters['occupation'].value" :options=occupations optionLabel="name"
                             optionValue="id" class="p-column-filter" :maxSelectedLabels="3"/>
              </template>
            </Column>
            <Column field="minAge" header="Min Age" ref="minAge">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.minAge }}</span>
              </template>
              <template #filter="">
                <InputNumber v-model="filters['minAge'].value"/>
              </template>
            </Column>
            <Column field="maxAge" header="Max Age" ref="maxAge">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.maxAge }}</span>
              </template>
              <template #filter="">
                <InputNumber v-model="filters['maxAge'].value"/>
              </template>
            </Column>
            <Column field="startDate" header="Start Date" ref="startDate">
              <template #body="slotProps">
                <span class="m-0">{{ formatDate(slotProps.data.startDate) }}</span>
              </template>
            </Column>
            <Column field="endDate" header="End Date" ref="endDate">
              <template #body="slotProps">
                <span class="m-0">{{ formatDate(slotProps.data.endDate) }}</span>
              </template>
            </Column>
            <Column field="actions" header="Actions" ref="actions">
              <template #body="slotProps">
                <Button
                    icon="pi pi-book"
                    class="p-button p-button-sm me-2 my-2"
                    @click="showEditLanguage(slotProps.data.id)"
                />
                <Button icon="pi pi-pencil" class="p-button-warning p-button-sm me-2 my-2"
                        @click="openEditContent(slotProps.data.id)"/>
                <Button icon="pi pi-trash" class="p-button-danger p-button-sm me-2 my-2"
                        @click="deleteContent(slotProps.data.id)"/>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>

      <AddWeeklyContentModal
          :show="showNewContentModal"
          @hide="showNewContentModal = false"
          @refresh="getContents"
          :content-type="'weekly'"
      />

      <TranslationsModal
          :show="showEditLanguageModal"
          @hide="showEditLanguageModal = false"
          :contentId="editTranslationContentId"
      />

      <Dialog header="Edit Content" v-model:visible="showEditContent" modal :style="{ 'width': '40%' }">
        <div class="row mb-3 mt-3">
          <div class="col-md-6 form-group">
            <label class="d-block">Date:</label>
            <Calendar v-model="editContentModel.date" :disabled-days="[0, 2, 3, 4, 5, 6]" selection-mode="range"
                      format="dd/MM/yyyy" @date-select="onDateSelectEdit($event)"/>
          </div>
          <div class="form-group col-6">
            <label class="d-block">General:</label>
            <InputSwitch v-model="editContentModel.isGeneral"/>
          </div>
          <div class="form-group col-6">
            <label class="d-block">Premium:</label>
            <InputSwitch v-model="editContentModel.isPremium"/>
          </div>
          <div v-if="!editContentModel.isGeneral" class="col-md-6 form-group">
            <label class="d-block">Ascendant Sign:</label>
            <Dropdown v-model="editContentModel.ascendantSignId" :options="signs" optionLabel="name" optionValue="id"
                      placeholder="Select Sign"/>
          </div>
          <div class="col-md-6 form-group">
            <label class="d-block">Min Age:</label>
            <InputNumber buttonLayout="horizontal" decrementButtonClass="p-button-secondary"
                         incrementButtonClass="p-button-secondary" incrementButtonIcon="pi pi-plus"
                         decrementButtonIcon="pi pi-minus"
                         mode="decimal" showButtons v-model="editContentModel.minAge"/>
          </div>
          <div class="col-md-6 form-group">
            <label class="d-block">Max Age:</label>
            <InputNumber buttonLayout="horizontal" decrementButtonClass="p-button-secondary"
                         incrementButtonClass="p-button-secondary" incrementButtonIcon="pi pi-plus"
                         decrementButtonIcon="pi pi-minus"
                         mode="decimal" showButtons v-model="editContentModel.maxAge"/>
          </div>
          <div class="col-md-6 form-group">
            <label class="d-block">Gender:</label>
            <Dropdown
                :options="[{ 'id': 0, 'value': 'All' }, { 'id': 1, 'value': 'Man' }, { 'id': 2, 'value': 'Woman' }]"
                v-model="editContentModel.gender" optionLabel="value" optionValue="id"/>
          </div>
          <div class="col-md-6 form-group">
            <label class="d-block">Relationship:</label>
            <MultiSelect :options="[{ 'id': 0, 'value': 'All' }, { 'id': 1, 'value': 'Single' },
            { 'id': 2, 'value': 'Married' }, { 'id': 3, 'value': 'Divorced' },
            { 'id': 4, 'value': 'Engaged' }, { 'id': 5, 'value': 'In Relationship' },
            { 'id': 6, 'value': 'Complicated' },
            { 'id': 7, 'value': 'Widowed' },
            { 'id': 8, 'value': 'Open Relationship' }]"
                         v-model="editContentModel.relationshipStatusIds" optionLabel="value" optionValue="id"/>
          </div>
          <div class="col-md-12 form-group">
            <label class="d-block">Occupations:</label>
            <MultiSelect v-model="editContentModel.occupationIds" :options="occupations" optionLabel="name"
                         optionValue="id" placeholder="Select Occupations"/>
          </div>
          <div class="col-md-6 form-group">
            <label class="d-block">Tags:</label>
            <MultiSelect v-model="editContentModel.categoryIds" :options="categories" optionLabel="name"
                         optionValue="id"
                         placeholder="Select House"/>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click="showEditContent = false">
            Close
          </button>
          <Button label="Save" icon="pi pi-save" iconPos="left" @click="editContent()"/>
        </div>
      </Dialog>

    </div>
  </main>
</template>

<script>
import Header from "@/components/Header.vue";
import dayjs from "dayjs";
import AddWeeklyContentModal from "../Modals/AddWeeklyContentModal.vue";
import {mapGetters} from "vuex";
import TranslationsModal from "../Modals/TranslationsModal.vue";

var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export default {
  name: "WeeklyContent",
  components: {
    Header,
    AddWeeklyContentModal,
    TranslationsModal,
  },
  data() {
    return {
      loading: false,
      contentsTotalRecords: 0,
      contents: [],
      filters: {
        global: {value: null},
        name: {value: null},
        house: {value: null},
        gender: {value: null},
        relationshipStatusIds: {value: null},
        score: {value: []},
        occupation: {value: null},
        minAge: {value: null},
        maxAge: {value: null},
        ascendant: {value: null},
      },
      contentsLazyParams: {},
      genders: [{id: 0, name: "All"}, {id: 1, name: "Man"}, {id: 2, name: "Woman"}],
      showNewContentModal: false,
      showEditLanguageModal: false,
      showEditContent: false,
      newContent: {
        title: "",
        text: "",
        score: 1,
        houseId: null,
        ascendantSignId: null,
        gender: 0,
        minAge: 0,
        maxAge: 100,
        relationshipStatus: 0,
      },
      editContentModel: {},
      messages: [],
      messageCount: 0,
      editLanguageModel: {},
      generateAudioLoading: false,
      editTranslationContentId: 0,
    };
  },
  mounted() {
    this.contentsLazyParams = {
      first: 0,
      rows: this.$refs.dt.rows,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };
    this.getContents();
  },
  computed: {
    ...mapGetters(['occupations', 'signs', 'houses', 'relationships', 'occupationIds', 'relationshipIds', 'categories']),
  },
  methods: {
    getContents() {
      this.loading = true;

      var filters = {};

      for (const [key] of Object.entries(this.contentsLazyParams.filters)) {
        filters[key] = this.contentsLazyParams.filters[key].value;
      }

      this.$appAxios.post("admin/content/list/weekly", {
        first: this.contentsLazyParams.first,
        rows: this.contentsLazyParams.rows,
        sortField: this.contentsLazyParams.sortField,
        sortOrder: this.contentsLazyParams.sortOrder,
        page: this.contentsLazyParams.page,
        filters: JSON.stringify(filters),
      })
          .then((resp) => {
            this.contents = resp.data.data;
            this.contentsTotalRecords = resp.data.totalRecords;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
    },
    onContentsPage(event) {
      this.contentsLazyParams = event;
      this.contentsLazyParams.filters = this.filters;
      this.getContents();
    },
    onContentsSort(event) {
      this.contentsLazyParams = event;
      this.contentsLazyParams.filters = this.filters;
      this.getContents();
    },
    onContentsFilter() {
      this.contentsLazyParams.filters = this.filters;
      this.getContents();
    },
    clearFilter() {
      for (const [key] of Object.entries(this.filters)) {
        this.filters[key].value = null;
      }
      this.getContents();
    },
    formatDate(dateString) {
      if (dateString == null) {
        return "";
      }

      const date = dayjs(dateString);
      return date.format("DD/MM/YYYY");
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    showNewContent() {
      this.newContent = {}
      this.showNewContentModal = true;
    },
    showEditLanguage(id) {
      this.editTranslationContentId = id;
      this.showEditLanguageModal = true;
    },
    openEditContent(id) {
      this.$appAxios.get("admin/content/" + id)
          .then(resp => {
            this.editContentModel = resp.data;
            this.editContentModel.date = [dayjs(resp.data.startDate).toDate(), dayjs(resp.data.endDate).toDate()];
            this.showEditContent = true;
          })
          .catch(err => {
            console.log(err)
          });
    },
    editContent() {
      var startDate = this.editContentModel.date[0];
      var startDateUtc = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0, 0)).toISOString();

      var endDate = this.editContentModel.date[1];
      var endDateUtc = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 0, 0, 0, 0)).toISOString();

      this.$appAxios.put("admin/content",
          {
            id: this.editContentModel.id,
            ascendantSignId: this.editContentModel.ascendantSignId,
            houseId: this.editContentModel.houseId,
            gender: this.editContentModel.gender,
            minAge: this.editContentModel.minAge,
            maxAge: this.editContentModel.maxAge,
            score: this.editContentModel.score,
            relationshipStatusIds: this.editContentModel.relationshipStatusIds,
            occupationIds: this.editContentModel.occupationIds,
            startDate: startDateUtc,
            endDate: endDateUtc,
            type: "weekly",
            isGeneral: this.editContentModel.isGeneral,
            isPremium: this.editContentModel.isPremium,
            categoryIds: this.editContentModel.categoryIds
          }
      ).then(() => {
        this.showEditContent = false;
        this.$toast.add({severity: 'success', summary: 'Success', detail: 'Content updated', life: 3000});
        this.getContents();
      })
          .catch(() => {
            this.showEditContent = false;
            this.$toast.add({severity: 'error', summary: 'Error', detail: 'Content not updated', life: 3000});
          })
    },
    deleteContent(id) {
      this.$confirm.require({
        message: 'Are you sure you want to delete the content?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$appAxios.delete("admin/content/" + id)
              .then(() => {
                this.$toast.add({severity: 'success', summary: 'Success', detail: 'Content deleted', life: 3000});
                this.getContents();
              })
              .catch(() => {
                this.$toast.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'Content could not be deleted',
                  life: 3000
                });
              })
        },
        reject: () => {
        }
      });
    },
    onDateSelect(event) {
      var date = dayjs(event).add(7, 'day').toDate();
      this.newContent.date[1] = date;
    },
    onDateSelectEdit(event) {
      var date = dayjs(event).add(7, 'day').toDate();
      this.editContentModel.date[1] = date;
    },
  },
};
</script>
<style scoped>
.p-button-sm {
  height: 35px;
  width: 35px;
  font-size: 0.875rem;
  padding: 0.7rem;
}

.p-disabled,
.p-component:disabled {
  opacity: 0.8;
}

.p-dropdown {
  min-width: 200px;
}
</style>

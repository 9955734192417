<template>
  <Header/>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-12 m-0 no-max-w home-div">
          <Toast/>
          <div class="card card-custom gutter-b w-100">
            <div class="card-header d-flex justify-content-between">
              <div>
                <Button
                    label="New"
                    icon="pi pi-plus"
                    iconPos="left"
                    @click="openNewModal()"
                />
                <Dropdown
                    :options="[{id: 'tr', name: 'Turkish'}, {id: 'en', name: 'English'}, {id: 'es', name: 'Spanish'}]"
                    v-model="calendarSelectedLanguage"
                    optionLabel="name"
                    optionValue="id"
                    class="ms-4"
                    placeholder="Select a Language"
                    @change="getLookups"
                />
              </div>
              <FileUpload
                  mode="basic"
                  accept=".xlsx"
                  :file-limit="1"
                  :customUpload="true"
                  @uploader="uploader"
                  :maxFileSize="5000000"
                  class="ms-auto"
                  name="file[]"
                  choose-label="Choose Event File"
                  upload-label="Upload Event File"
                  cancel-label="Cancel"
              />
            </div>
            <div class="card-body">
              <div class="calendar-wrapper position-relative" :class="{ 'loading': isCalendarLoading }">
                <FullCalendar
                    :options="calendarOptions"
                    @datesRender="handleWeekChange"
                    ref="calendar"
                    :events="events"
                />
              </div>
              <div v-if="isCalendarLoading" class="position-absolute top-50 start-50 translate-middle">
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem;"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Dialog header="Edit" v-model:visible="showEditModal" modal :style="{ width: '35vw' }">
      <!-- Her zaman görünen Start Date ve End Date -->
      <div class="row mb-3 mt-3">
        <div class="col-md-12 form-group">
          <label class="d-block">Start Date:</label>
          <Datepicker v-model="editLookupObject.startDate" :format="'dd/MM/yyyy HH:mm'"></Datepicker>
        </div>
      </div>
      <div class="row mb-3 mt-3">
        <div class="col-md-12 form-group">
          <label class="d-block">End Date:</label>
          <Datepicker v-model="editLookupObject.endDate" :format="'dd/MM/yyyy HH:mm'"></Datepicker>
        </div>
      </div>

      <!-- Diğer Event'ler için görünüm -->
      <div class="row mb-3 mt-3" v-if="!editLookupObject.isVoidMoon && editLookupObject.eventType !== 'eclipse-moon' && editLookupObject.eventType !== 'void-moon'">
        <div class="row mb-3 mt-3">
          <div class="col-6">
            <label class="d-block"> Is General </label>
            <InputSwitch v-model="editLookupObject.isGeneral"/>
          </div>
          <div class="col-6" v-if="!editLookupObject.isGeneral">
            <label class="d-block">Ascendant Sign</label>
            <Dropdown
                v-model="editLookupObject.ascendantSignId"
                :options="signs"
                optionLabel="name"
                optionValue="id"
                class="w-100"
            />
          </div>
        </div>

        <div class="row mb-3 mt-3">
          <div class="col-md-6">
            <label class="d-block"> Premium </label>
            <InputSwitch v-model="editLookupObject.isPremium"/>
          </div>
          <div class="col-md-6">
            <label class="d-block mb-3"> Positiveness </label>
            <Dropdown
                v-model="editLookupObject.score"
                :options="[{id: 5, name: 'Positive'}, {id: -5, name: 'Negative'}]"
                optionLabel="name"
                optionValue="id"
                class="w-100"
            />
          </div>
        </div>

        <div class="row mb-3 mt-3">
          <div class="col-md-12">
            <label class="mt-3">Title</label>
            <InputText
                v-model="editLookupObject.title"
                class="w-100"
            />
            <label class="mt-3">Text</label>
            <Textarea
                v-model="editLookupObject.text"
                class="w-100"
            />
          </div>
        </div>

        <div class="row mb-3 mt-3">
          <div class="col-md-12 form-group">
            <label class="d-block">Category:</label>
            <MultiSelect
                v-model="editLookupObject.categoryIds"
                :options="contentCategories"
                optionLabel="name"
                optionValue="id"
                class="w-100"
            />
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <Button label="Cancel" class="p-button-outlined" @click="showEditModal = false"/>
        <Button label="Delete" icon="pi pi-trash" iconPos="left" class="p-button-danger" @click="deleteLookup()"/>
        <Button label="Save" icon="pi pi-save" iconPos="left" @click="lookupEdit()"/>
      </div>
    </Dialog>

    <Dialog header="New Event" v-model:visible="showNewModal" modal :style="{ width: '30vw' }">
      <div class="row mb-3 mt-3">
        <div class="col-md-12 form-group">
          <label class="d-block">Start Date:</label>
          <Datepicker v-model="newLookupObject.startDate" :format="'dd/MM/yyyy HH:mm'"></Datepicker>
        </div>
      </div>
      <div class="row mb-3 mt-3">
        <div class="col-md-12 form-group">
          <label class="d-block">End Date:</label>
          <Datepicker v-model="newLookupObject.endDate" :format="'dd/MM/yyyy HH:mm'"></Datepicker>
        </div>
      </div>

      <div class="row mb-3 mt-3">
        <div class="col-md-6 form-group">
          <label class="d-block">Void Moon</label>
          <InputSwitch v-model="newLookupObject.isVoidMoon"/>
        </div>
        <div class="col-md-6 form-group">
          <!-- Eclipse Moon InputSwitch -->
            <label class="d-block">Eclipse Moon</label>
            <InputSwitch v-model="newLookupObject.isEclipseMoon" @change="onSwitchChange" />
        </div>
      </div>

      <div
          class="row mb-3 mt-3"
          v-if="!newLookupObject.isVoidMoon && !newLookupObject.isEclipseMoon"
      >

        <div class="row mb-3 mt-3">
          <div class="col-6">
            <label class="d-block"> Is General </label>
            <InputSwitch v-model="newLookupObject.isGeneral" :disabled="newLookupObject.isVoidMoon || newLookupObject.isEclipseMoon" />
          </div>
          <div class="col-6" v-if="!newLookupObject.isGeneral">
            <label class="d-block">Ascendant Sign</label>
            <Dropdown
                v-model="newLookupObject.ascendantSign"
                :options="signs"
                optionLabel="name"
                optionValue="id"
                class="w-100"
            />
          </div>
        </div>

        <div class="row mb-3 mt-3">
          <div class="col-md-6">
            <label class="d-block"> Premium </label>
            <InputSwitch v-model="newLookupObject.isPremium"/>
          </div>
          <div class="col-md-6">
            <label class="d-block mb-3"> Positiveness </label>
            <div class="d-block mb-2">
              <RadioButton v-model="newLookupObject.score" inputId="5" name="Positive" value="5"/>
              <label class="ms-2">Positive</label>
            </div>
            <div class="d-block mb-2">
              <RadioButton v-model="newLookupObject.score" inputId="-5" name="Negative" value="-5"/>
              <label class="ms-2">Negative</label>
            </div>
          </div>
        </div>

        <div class="row mb-3 mt-3">
          <div class="col-md-12 form-group">
            <label class="d-block">Title:</label>
            <InputText v-model="newLookupObject.title"
                       class="w-100"
            />
          </div>
        </div>

        <div class="row mb-3 mt-3">
          <div class="col-md-12 form-group">
            <label class="d-block">Text:</label>
            <Textarea v-model="newLookupObject.text"
                      class="w-100"
                      rows="5"
            />
          </div>
        </div>

        <div class="row mb-3 mt-3">
          <div class="col-md-12 form-group">
            <label class="d-block">Category:</label>
            <MultiSelect
                v-model="newLookupObject.categoryIds"
                :options="contentCategories"
                optionLabel="name"
                optionValue="id"
                class="w-100"
            />
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <Button label="Cancel" class="p-button-outlined" @click="showNewModal = false"/>
        <Button label="Add" icon="pi pi-save" iconPos="left" @click="lookupAdd()"/>
      </div>
    </Dialog>
  </main>
</template>

<script>
import Header from "@/components/Header.vue";
import dayjs from "dayjs";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Dialog from "primevue/dialog";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import Button from "primevue/button";
import Toast from 'primevue/toast';

var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export default {
  name: "Calendar",
  components: {
    Header,
    FullCalendar,
    Dialog,
    Datepicker,
    Button,
    Toast,
  },
  data() {
    return {
      tasks: [],
      events: [],
      calendarOptions: {
        themeSystem: "bootstrap5",
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridWeek, dayGridMonth",
        },
        dayHeaderFormat: {day: "numeric", weekday: "short"},
        initialView: "dayGridWeek",
        titleFormat: {month: "long", day: "numeric"},
        firstDay: 1,
        nowIndicator: true,
        editable: false,
        selectable: false,
        selectMirror: false,
        dayMaxEvents: true,
        weekends: true,
        contentHeight: 600,
        aspectRatio: 0.3,
        height: 650,
        eventClick: this.handleEventClick,
        datesSet: this.handleWeekChange,
        // select: this.handleDateSelect,
        showNonCurrentDates: false,
        initialEvents: [],
        allDaySlot: true,
        timeZone: "UTC",
      },
      prevEvents: [],
      calendarArgs: null,
      editLookupObject: {},
      showEditModal: false,
      signs: [],
      newLookupObject: {},
      showNewModal: false,
      isCalendarLoading: true,
      calendarSelectedLanguage: "tr",
      // contentCategories: {
      //   "en" : [{id : 0, name : "Beauty"}, {id : 1, name : "Health"}, {id: 2, name : "Love"}, {id: 3, name: "Money"}, {id: 4, name: "Career/Business"}, {id: 5, name: "Sex"}, {id: 6, name: "Moon"}, {id: 7, name: "Relationship"}],
      //   "tr" : [{id : 0, name : "Güzellik"}, {id : 1, name : "Sağlık"}, {id: 2, name : "Aşk"}, {id: 3, name: "Para"}, {id: 4, name: "Kariyer/İş"}, {id: 5, name: "Cinsellik"}, {id: 6, name: "Ay"}, {id: 7, name: "İlişkiler"}]
      // },
      contentCategories: [],
    };
  },
  mounted() {
    this.$appAxios
        .get("admin/global/signs")
        .then((resp) => {
          this.signs = resp.data;
        });
    this.$appAxios
        .get("admin/global/houses")
        .then((resp) => {
          this.houses = resp.data;
        });
    this.$appAxios
        .get("admin/calendar/categories/tr")
        .then((resp) => {
          this.contentCategories = resp.data;
        });
  },
  computed: {},
  methods: {
    formatDate(date) {
      return dayjs(date).format("DD/MM/YYYY");
    },
    handleEventClick(clickInfo) {
      var id = clickInfo.event.id;
      var eventType = clickInfo.event.extendedProps.type;
      var endpoint = (eventType == "void-moon") ? "admin/calendar/void-moon/"
          : (eventType == "eclipse-moon") ? "admin/calendar/eclipse-moon/"
              : "admin/calendar/";

      this.$appAxios
          .get(endpoint + id)
          .then((resp) => {
            this.editLookupObject = resp.data;
            this.editLookupObject.eventType = eventType;
            this.showEditModal = true;
          });
    },
    handleWeekChange(arg) {

      if (arg) {
        this.calendarArgs = arg;
      }

      this.getLookups();
    },
    getLookups() {
      this.calendarArgs.view.calendar.removeAllEvents();
      this.isCalendarLoading = true;

      this.$appAxios.post("admin/calendar/list/" + this.calendarSelectedLanguage, {
        start: this.calendarArgs.start,
        end: this.calendarArgs.end,
      })
          .then((resp) => {

            // Normal etkinlikleri ekleme
            for (let i = 0; i < resp.data.data.length; i++) {
              let event = resp.data.data[i];

              this.calendarArgs.view.calendar.addEvent({
                id: event.id,
                title: event.title || event.id,
                start: event.startDate,
                end: event.endDate,
                type: "calendar-event"
              });
            }

            // Void Moon kayıtlarını ekleme
            for (let i = 0; i < resp.data.voidMoonRecords.length; i++) {
              let event = resp.data.voidMoonRecords[i];

              this.calendarArgs.view.calendar.addEvent({
                id: event.id,
                title: "Void Moon",
                start: event.startDate,
                end: event.endDate,
                backgroundColor: "#f64e60", // Kırmızı renkle gösteriliyor
                type: "void-moon"
              });
            }

            // Eclipse Moon kayıtlarını ekleme
            for (let i = 0; i < resp.data.eclipseMoonRecords.length; i++) {
              let event = resp.data.eclipseMoonRecords[i];

              this.calendarArgs.view.calendar.addEvent({
                id: event.id,
                title: "Eclipse Moon",
                start: event.startDate,
                end: event.endDate,
                backgroundColor: "#1e90ff", // Mavi renkle gösteriliyor
                type: "eclipse-moon"
              });
            }

            this.isCalendarLoading = false;
          })
          .catch(() => {
            this.isCalendarLoading = false;
          });
    },
    lookupEdit() {
      // Başlangıç ve Bitiş tarihi kontrolleri
      if (!this.editLookupObject.startDate || !this.editLookupObject.endDate) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Please select start and end dates',
          life: 3000
        });
        return;
      }

      if (this.editLookupObject.startDate > this.editLookupObject.endDate) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Start date cannot be greater than end date',
          life: 3000
        });
        return;
      }

      // Tarihleri UTC formatına dönüştürme
      this.editLookupObject.startDate = this.toUTC(this.editLookupObject.startDate);
      this.editLookupObject.endDate = this.toUTC(this.editLookupObject.endDate);

      // API endpointine göre PUT isteği
      let endpoint = this.editLookupObject.eventType === 'void-moon'
          ? `admin/calendar/void-moon/${this.editLookupObject.id}`
          : this.editLookupObject.eventType === 'eclipse-moon'
              ? `admin/calendar/eclipse-moon/${this.editLookupObject.id}`
              : "admin/calendar";

      // API çağrısı
      this.$appAxios.put(endpoint, this.editLookupObject)
          .then(() => {
            this.showEditModal = false;
            this.getLookups(); // Verileri güncelleme
            this.$toast.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Lookup Info Edited',
              life: 3000
            });
          })
          .catch((err) => {
            this.showEditModal = false;
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: err.response.data.error,
              life: 3000
            });
          });
    },
    deleteLookup() {
      this.$appAxios
          .delete(
              "admin/calendar/" +
              this.editLookupObject.id
          )
          .then(() => {
            this.showEditModal = false;
            this.getLookups();
            this.$toast.add({severity: 'success', summary: 'Success', detail: 'Lookup Deleted!', life: 3000});
          }).catch((err) => {
        this.showNewModal = false;
        this.$toast.add({severity: 'error', summary: 'Error', detail: err.response.data.error, life: 3000});

      });
    },
    lookupAdd() {
      // Tarihlerin geçerli olup olmadığını kontrol et
      if (!this.newLookupObject.startDate || !this.newLookupObject.endDate) {
        this.$toast.add({severity: 'error', summary: 'Error', detail: 'Please select start and end dates', life: 3000});
        return;
      }

      if (this.newLookupObject.startDate > this.newLookupObject.endDate) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Start date cannot be greater than end date',
          life: 3000
        });
        return;
      }

      // Tarihleri UTC formatına çevir
      this.newLookupObject.startDate = this.toUTC(this.newLookupObject.startDate);
      this.newLookupObject.endDate = this.toUTC(this.newLookupObject.endDate);

      // Void Moon ve Eclipse Moon seçildiğinde General seçimini devre dışı bırakma
      if (this.newLookupObject.isVoidMoon || this.newLookupObject.isEclipseMoon) {
        this.newLookupObject.isGeneral = false;
        this.newLookupObject.ascendantSign = null;  // General moddaysa Ascendant Sign null olacak
      }

      // Eğer kategoriler boşsa hata mesajı ver
      if (!this.newLookupObject.isVoidMoon && !this.newLookupObject.isEclipseMoon && this.newLookupObject.categoryIds.length == 0) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Please select at least one category',
          life: 3000
        });
        return;
      }

      // Endpoint belirleme - Seçilen checkbox'a göre
      let endpoint = "admin/calendar";  // Varsayılan endpoint
      if (this.newLookupObject.isVoidMoon) {
        endpoint = "admin/calendar/void-moon";  // Void Moon seçiliyse
      }
      if (this.newLookupObject.isEclipseMoon) {
        endpoint = "admin/calendar/eclipse-moon";  // Eclipse Moon seçiliyse
      }

      // API isteği gönderme
      this.$appAxios
          .post(endpoint, {
            score: this.newLookupObject.score,
            startDate: this.newLookupObject.startDate,
            endDate: this.newLookupObject.endDate,
            isPremium: this.newLookupObject.isPremium,
            title: this.newLookupObject.title,
            text: this.newLookupObject.text,
            language: this.calendarSelectedLanguage,
            categoryIds: this.newLookupObject.categoryIds,
            isGeneral: this.newLookupObject.isGeneral,
            ascendantSignId: this.newLookupObject.ascendantSign,
          })
          .then(() => {
            this.showNewModal = false;  // Modalı kapat
            this.getLookups();  // Listeleri yenile
            this.$toast.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Content successfully added',
              life: 3000
            });
          })
          .catch((err) => {
            this.showNewModal = false;  // Modalı kapat
            this.$toast.add({severity: 'error', summary: 'Error', detail: err.response.data.error, life: 3000});
          });
    },
    onSwitchChange() {
      if (this.newLookupObject.isVoidMoon || this.newLookupObject.isEclipseMoon) {
        this.newLookupObject.isGeneral = false;
      }
    },
    openNewModal() {
      this.newLookupObject = {}
      this.showNewModal = true
    },
    uploader(event) {
      console.log(event)

      var file = event.files[0];

      let formData = new FormData();
      formData.append("file", file);

      this.$appAxios
          .post("admin/calendar/upload", formData, {
            headers: {
              'Content-Type': file.type,
            }
          })
          .then(() => {
            this.$toast.add({
              severity: 'success',
              summary: 'Success',
              detail: 'File was uploaded successfully',
              life: 3000
            });
            this.getLookups();
          }).catch((e) => {
        this.$toast.add({severity: 'error', summary: 'Error', detail: e.response.data.error, life: 5000});
      });
    },
  },
};
</script>

<style scoped>
.fc {
  height: calc(100vh - 150px) !important;
}

.fc-event-time {
  display: none !important;
}

.fc-daygrid-event {
  height: 24px;
  cursor: pointer;
}

.event-order {
  background-color: #ffe2e5 !important;
  color: #f64e60 !important;
}

.event-order > .fc-daygrid-event-dot {
  border-color: #f64e60 !important;
}

.fc-timeGridWeek-button.fc-button.fc-button-primary.fc-button-active {
  background-color: #f64e60 !important;
}

.p-dialog .p-dropdown {
  width: 400px;
}

.form-group .p-button-icon-only {
  margin-top: 25px;
}

.calendar-wrapper.loading {
  filter: blur(1px);
  pointer-events: none;
}
</style>
  